import React from 'react'
import Arrival from "../../modal/Arrival";


export default class ArrivalNotification extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      submission: props.submission,
    }
  }

  render() {
    let {
      submission,
      showArrival
    } = this.state

    return (
      <>
        <button
          className="btn btn-sm btn-outline-primary btn-submission-action"
          onClick={() => this.setState({showArrival: true})}
        >
          Arrive
        </button>
        {
          showArrival &&
          <Arrival
            show={showArrival}
            submission={submission}
            onArrived={() => {
              this.props.onArrived()
              this.setState({ showArrival: false })
            }}
            onHide={() => this.setState({showArrival: false})}
          />
        }
      </>
    )
  }
}
