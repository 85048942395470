import React from "react"

import General from "../../../utils/General";
import AsyncSelect from "../common/AsyncSelect";
import Email from "../../../utils/Email";

export default class Code extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            field: props.field,
            editable: props.editable,
        }
    }

    componentWillReceiveProps(props) {
        this.setState(props)
    }

    getError(){
        let {
            field
        } = this.state

        if(field.is_required && !field.answer?.value){
            return "Please select an option"
        }
        return null
    }

    render(){
        let {
            field,
            error,
            editable
        } = this.state

        let value = General.getSingleAsyncOption(field.answer, "value")

        return (
            <AsyncSelect
                placeholder={field.placeholder}
                endpoint={window.Api.Codes}
                isClearable={true}
                disabled={!editable}
                orderBy={"name"}
                filter={`list_key=${field.format.code_list_key}`}
                className={'custom-async-select__container'}
                classNamePrefix={'custom-async-select'}
                value={value}
                getOptions={options => {
                    return General.getAsyncOptions(options, "name")
                }}
                onSelected={option => {
                    let fieldData = General.clone(field)
                    if(option){
                        fieldData.answer = {
                            id: field.answer?.id,
                            value: option.value
                        }
                    }else{
                        delete fieldData.answer
                    }
                    this.props.onUpdated(fieldData)
                }}
                onBlur={() => {
                    this.props.onBlur()
                }}
            />
        )
    }
}
