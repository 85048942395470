import React from "react"

import { Link, withRouter } from "react-router-dom";
import moment from "moment";

import BaseTable from "./BaseTable"
import Authorisation from "../modal/Authorisation";
import Edit from "./cells/Edit";
import Delete from "./cells/Delete";
import Party from "../modal/Party";
import Confirmation from "../modal/Confirmation";
import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";

class AuthorisationsTable extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      live: props.live
    }

    this.table = React.createRef()
  }

  _handleDelete(party){
    Backend.deleteParty(party).then(() => {
      Notify.success("Authorisation Deleted.")
      this.setState({showConfirmationModal: false, selectedParty: null})
      this.table.current.refresh()
    }).catch(e => Notify.error(e.message))
  }

  _getColumns(){
    let columns = [
      {
        Header: 'Identification Number',
        id: 'identification_number',
        accessor: 'identification_number',
      },
      {
        Header: 'Name',
        id: 'name',
        accessor: 'name',
      },
      {
        Header: 'Date Added',
        id: 'created_at',
        accessor: authorisation => moment(authorisation.created_at).format('DD MMM YYYY'),
        type: "date",
        format: "%d %b %Y"
      },
      {
        Header: '',
        id: '',
        sortable: false,
        exportable: false,
        Cell: rowInfo => {
          let party = rowInfo.original
          return (
            <div className={"text-end my-auto"}>
              <Edit
                onEditClicked={() => {
                  this.setState({
                    showPartyModal: true,
                    selectedParty: party
                  })
                }}
              />
              <Delete
                onDeleteClicked={() => {
                  this.setState({showConfirmationModal: true, selectedParty: party})
                }}
              />
            </div>
          )
        }
      }
    ]

    return columns
  }

  render() {
    let {
      showPartyModal,
      selectedParty,
      showConfirmationModal
    } = this.state

    const columns = this._getColumns()

    return (
      <>
        <BaseTable
          ref={this.table}
          endpoint={`${window.Api.Parties}?type=authorisation_holder`}
          noDataMessage={"No authorisations found"}
          title={this.props.title}
          columns={columns}
          defaultSorted={[
            {
              id: "created_at",
              desc: true
            }
          ]}
          renderToolbar={() => (
            <>
              <div className="card-toolbar" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover"
                   data-kt-initialized="1" data-bs-original-title="" title="">
                <a
                  href="#"
                  className="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#kt_modal_invite_friends"
                  onClick={() => this.setState({showPartyModal: true, selectedParty: {type: "authorisation_holder"}})}
                >
                  <span className="svg-icon svg-icon-3">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                      <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1"
                            transform="rotate(-90 11.364 20.364)" fill="currentColor"/>
                      <rect x="4.36396" y="11.364" width="16" height="2" rx="1" fill="currentColor"/>
                    </svg>
                  </span>
                  Add Authorisation ID
                </a>
              </div>
            </>
          )}
        />
        {
          showPartyModal &&
          <Party
            name={"Authorisation"}
            show={showPartyModal}
            party={selectedParty}
            onHide={() => this.setState({showPartyModal: false, selectedParty: null})}
            onSubmit={() => {
              this.table.current.refresh()
              this.setState({showPartyModal: false, selectedParty: null})
            }}
          />
        }
        {
            showConfirmationModal &&
            <Confirmation
                show={showConfirmationModal}
                onConfirm={() => this._handleDelete(selectedParty)}
                onHide={() => this.setState({showConfirmationModal: false, selectedParty: null})}
            />
        }
      </>
    )

  }

}

export default withRouter(AuthorisationsTable);
