import React from "react"

import General from "../../../utils/General";
import LocationInput from "../common/LocationInput";
import Email from "../../../utils/Email";

export default class Label extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            field: props.field,
            index: props.index,
            editable: props.editable,
            submission: props.submission
        }
    }

    componentWillReceiveProps(props) {
        this.setState(props)
    }

    getError(){
        let {
            field
        } = this.state


        if(field.is_required && !field.answer?.address){
            return "Please enter the address"
        }

        return null
    }

    render(){
        let {
            field,
            editable,
        } = this.state

        let value = field.answer?.address

        return (
            <LocationInput
                className="form-control"
                placeholder={field.placeholder}
                allowManualInput={true}
                location={value}
                isDisabled={!editable}
                onUpdated={address => {
                    let fieldData = General.clone(field)
                    if(address){
                        fieldData.answer = {
                            id: field.answer?.id,
                            address: {
                                line_1: address.address_line_1,
                                line_2: address.address_line_2,
                                city: address.city,
                                state: address.state,
                                country: address.country,
                                country_code: address.country_short,
                                postal_code: address.postal_code,
                                raw: address.raw
                            }
                        }
                    }else{
                        delete fieldData.answer
                    }
                    this.props.onUpdated(fieldData)
                }}
                onBlur={e => {
                    this.props.onBlur()
                }}
            />
        )
    }
}
