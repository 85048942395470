import AuthManager from './AuthManager'

export default class Declarant {
  static getHMRCAuthCode(){
    let url = new URL(window.location.href)
    let params = new URLSearchParams(url.search)

    let code = params.get("code")

    if(code == null){
      return null
    }

    params.delete("code")
    params.delete("state")

    let pathName = window.location.pathname
    if(params.toString()){
      pathName += "?" + params.toString()
    }

    window.history.replaceState(null, window.document.title, pathName)

    return code
  }

  static getDeclarantId(form){
    let certificateType = "ros"
    if(form.type == "cds"){
      certificateType = "hmrc"
    }

    return AuthManager.currentUser.company.declarants.find(
      declarant => declarant.certificate_type == certificateType
    )?.id
  }
}
