import React from "react"

import General from "../../../utils/General";

export default class Number extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            field: props.field,
            index: props.index,
            editable: props.editable,
            submission: props.submission
        }
    }
    componentWillReceiveProps(props) {
        this.setState(props)
    }

    getError(){
        let {
            field
        } = this.state

        let number = field.answer?.value
        if(field.is_required && !number){
            return "Please enter a valid number"
        }

        if(!number){
            return null
        }
        let minLength = field.format.min_length
        let maxLength = field.format.max_length

        let digits = number.toString().replace(".", "")
        let digits_no = digits.toString().length
        if(digits_no > maxLength){
            return `Number of digits must be less than ${maxLength}`
        }

        return null
    }
    render(){
        let {
            field,
            editable,
        } = this.state

        let value = field.answer?.value

        return (
            <input
                type={"number"}
                className={`form-control`}
                placeholder={field.placeholder}
                defaultValue={value}
                style={{ height:  47.8 }}
                minLength={field.format.min_length}
                maxLength={field.format.max_length}
                disabled={!editable}
                onChange={e => {
                    let fieldData = General.clone(field)
                    if(e.target.value){
                        fieldData.answer = {
                            id: field.answer?.id,
                            value: e.target.value
                        }
                    }else{
                        delete fieldData.answer
                    }
                    this.props.onUpdated(fieldData)
                }}
                onBlur={e => {
                    this.props.onBlur()
                }}
            />
        )
    }
}
