import React from "react";

import CodeInput from "./CodeInput";

import Input from "./Input";
import SmartList from "../common/SmartList";

export default class AdditionalReferences extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editable: props.editable,
      additionalReferences: props.additionalReferences,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  _renderAdditionalReference(reference, index) {
    let { editable, additionalReferences } = this.state;
    return (
      <div className="form-group row mb-5">
        <div className="col-md-6">
          <CodeInput
            placeholder={"Type"}
            listKey={"AdditionalReferenceType_AES"}
            value={reference.type}
            editable={editable}
            onSelected={(option) => {
              additionalReferences[index].type = option;
              this.props.onUpdated(additionalReferences);
            }}
          />
        </div>
        <div className="col-md-6">
          <Input
            type="text"
            placeholder="Reference Number"
            value={reference.reference_number}
            editable={editable}
            onChange={(identifier) => {
              additionalReferences[index].reference_number = identifier;
              this.props.onUpdated(additionalReferences);
            }}
          />
        </div>
      </div>
    );
  }

  render() {
    let { editable, additionalReferences } = this.state;

    let itemName = "Additional Reference";
    return (
      <SmartList
        minimal
        items={additionalReferences}
        itemName={itemName}
        showItemName={true}
        canDelete={editable}
        canAdd={editable}
        renderItem={(item, index) =>
          this._renderAdditionalReference(item, index)
        }
        renderDelete={(item, index) => (
          <a
            className="btn btn-sm btn-light-danger"
            onClick={() => {
              additionalReferences.splice(index, 1);
              this.props.onUpdated(additionalReferences);
            }}
          >
            <i className="la la-trash-o fs-3"></i>
            {itemName}
          </a>
        )}
        onUpdated={(items) => {
          additionalReferences = items;
          this.props.onUpdated(additionalReferences);
        }}
        onAddItemPressed={() => {
          additionalReferences.push({});
          this.props.onUpdated(additionalReferences);
        }}
      />
    );
  }
}

AdditionalReferences.defaultProps = {};
