import React from "react"

import General from "../../../utils/General";

export default class Number extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            field: props.field,
            index: props.index,
            editable: props.editable,
            submission: props.submission
        }
    }
    componentWillReceiveProps(props) {
        this.setState(props)
    }

    getError(){
        let {
            field
        } = this.state

        let value = field.answer?.value
        if(field.is_required && value == null){
            return "Please select an option"
        }

        return null
    }
    render(){
        let {
            field,
            editable,
        } = this.state

        let value = field.answer?.value

        return (
            <select
                className="form-select"
                aria-label="Container"
                disabled={!editable}
                onChange={e => {
                    let fieldData = General.clone(field)
                    if(e.target.value){
                        fieldData.answer = {
                            id: field.answer?.id,
                            value: e.target.value
                        }
                    }else{
                        delete fieldData.answer
                    }
                    this.props.onUpdated(fieldData)
                }}
                onBlur={e => {
                    this.props.onBlur()
                }}
            >
                <option disabled selected={field.answer == null}>{field.placeholder}</option>
                <option value="true" selected={value === "true"}>True</option>
                <option value="false" selected={value === "false"}>False</option>
            </select>
        )
    }
}
