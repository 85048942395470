import React from "react"

export default class Badge extends React.Component {

  render() {
    return (
      <>
        <span class="position-absolute top-0 start-100 translate-middle badge badge-primary fs-10">
          { this.props.label }
        </span>
      </>
    )
  }

}
