import React from "react"

import { Link, withRouter } from "react-router-dom";

import Reports from "../modal/Reports";

import BaseTable from "./BaseTable"
import Download from "./cells/Download";

import Notify from "../../../utils/Notify";
import General from "../../../utils/General";
import Backend from "../../../utils/Backend";
import AuthManager from "../../../utils/AuthManager";

import moment from "moment/moment";

class ReportsTable extends React.Component {

  constructor(props) {
    super(props)

    this.state = {

    }

    this.table = React.createRef()
  }

  _getColorClassNameForStatus(status){
    let className = "secondary"
    if(status === "complete"){
      className = 'success'
    }else if(status === "failed"){
      className = 'danger'
    }else if(status === "processing"){
      className = 'warning'
    }
    return className
  }

  _getColumns(){
    let columns = [
      {
        Header: "Date Created",
        accessor: report => moment(report.created_at).format('DD MMM YYYY HH:mm'),
        id: "created_at",
        type: "date",
        format: "%d %b %Y"
      },
      {
        Header: "Form",
        id: "form.subtype",
        accessor: submission => {

          var subtype = submission.form.subtype
          if(subtype == "G4G3"){
            subtype = "G3/G4"
          }else{
            subtype = General.snakeCaseToTitleCase(subtype)
          }
          return `${subtype} (${submission.form.type.toUpperCase()})`
        },
      },
      {
        Header: "Created By",
        id: "user.first_name",
        accessor: report => (
          <>
            <div className="d-flex align-items-center" bis_skin_checked="1">
              <div className="d-flex flex-column justify-content-center" bis_skin_checked="1">
                <a href="" className="mb-1 text-gray-800 text-hover-primary">
                  {report.user.first_name} {report.user.last_name}
                </a>
                <div className="fw-semibold fs-6 text-gray-400" bis_skin_checked="1">
                  {report.user.email}
                </div>
              </div>
            </div>
          </>
        )
      },
      {
        Header: "Status",
        id: "status",
        width: 170,
        accessor: report => {
          let colorClassName = this._getColorClassNameForStatus(report.status)
          let className = `svg-icon-light-${colorClassName}`
          let status = null
          if(colorClassName){
            status = (
                <span className={`svg-icon ${className}`}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1"
                    >
                      <circle fill="currentColor" cx="12" cy="12" r="8"></circle>
                    </svg>
                </span>
            )
          }

          let labels = [
            status,
            General.snakeCaseToTitleCase(report.status)
          ]
          if(report.status == "processing"){
            let progress = ""
            if(report.answers_total_no){
              progress = ((report.answers_retrieved_no / report.answers_total_no) * 100).toFixed(2)
              labels.push(` (${progress}%)`)
            }
          }

          return (
            <>
                <span className="text-dark text-hover-primary fs-6">
                  { labels }
                </span>
            </>
          )
        },
      },
      {
        Header: "Actions",
        id: "actions",
        sortable: false,
        exportable: false,
        accessor: report => {

          return (
            <>
              { report.file &&
                <Download
                  key={report.file.url}
                  url={report.file.url}
                  tooltip="Download Report"
                />
              }
            </>
          )
        },
      },
    ]

    return columns
  }


  render() {
    let {
      showReportModal,
    } = this.state

    const columns = this._getColumns()

    return (
      <>
        <BaseTable
          ref={this.table}
          endpoint={window.Api.Reports}
          noDataMessage={"No reports found"}
          title={this.props.title}
          subtitle={this.props.subtitle}
          showFilter={false}
          columns={columns}
          defaultSorted={[
            {
              id: "created_at",
              desc: true
            }
          ]}
          renderToolbar={() => (
            <>
              <div className="card-toolbar" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover"
                   data-kt-initialized="1" data-bs-original-title="" title="">
                <a
                  href="#"
                  className="btn btn-primary "
                  data-bs-toggle="modal"
                  data-bs-target="#kt_modal_invite_friends"
                  onClick={() => this.setState({showReportModal: true})}
                >
                  <span className="svg-icon svg-icon-3">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                      <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1"
                            transform="rotate(-90 11.364 20.364)" fill="currentColor"/>
                      <rect x="4.36396" y="11.364" width="16" height="2" rx="1" fill="currentColor"/>
                    </svg>
                  </span>
                  Create Report
                </a>
              </div>
            </>
          )}
        />
        {
          showReportModal &&
          <Reports
            show={showReportModal}
            onHide={() => this.setState({showReportModal: false})}
            onCreated={() => {
              this.table.current.refresh()
              this.setState({showReportModal: false})
            }}
          />
        }
      </>
    )

  }

}

export default withRouter(ReportsTable);
