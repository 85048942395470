import React from "react";
import Modal from 'react-bootstrap/Modal'
import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";
import AuthManager from "../../../utils/AuthManager";

export default class Authorisation extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      show: props.show,
      authorisation: props.authorisation || {}
    }
  }

  componentDidMount() {

  }

  _handleSubmission(){
    let {
      authorisation
    } = this.state

    if(authorisation.id){
      this._updateAuthorisation(authorisation)
    }else{
      this._addAuthorisation(authorisation)
    }
  }

  _addAuthorisation(authorisation){
    this.setState({loading: true})
    Backend.addAuthorisation(authorisation).then(item => {
      this.setState({loading: false})
      Notify.success("Authorisation Added")
      window.location = '/parties'
    }).catch(e => {
      this.setState({loading: false})
      Notify.error(e.message)
    })
  }

  _updateAuthorisation(authorisation){
    this.setState({loading: true})
    Backend.updateAuthorisation(authorisation).then(item => {
      this.setState({loading: false})
      Notify.success("Authorisation Updated")
      window.location = '/parties'
    }).catch(e => {
      this.setState({loading: false})
      Notify.error(e.message)
    })
  }

  render() {
    let {
      show,
      loading,
      authorisation,
    } = this.state

    return (
      <>
        <Modal
          show={show}
          onHide={() => this.props.onHide()}
          className={'sub-modal'}
          dialogClassName={"modal-40"}
        >
          <Modal.Header closeButton>
            <Modal.Title>Which Import Declaration would you like to add?</Modal.Title>
          </Modal.Header>

          <Modal.Body>

            <form id="kt_modal_add_user_form" className="form fv-plugins-bootstrap5 fv-plugins-framework" action="#">
              <div
                className="d-flex flex-column scroll-y me-n7 pe-7" id="kt_modal_add_user_scroll"
                data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}"
                data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_user_header"
                data-kt-scroll-wrappers="#kt_modal_add_user_scroll" data-kt-scroll-offset="300px"
                style={{maxHeight: 377}} bis_skin_checked="1"
              >
                <div className="fv-row mb-7 fv-plugins-icon-container" bis_skin_checked="1">
                  <label className="required fw-semibold fs-6 mb-2">Full Name</label>
                  <input
                    type="text"
                    name="user_name"
                    className="form-control form-control-solid mb-3 mb-lg-0"
                    placeholder="Full name"
                    value={authorisation.name}
                    onChange={e => {
                      authorisation.name = e.target.value
                      this.setState({authorisation})
                    }}
                  />
                  <div className="fv-plugins-message-container invalid-feedback" bis_skin_checked="1"/>
                </div>
                <div className="fv-row mb-7 fv-plugins-icon-container" bis_skin_checked="1">
                  <label className="required fw-semibold fs-6 mb-2">ID</label>
                  <input
                    type="text"
                    name="auth_ID"
                    className="form-control form-control-solid mb-3 mb-lg-0"
                    value={authorisation.id}
                    onChange={e => {
                      authorisation.id = e.target.value
                      this.setState({authorisation})
                    }}
                  />
                  <div className="fv-plugins-message-container invalid-feedback" bis_skin_checked="1"/>
                </div>
              </div>
              <div className="text-center pt-15" bis_skin_checked="1">
                <button type="reset" className="btn btn-light me-3" data-kt-users-modal-action="cancel">Discard</button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  data-kt-users-modal-action="submit"
                  disabled={loading}
                  onClick={e => {
                    e.preventDefault()
                    this._handleSubmission()
                  }}
                >
                  <span className="indicator-label">Add Authorisation</span>
                  <span className="indicator-progress">
                    Please wait...<span className="spinner-border spinner-border-sm align-middle ms-2"/>
                  </span>
                </button>
              </div>
              <div bis_skin_checked="1"/>
            </form>

          </Modal.Body>

        </Modal>
      </>
    )
  }

}