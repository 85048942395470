import React from "react";
import Modal from 'react-bootstrap/Modal'

import AttachmentsTable from "../tables/AttachmentsTable"

import General from "../../../utils/General";
import AsyncSelect from "../common/AsyncSelect";
import SmartList from "../common/SmartList";
import MockData from "../../../utils/MockData";
import Codes from "../common/Codes"
import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";
import FileUploader from "../common/FileUploader";

export default class Attachments extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      show: props.show,
      submission: props.submission,

    }
  }



  render() {
    let {
      show,
      submission
    } = this.state

    return (
      <>
        <Modal
          show={show}
          onHide={() => this.props.onHide()}
          className={'sub-modal'}
          dialogClassName={"modal-50"}
        >
          <Modal.Body>

            <AttachmentsTable
              title={"Attachments"}
              submission={submission}
            />

          </Modal.Body>

          <Modal.Footer>

            <div className="form-group text-end mt-10">
              <button
                type="button"
                className="btn btn-light-primary"
                onClick={() => this.props.onHide()}
              >
                Close
              </button>
            </div>

          </Modal.Footer>

        </Modal>
      </>
    )
  }


}
