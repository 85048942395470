import React from "react"

import LinkedSubmissionsTable from "./components/tables/LinkedSubmissionsTable";

export default class LinkedSubmissions extends React.Component {

  constructor(props) {
    super(props)

    this.state = {

    }
  }

  componentDidMount() {

  }

  render() {
    let {
      imports
    } = this.state

    return (
      <>
        <div className="row">
          <div className="col-sm-12 col-xl-12">
            <LinkedSubmissionsTable
              showDateRange={false}
              mode={"declaration"}
            />
          </div>
        </div>
      </>
    )
  }

}
