import React from "react"

import AdditionalExternalIds from "../modal/AdditionalExternalIds"

import General from "../../../utils/General";
import Email from "../../../utils/Email";

export default class Label extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            field: props.field,
            index: props.index,
            editable: props.editable,
            submission: props.submission
        }
    }

    componentWillReceiveProps(props) {
        this.setState(props)
    }

    getError(){
        let {
            field
        } = this.state


        if(field.is_required && !field.answer){
            return "This field is required"
        }

        if(!field.answer) {
            return null
        }

        let minLength = field.format.min_length
        let maxLength = field.format.max_length

        let length = field.answer?.value.toString().length
        if (minLength && length < minLength) {
            return `Must be ${minLength} or less character(s) long`
        }
        else if (maxLength && length > maxLength) {
            return `Must be ${maxLength} or more character(s) long`
        }

        return null
    }

    render(){
        let {
            field,
            editable,
            submission,
            showAdditionalExternalIdsModal
        } = this.state

        let value = field.answer?.value

        let isUCR = field.box_code == "2/4"
        let additionalUCRs = ""
        let ucrCTA = "+ Add Additional UCR"
        if(isUCR){
          additionalUCRs = submission?.additional_external_ids?.join(", ")
        }
        if(submission && submission.additional_external_ids?.length > 0){
          ucrCTA = "Manage Additional UCRs"
        }

        return (
          <>
            <input
                type={"text"}
                className={`form-control`}
                style={{ height:  47.8 }}
                placeholder={field.placeholder}
                value={value}
                minLength={field.format.min_length}
                maxLength={field.format.max_length}
                disabled={!editable}
                onChange={e => {
                    let fieldData = General.clone(field)
                    if(e.target.value){
                        fieldData.answer = {
                            id: field.answer?.id,
                            value: e.target.value
                        }
                    }else{
                        delete fieldData.answer
                    }
                    this.props.onUpdated(fieldData)
                }}
                onBlur={e => {
                    this.props.onBlur()
                }}
            />
            { isUCR &&
              <>
                <span
                  className="text-muted"
                  style={{ paddingRight: 10 }}
                >
                  {additionalUCRs}
                </span>
                { editable &&
                  <a
                    className="text-primary"
                    style={{ cursor: "pointer" }}
                    onClick={() => this.setState({ showAdditionalExternalIdsModal: true })}
                  >
                    { ucrCTA }
                  </a>
                }
              </>
            }
            { showAdditionalExternalIdsModal &&
              <AdditionalExternalIds
                show={showAdditionalExternalIdsModal}
                onHide={() => this.setState({ showAdditionalExternalIdsModal: false })}
                additionalExternalIds={submission.additional_external_ids || []}
                onUpdated={additionalExternalIds => {
                  submission.additional_external_ids = additionalExternalIds
                  this.setState({ showAdditionalExternalIdsModal: false })
                }}
              />
            }
          </>
        )
    }
}
