import React from "react"

import { Link, withRouter } from "react-router-dom";

import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import moment from "moment";
import Tooltip from '@material-ui/core/Tooltip';

import BaseTable from "./BaseTable"
import StatesTable from "./StatesTable";

import PreBoardingNotification from "../modal/PreBoardingNotification";

import Select from "../common/Select";
import Separator from "../common/Separator";

import View from "./cells/View";
import Edit from "./cells/Edit";
import Delete from "./cells/Delete";

import Template from "./cells/Template";
import Duplicate from "./cells/Duplicate";
import Attachment from "./cells/Attachment";
import SelectCell from "./cells/Select";

import PrintDeclaration from "./cells/PrintDeclaration";
import DeclarationActions from "./cells/DeclarationActions"
import DeclarationSummary from "./cells/DeclarationSummary";
import DocumentsUploadRequest from "./cells/DocumentsRequest";
import ArrivalNotification from "./cells/ArrivalNotification";
import DepartureNotification from "./cells/DepartureNotification";
import GoodsPresentationRequest from "./cells/GoodsPresentationRequest";

import Forms from "../modal/Forms";
import Attachments from "../modal/Attachments";
import Confirmation from "../modal/Confirmation";
import TemplateForm from "../modal/TemplateForm";
import DocumentsRequest from "../modal/DocumentsRequest";
import DocumentsPresentation from "../modal/DocumentsPresentation";
import GoodsPresentation from "../modal/GoodsPresentation";
import Declaration from "../modal/Declaration";
import BulkEdit from "../modal/BulkEdit";

import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";
import General from "../../../utils/General";
import AuthManager from "../../../utils/AuthManager";



const CREATOR_FILTERS = {
  isMulti: true,
  name: {
    api: 'company_member_ids',
    display: 'Creator',
  },
  endpoint: {
    url:`${window.Api.CompanyMembers}`,
    filters: '',
    orderBy: 'user__first_name',
    accessor: ''
  }
}

export const STATUS_FILTERS = {
  isMulti: true,
  name: {
    api: 'statuses',
    display: 'Status',
  },
  values: [
    {
      label: 'Draft',
      value: 'draft',
    },
    {
      label: 'Complete',
      value: 'complete',
    },
    {
      label: 'Processing',
      value: 'processing',
    },
    {
      label: 'Registered',
      value: 'registered',
    },
    {
      label: 'Rejected',
      value: 'rejected',
    },
    {
      label: 'Accepted',
      value: 'accepted',
    },
    {
      label: 'Under Review',
      value: 'under_review',
    },
    {
      label: 'Insufficient Funds',
      value: 'insufficient_funds',
    },
    {
      label: 'Released',
      value: 'released',
    },
  ],
}

export const ROUTE_FILTERS = {
  isMulti: true,
  name: {
    api: 'routes',
    display: 'Route',
  },
  values: [
    {
      label: 'Red',
      value: 'red',
    },
    {
      label: 'Green',
      value: 'green',
    },
    {
      label: 'Orange',
      value: 'orange',
    },
    {
      label: 'Yellow',
      value: 'yellow',
    }
  ],
}

const CONSIGNOR_FILTERS = {
  isMulti: true,
  name: {
    api: 'consignor_ids',
    display: 'Consignor',
  },
  endpoint: {
    url: `${window.Api.Parties}`,
    filters: 'type=exporter',
    accessor: ''
  }
}

const CONSIGNEE_FILTERS = {
  isMulti: true,
  name: {
    api: 'consignee_ids',
    display: 'Consignee',
  },
  endpoint: {
    url: `${window.Api.Parties}`,
    filters: 'type=importer',
    accessor: ''
  }
}

const CLIENT_FILTERS = {
  isMulti: true,
  name: {
    api: 'client_ids',
    display: 'Client',
  },
  endpoint: {
    url: `${window.Api.Parties}`,
    filters: 'type=client',
    accessor: ''
  }
}

export const TYPE_FILTERS = {
  isMulti: true,
  name: {
    api: 'form_types',
    display: 'Type',
  },
  values: [
    {
      label: 'AIS',
      value: 'ais',
    },
    {
      label: 'AES',
      value: 'aes',
    },
    {
      label: 'CDS',
      value: 'cds',
    },
  ],
}

export const SUBTYPE_FILTERS = {
  isMulti: true,
  name: {
    api: 'form_subtypes',
    display: 'Type',
  },
  values: [
    {
      label: 'H1',
      value: 'H1',
    },
    {
      label: 'H2',
      value: 'H2',
    },
    {
      label: 'H3',
      value: 'H3',
    },
    {
      label: 'H4',
      value: 'H4',
    },
    {
      label: 'H5',
      value: 'H5',
    },
    {
      label: 'H6',
      value: 'H6',
    },
    {
      label: 'H7',
      value: 'H7',
    },
    {
      label: 'I1',
      value: 'I1',
    },
    {
      label: 'G3/G4',
      value: 'G4G3',
    },
    {
      label: 'B1',
      value: 'B1',
    },
    {
      label: 'B2',
      value: 'B2',
    },
    {
      label: 'B4',
      value: 'B4',
    },
    {
      label: 'C1 C&F',
      value: 'C1 C&F',
    },
    {
      label: 'C1 B&E',
      value: 'C1 B&E',
    },
  ],
}

const FILTERS = [CREATOR_FILTERS, CONSIGNOR_FILTERS, CONSIGNEE_FILTERS, CLIENT_FILTERS, STATUS_FILTERS, ROUTE_FILTERS, TYPE_FILTERS, SUBTYPE_FILTERS]

const COMPARISONS = [
  {
    label: "EQUALS",
    value: "exact"
  },
  {
    label: "IN",
    value: "in"
  },
  // {
  //   label: "CONTAINS",
  //   value: "contains"
  // },
  // {
  //   label: "STARTS WITH",
  //   value: "startswith"
  // },
  // {
  //   label: "ENDS WITH",
  //   value: "endswith"
  // }
]

class SubmissionsTable extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      live: props.live,
      mode: props.mode,
      imports: props.imports,
      formFields: [],
      advancedFilters: [],
      selectedSubmissionIds: []
    }

    this.table = React.createRef()
    this.statesTable = React.createRef()
  }

  _goTo(submission, edit=true){
    this.setState({
      selectedSubmission: submission,
      declarationEditable: edit,
      showDeclarationModal: true
    })
  }

  _generate(type, submission){
    Backend.generate(type, submission)
    .then(() => {
      Notify.success("You will receive the file shortly via email")
    })
    .catch(error => {
      Notify.error(error.message)
    })
  }

  _applyAdvancedFilters(){
    let { advancedFilters } = this.state

    let advancedFiltersQuery = []
    advancedFilters.forEach((filter, i) => {
      filter = General.clone(filter)
      let fieldId = filter.field?.id
      if(!fieldId){
        return
      }
      let value = filter.value
      let match = filter.comparison.value

      if(match == "in" && value){
        value = value.split(",")
      }
      advancedFiltersQuery.push({
        value,
        match,
        type: "field",
        field_id: fieldId,
      })
    });

    let advancedFiltersQueryValue = encodeURIComponent(
      JSON.stringify(advancedFiltersQuery)
    )
    this.setState({ advancedFiltersQueryValue }, () => {
      this.table.current.refresh()
    })
  }

  _loadFormFields(){
    let { formType, formSubType } = this.state
    if(!formType || !formSubType){
      return
    }

    Backend.getFields(formType.value, formSubType.value, "text,code,number,date,datetime")
    .then(fields => {
      this.setState({
        formFields: fields,
        advancedFilters: [{
          _id: General.uuid(),
          comparison: COMPARISONS[0]
        }],
        isLoadingFields: false
      })
    })
    .catch(error => {
      Notify.error(error.message)
    })
  }

  _renderDraftActions(submission){
    return (
      <div className={"text-end"}>
        <View
          onViewClicked={() => {
            this._goTo(submission, false)
          }}
        />
        <Edit
          onEditClicked={() => {
            this._goTo(submission)
          }}
        />
        <Duplicate
          submission={submission}
        />
      </div>
    )
  }

  _renderSubmittedActions(submission){

    let colorClassName = this._getColorClassNameForRoute(submission.route)

    let selectable = submission.form.type != "cds" && !submission.pre_boarding_notification
    if(submission.form.subtype == "G4G3" || !submission.mrn){
      selectable = false
    }
    return (
      <div className={"text-end"}>
        {
          submission.documents_upload_requested &&
          <DocumentsUploadRequest
            colorClassName={colorClassName}
            onUploadClicked={() => {
              this.setState({selectedSubmission: submission, showDocumentsRequestedModal: true})
            }}
          />
        }
        {
          submission.goods_presentation_status == "requested" &&
          <GoodsPresentationRequest
            colorClassName={colorClassName}
            onPresentClicked={() => {
              this.setState({selectedSubmission: submission, showGoodsPresentationModal: true})
            }}
          />
        }
        {
          submission.documents_presentation_requested &&
          <PrintDeclaration
            onPrintDeclarationClicked={() => {
              this.setState({selectedSubmission: submission, showDocumentsPresentationModal: true})
            }}
          />
        }
        <View
          onViewClicked={() => {
            this._goTo(submission, false)
          }}
        />
        <Duplicate
          submission={submission}
        />
        { selectable &&
          <SelectCell
            key={submission.id}
            id={submission.id}
            tooltip="Add To PBN"
            ids={this.state.selectedSubmissionIds}
            onUpdated={ids => {
              this.setState({
                formType: submission.form.type,
                selectedSubmissionIds: ids
              })
            }}
          />
        }
      </div>
    )
  }

  _isOwnTemplate(submission) {
    const authUserCompanyId = AuthManager.currentUser.company.id

    return (
      submission.company_member.company.id == authUserCompanyId  
    );
  }

  _renderTemplateActions(submission) {
    const isOwnTemplate = this._isOwnTemplate(submission);

    return (
      <div className={"text-end"}>
        {isOwnTemplate && (
          <Edit
            onEditClicked={() => {
              this._goTo(submission);
            }}
          />
        )}
        <Duplicate submission={submission} />
      </div>
    );
  }

  _getColorClassNameForRoute(route){
    let className = "secondary"
    if(route === "green"){
      className = 'success'
    }else if(route === "red"){
      className = 'danger'
    }else if(route === "yellow"){
      className = 'warning'
    }else if(route === "orange"){
      className = 'warning'
    }
    return className
  }

  _getColumns(){
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const COLUMN_ID = {
      Header: 'ID',
      id: 'id',
      width: 250,
      accessor: submission => {
        let verificationStatus = null
        if(submission.verification_status == "passed"){
          verificationStatus = (
            <Tooltip
              title={<p style={{ fontSize: 12, padding: 8, marginBottom: 0}}>AI Verified</p>}
              fontSize={16}
              placement="top"
              arrow
              className="mb-1"
            >
              <span>✅</span>
            </Tooltip>
          )
        }
        else if(submission.verification_status == "failed"){
          let errorHTML = "AI Found Issue, check your email"
          if(submission.verification_errors){
            errorHTML = (
              <>
                AI Found Issue(s)<br/><br/>
                <ul>
                  {submission.verification_errors.map(verificationError => {
                    return (
                      <li class="mb-2">
                        {verificationError}
                      </li>
                    )
                  })}
                </ul>
              </>
            )
          }

          verificationStatus = (
            <Tooltip
              title={<p style={{ fontSize: 12, padding: 8, marginBottom: 0}}>{errorHTML}</p>}
              fontSize={16}
              placement="top"
              arrow
              className="mb-1"
            >
              <span>⚠️</span>
            </Tooltip>
          )
        }

        return (
            <>
              <span className="text-hover-primary text-dark fs-8">
                <span className={"text-dark"}>ID:</span> <span className="">{submission.id}</span>
              </span>
              {
                submission.mrn &&
                <span className="text-muted fw-bold text-muted d-block fs-8">
                  MRN: <span>{submission.mrn}</span>
                </span>
              }
              {
                submission.external_id &&
                <span className="text-muted fw-bold text-muted d-block fs-8">
                  UCR: <span>{submission.external_id} </span> { verificationStatus }
                </span>
              }
            </>
        )
      }
    }

    const COLUMN_LRN = {
      Header: 'LRN',
      id: 'lrn',
      show: false,
      width: 0,
      accessor: submission => {
        return null
      }
    }

    const COLUMN_MRN = {
      Header: 'MRN',
      id: 'mrn',
      show: false,
      width: 0,
      accessor: submission => {
        return null
      }
    }

    const COLUMN_EXTERNAL_ID = {
      Header: 'Unique Customer Reference',
      id: 'external_id',
      show: false,
      width: 0,
      accessor: submission => {
        return null
      }
    }

    const COLUMN_ADDITIONAL_EXTERNAL_IDS = {
      Header: 'Additional Customer References',
      id: 'additional_external_ids',
      show: false,
      type: "array",
      format: "str",
      width: 0,
      accessor: submission => {
        return null
      }
    }

    const COLUMN_NAME = {
      Header: 'Name',
      accessor: 'name',
      width: 200
    }

    const COLUMN_CREATED_BY = {
      Header: 'Created By',
      id: 'company_member.user.first_name',
      accessor: submission => {

        let companyMember = submission.company_member
        if(!companyMember){
          return "-"
        }
        return (
          <>
            <a className="text-dark fw-bolder text-hover-primary fs-6">
              {companyMember.user.first_name} {companyMember.user.last_name}
            </a>
            <span className="text-muted fw-bold text-muted d-block fs-7">
              {moment(submission.created_at).format('ll')}
            </span>
          </>
        )
      },
    }

    const COLUMN_PARTIES = {
      Header: 'Parties',
      id: 'consignor.name',
      exportable: false,
      accessor: submission => (
          <>
              <a className="text-dark text-hover-primary">
                CNOR: {General.snakeCaseToTitleCase(submission.consignor?.name) || '-'}
              </a>
              <br/>
              <a className="text-muted text-hover-primary">
                CNEE: {General.snakeCaseToTitleCase(submission.consignee?.name) || '-'}
              </a>
          </>

      ),
    }


    const COLUMN_STATUS = {
      Header: "Status",
      id: "status",
      width: 170,
      accessor: submission => {
        let className = ''
        if(submission.route){
          let colorClassName = this._getColorClassNameForRoute(submission.route)
          className = `svg-icon-light-${colorClassName}`
        }

        let route = (
            <span className={`svg-icon ${className}`}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                >
                  <circle fill="currentColor" cx="12" cy="12" r="8"></circle>
                </svg>
            </span>
        )

        if(["draft", "complete", "processing","rejected"].indexOf(submission.status) > -1){
          route = null
        }

        return (
          <>
              <span className="text-dark text-hover-primary fs-6">
                {route} {General.snakeCaseToTitleCase(submission.status)}
              </span>
          </>
        )
      },
    }

    const COLUMN_CLIENT = {
      Header: 'Client',
      id: 'client.name',
      exportable: false,
      accessor: submission => {
        let client = submission.client
        if(!client){
          return "-"
        }

        return (
            <span className="text-dark text-hover-primary fs-6">
              {client.name}
            </span>
        )
      },
    }

    const COLUMN_TYPE = {
      Header: "Type",
      id: "form.subtype",
      width: 70,
      accessor: submission => {

        var subtype = submission.form.subtype
        if(subtype == "G4G3"){
          subtype = "G3/G4"
        }else{
          subtype = General.snakeCaseToTitleCase(subtype)
        }

        let subtitle = General.snakeCaseToTitleCase(submission.form.type)
        if(submission.source_type == "automated_import"){
          subtitle = "Linked"
        }

        return (
          <>
            <a className="text-dark fw-bolder text-hover-primary fs-6">
              {subtype}
            </a>
            <span className={`text-muted fw-bold text-muted d-block fs-7 text-uppercase`}>
              {subtitle}
            </span>
          </>
        )
      },
    }

    const COLUMN_CREATED_AT = {
      timezone,
      Header: `Created At (${timezone})`,
      accessor: 'created_at',
      type: "datetime",
      format: "%Y-%m-%d %H:%M:%S.%f%z",
      show: false,
    }

    const COLUMN_LAST_UPDATE_AT = {
      timezone,
      Header: `Latest Update At (${timezone})`,
      accessor: 'latest_state.created_at',
      type: "datetime",
      format: "%Y-%m-%d %H:%M:%S.%f%z",
      show: false,
    }

    const COLUMN_LAST_UPDATE = {
      Header: 'Last Update',
      id: 'latest_state.id',
      width: 180,
      exportable: false,
      accessor: submission => {

        let state = submission.latest_state
        if(!state){
          return "-"
        }

        let updatedBy = submission.form.type == "ais" ? "Revenue" : "HMRC"
        if(state.company_member){
          let user = state.company_member.user
          updatedBy = `${user.first_name} ${user.last_name}`
        }

        return (
            <>
              <a className="text-dark fw-bolder text-hover-primary fs-6">
                {updatedBy}
              </a>
              <span className="text-muted fw-bold text-muted d-block fs-7">
                {moment(state.created_at).format('lll')}
              </span>
            </>
        )
      },
    }

    const COLUMN_LAST_UPDATE_MESSAGE = {
      Header: 'Latest Update',
      id: 'latest_state.message',
      width: 180,
      show: false,
      sortable: false,
      exportable: true,
    }

    const COLUMN_LAST_UPDATE_BR_ERROR = {
      Header: 'Latest Error',
      id: 'latest_state.message',
      type: "br_error",
      format: "str",
      width: 180,
      show: false,
      sortable: false,
      exportable: true,
    }

    const COLUMN_ACTIONS = {
      Header: '',
      id: '',
      width: 200,
      sortable: false,
      exportable: false,
      Cell: rowInfo => {
        let submission = rowInfo.original
        let content = null
        if(submission.template){
          content = this._renderTemplateActions(submission)
        }else if(submission.status === "draft" || submission.status === "rejected"){
          content = this._renderDraftActions(submission)
        }else{
          content = this._renderSubmittedActions(submission)
        }

        return (
          <div key={submission.id}>
            {content}
          </div>
        )
      }
    }

    let { mode } = this.state
    let columns = []
    if(mode === "template"){
      columns = [
          COLUMN_NAME,
          COLUMN_TYPE,
          COLUMN_CREATED_BY,
          COLUMN_PARTIES,
          COLUMN_ACTIONS
      ]
    }
    else if(mode === "draft"){
      columns = [
          COLUMN_TYPE,
          COLUMN_CREATED_BY,
          COLUMN_CLIENT,
          COLUMN_PARTIES,
          COLUMN_ACTIONS
      ]
    }
    else{
      columns = [
          COLUMN_ID,
          COLUMN_LRN,
          COLUMN_MRN,
          COLUMN_EXTERNAL_ID,
          COLUMN_ADDITIONAL_EXTERNAL_IDS,
          COLUMN_TYPE,
          COLUMN_CLIENT,
          COLUMN_PARTIES,
          COLUMN_CREATED_AT,
          COLUMN_STATUS,
          COLUMN_LAST_UPDATE,
          COLUMN_LAST_UPDATE_AT,
          COLUMN_LAST_UPDATE_MESSAGE,
          COLUMN_LAST_UPDATE_BR_ERROR,
          COLUMN_ACTIONS
      ]
    }

    return columns
  }

  _renderStates(submission){

    return (
      <div className="submission-detail">
        <DeclarationActions
          submission={submission}
          goTo={() => this._goTo(submission)}
          refresh={() => this.table.current.refresh()}
        />
        <div className="states-table">
          <StatesTable
            ref={this.statesTable}
            submission={submission}
            submissionID={submission.id}
          />
        </div>
      </div>
    )
  }

  _renderAdvancedFilters(){
    let { formFields, formType, formSubType, advancedFilters } = this.state

    return (
      <>
        <Separator text="Advanced Filters"/>
        <Select
          isClearable={true}
          value={formType}
          options={TYPE_FILTERS.values}
          placeholder={"Please select an option"}
          getOptionLabel={option => option.label}
          getOptionValue={option => option.value}
          className={'filter-solid custom-async-select__container'}
          classNamePrefix={'custom-async-select'}
          onSelected={option => {
            this.setState({
              fields: [],
              advancedFilters: [],
              formType: option,
            }, () => this._loadFormFields())
          }}
        />

        <Select
          isClearable={true}
          value={formSubType}
          options={SUBTYPE_FILTERS.values}
          placeholder={"Please select a form"}
          getOptionLabel={option => option.label}
          getOptionValue={option => option.value}
          className={'filter-solid custom-async-select__container'}
          classNamePrefix={'custom-async-select'}
          onSelected={option => {
            this.setState({
              fields: [],
              advancedFilters: [],
              formSubType: option,
            }, () => this._loadFormFields())
          }}
        />

        { formFields.length > 0 &&
          <>
            { advancedFilters.map((filter, i) => {
                return (
                  <div key={filter._id} className="row">
                    <div className="col-11">
                      <div className="row">
                        <div className="col-6">
                          <Select
                            options={formFields}
                            placeholder={"Please select a field"}
                            getOptionLabel={option => {
                              let label = option.title
                              if(option.box_code){
                                label += ` [${option.box_code}]`
                              }
                              return label
                            }}
                            getOptionValue={option => option.id}
                            value={filter.field}
                            className={'filter-solid custom-async-select__container'}
                            classNamePrefix={'custom-async-select'}
                            onSelected={field => {
                              advancedFilters[i].field = field
                              this.setState({ advancedFilters })
                            }}
                          />
                        </div>

                        <div className="col-2">
                          <Select
                            options={COMPARISONS}
                            placeholder={"Please select an option"}
                            getOptionLabel={option => option.label}
                            getOptionValue={option => option.value}
                            value={filter.comparison?.value}
                            className={'filter-solid custom-async-select__container'}
                            classNamePrefix={'custom-async-select'}
                            onSelected={option => {
                              advancedFilters[i].comparison = option
                              this.setState({ advancedFilters })
                            }}
                          />
                        </div>

                        <div className="col-4">
                          <input
                            type="text"
                            style={{height: 47.8 }}
                            className="form-control form-control-lg form-control-solid"
                            placeholder="Please type a value"
                            value={filter.value}
                            onChange={e => {
                              advancedFilters[i].value = e.target.value || null
                              this.setState({ advancedFilters })
                            }}
                          />
                          {filter.comparison?.value == "in" &&
                            <span className="text-muted">comma, seperated list</span>
                          }
                        </div>
                      </div>
                    </div>

                    <div className="col-1 text-end">
                      {advancedFilters.length > 1 &&
                        <Delete
                          onDeleteClicked={() => {
                            let newAdvancedFilters = General.clone(advancedFilters)
                            newAdvancedFilters.splice(i, 1)
                            this.setState({ advancedFilters: newAdvancedFilters })
                          }}
                        />
                      }
                    </div>
                  </div>
                )
              })
            }

            <div className="row">
              <div className="col">
                <button
                  className="btn btn-light-primary btn-sm"
                  onClick={() => {
                    advancedFilters.push({
                      _id: General.uuid(),
                      comparison: COMPARISONS[0]
                    })
                    this.setState({ advancedFilters })
                  }}
                >
                  Add Filter
                </button>
              </div>
              <div className="col text-end">
                <button
                  className="btn btn-primary btn-sm"
                  onClick={() => {
                    this._applyAdvancedFilters()
                  }}
                >
                  Apply
                </button>
              </div>
            </div>
          </>
        }
      </>
    )
  }

  render() {
    let {
      mode,
      imports,
      formType,
      selectedTemplate,
      selectedSubmission,
      selectedSubmissionIds,
      showAttachmentsModal,
      declarationEditable,
      showBulkEditModal,
      showDeclarationModal,
      showTemplateFormModal,
      showGoodsPresentationModal,
      showSubmissionSummaryModal,
      showDocumentsRequestedModal,
      showDocumentsPresentationModal,
      advancedFiltersQueryValue,
      showPreBoardingNotificationModal
    } = this.state

    const columns = this._getColumns()

    let showPagination = true
    let toolbar= null
    let noDataMessage = "No declarations found"
    let showDateRange = true
    let endpoint = window.Api.Submissions
    let sortId = "created_at"
    let bulkEdit = (
      <a
        className="btn btn-light-primary btn-active-primary ms-3"
        onClick={() => this.setState({ showBulkEditModal: true })}
      >
        <span className="svg-icon svg-icon-3">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
               <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <rect x="0" y="0" width="24" height="24"/>
                <path d="M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z" fill="#000000" fill-rule="nonzero" transform="translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) "/>
                <rect fill="#000000" opacity="0.3" x="5" y="20" width="15" height="2" rx="1"/>
               </g>
          </svg>
        </span>
        Bulk Edit
      </a>
    )

    let pbn = (
      <a
        className="btn btn-light-primary btn-active-primary ms-3"
        onClick={() => this.setState({ showPreBoardingNotificationModal: true })}
      >
        <span className="svg-icon svg-icon-3">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1"
                  transform="rotate(-90 11.364 20.364)" fill="currentColor"/>
            <rect x="4.36396" y="11.364" width="16" height="2" rx="1" fill="currentColor"/>
          </svg>
        </span>
        Create PBN
      </a>
    )

    if(mode === "latest"){
      endpoint += "?template=false"
      showPagination = false
      sortId = "latest_state.created_at"
      toolbar = (
        <>
          <Link
            to="/import-submissions"
            className="btn btn-light-primary btn-active-primary"
          >
            <span className="svg-icon svg-icon-3">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1"
                      transform="rotate(-90 11.364 20.364)" fill="currentColor"/>
                <rect x="4.36396" y="11.364" width="16" height="2" rx="1" fill="currentColor"/>
              </svg>
            </span>
            View All
          </Link>
          { bulkEdit }
          { pbn }
        </>
      )
    }
    else if(mode === "template"){
      showDateRange = false
      noDataMessage = "No templates found"
      endpoint += "?template=true"
      toolbar = (
        <a
          className="btn btn-light-primary btn-active-primary"
          onClick={() => this.setState({ showTemplateFormModal: true })}
        >
          <span className="svg-icon svg-icon-3">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1"
                    transform="rotate(-90 11.364 20.364)" fill="currentColor"/>
              <rect x="4.36396" y="11.364" width="16" height="2" rx="1" fill="currentColor"/>
            </svg>
          </span>
          Add Template
        </a>
      )
    }
    else if(mode === "draft"){
      showDateRange = false
      noDataMessage = "No drafts found"
      endpoint += "?status=draft&template=false"
    }
    else if(mode === "declaration"){
      endpoint += "?template=false"
      sortId = "latest_state.created_at"
      toolbar = (
        <>
          { bulkEdit }
          { pbn }
        </>
      )
    }

    let bulkUpload = this.props.bulkUpload
    let exportable = bulkUpload != null
    if(bulkUpload){
      endpoint += `&bulk_upload_id=${bulkUpload.id}`
    }

    let preBoardingNotification = this.props.preBoardingNotification
    if(preBoardingNotification){
      endpoint += `&pre_boarding_notification_id=${preBoardingNotification.id}`
    }

    if(imports != null){
      endpoint += `&imports=${imports}`
    }

    if(advancedFiltersQueryValue != null){
      endpoint += `&advanced_filters=${advancedFiltersQueryValue}`
    }

    let hiddenSubmissionStatuses = AuthManager.currentUser.hidden_submission_statuses
    return (
      <>
        <BaseTable
          ref={this.table}
          endpoint={endpoint}
          noDataMessage={noDataMessage}
          title={this.props.title}
          subtitle={this.props.subtitle}
          showPagination={showPagination}
          columns={columns}
          chipsSearch={true}
          sendExportAsEmail={true}
          exportFilename={bulkUpload?.external_id}
          exportButtonsEnabled={exportable}
          filters={FILTERS}
          defaultSorted={[
            {
              id: sortId,
              desc: true
            }
          ]}
          dateRange={showDateRange}
          renderToolbar={() => (
            <>
              <div className="card-toolbar" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover"
                   data-kt-initialized="1" data-bs-original-title="" title="">
                { hiddenSubmissionStatuses.length > 0 &&
                  <Tooltip
                    title={
                      <p style={{ fontSize: 12, padding: 8, marginBottom: 0}}>
                        The following statuses have been hidden:<br/><br/>
                        <ul>
                          {hiddenSubmissionStatuses.map(status => {
                            return (
                              <li>
                                {General.snakeCaseToTitleCase(status)}
                              </li>
                            )
                          })}
                        </ul>
                      </p>
                    }
                    fontSize={16}
                    placement="top"
                    arrow
                    className="mb-1"
                  >
                    <span class="svg-icon-warning svg-icon-2x" style={{marginRight: 20}}>
                      <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="36px" height="36px" viewBox="0 0 24 24" version="1.1">
                        <defs/>
                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <rect x="0" y="0" width="24" height="24"/>
                            <path d="M11.1669899,4.49941818 L2.82535718,19.5143571 C2.557144,19.9971408 2.7310878,20.6059441 3.21387153,20.8741573 C3.36242953,20.9566895 3.52957021,21 3.69951446,21 L21.2169432,21 C21.7692279,21 22.2169432,20.5522847 22.2169432,20 C22.2169432,19.8159952 22.1661743,19.6355579 22.070225,19.47855 L12.894429,4.4636111 C12.6064401,3.99235656 11.9909517,3.84379039 11.5196972,4.13177928 C11.3723594,4.22181902 11.2508468,4.34847583 11.1669899,4.49941818 Z" fill="#ffc700" opacity="0.3"/>
                            <rect fill="#ffc700" x="11" y="9" width="2" height="7" rx="1"/>
                            <rect fill="#ffc700" x="11" y="17" width="2" height="2" rx="1"/>
                        </g>
                      </svg>
                    </span>
                  </Tooltip>
                }

                {toolbar}

              </div>
            </>
          )}
          SubComponent={(row) => {
            const isOwnTemplate = this._isOwnTemplate(row.original);

            if (!isOwnTemplate) {
              return null;
            }
            return this._renderStates(row.original);
          }}
          renderFilterSubMenuFooter={() => this._renderAdvancedFilters()}
          collapseOnDataChange={false}
          expandedKey="id"
          showFiltersInSubMenu
        />

        {
          showDocumentsRequestedModal &&
          <DocumentsRequest
            show={showDocumentsRequestedModal}
            submission={selectedSubmission}
            onHide={() => this.setState({showDocumentsRequestedModal: false, selectedSubmission: null})}
          />
        }
        {
          showDocumentsPresentationModal &&
          <DocumentsPresentation
            show={showDocumentsPresentationModal}
            onHide={() => this.setState({showDocumentsPresentationModal: false, selectedSubmission: null})}
            onConfirm={() => this.setState({showDocumentsPresentationModal: false, selectedSubmission: null})}
          />
        }
        {
          showGoodsPresentationModal &&
          <GoodsPresentation
            show={showGoodsPresentationModal}
            submission={selectedSubmission}
            onHide={() => {
              this.setState({showGoodsPresentationModal: false, selectedSubmission: null})
              this.table.current.refresh()
            }}
          />
        }
        {
          showTemplateFormModal &&
          <TemplateForm
            show={showTemplateFormModal}
            template={selectedTemplate}
            onHide={() => this.setState({showTemplateFormModal: false, selectedTemplate: null })}
            onUpdated={(template) => {
              this.table.current.refresh()
              this.setState({showTemplateFormModal: false, selectedTemplate: null })
              this._goTo(template)
            }}
          />
        }
        {
          showAttachmentsModal &&
          <Attachments
            show={showAttachmentsModal}
            submission={selectedSubmission}
            onHide={() => this.setState({showAttachmentsModal: false, selectedSubmission: null })}
          />
        }

        {
          showDeclarationModal &&
          <Declaration
            show={showDeclarationModal}
            editable={declarationEditable}
            submission={selectedSubmission}
            onClose={() => {
              if(declarationEditable){
                this.table.current.refresh()
              }
              this.setState({
                showDeclarationModal: false,
                selectedSubmission: null,
                declarationEditable: false
              })
            }}
          />
        }


        { showBulkEditModal &&
          <BulkEdit
            show={showBulkEditModal}
            onHide={() => {
              this.setState({
                showBulkEditModal: false,
              })
            }}
            onSaved={() => {
              Notify.success("Declarations Amended!")
              this.setState({ showBulkEditModal: false })
            }}
          />
        }

        { showPreBoardingNotificationModal &&
          <PreBoardingNotification
            show={showPreBoardingNotificationModal}
            formType={formType}
            submissionIds={selectedSubmissionIds}
            onHide={() => {
              this.setState({ showPreBoardingNotificationModal: false})
            }}
            onSend={() => {
              this.setState({ showPreBoardingNotificationModal: false})
            }}
          />
        }
      </>
    )

  }

}

SubmissionsTable.defaultProps = {
  showDateRange: true,
  subtitle: "",
  mode: "declaration"
}

export default withRouter(SubmissionsTable);