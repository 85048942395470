import React from "react"
import SubmissionsTable from "./components/tables/SubmissionsTable";
import AuthManager from "../utils/AuthManager";

export default class Templates extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      currentUser: AuthManager.currentUser
    }
  }

  componentDidMount() {

  }

  render() {
    let {

    } = this.state

    return (
      <>
        <div className="row">
          <div className="col-sm-12 col-xl-12">
            <SubmissionsTable
              title={"Declaration Templates"}
              mode={"template"}
            />
          </div>
        </div>
      </>
    )
  }

}