import React from "react";
import Modal from 'react-bootstrap/Modal'

import DeclarationForm from "../../DeclarationForm";

import Notify from "../../../utils/Notify";

export default class Declaration extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      show: props.show,
      editable: props.editable,
      submission: props.submission
    }
  }

  render() {
    let {
      show,
      editable,
      submission,
    } = this.state


    return (
      <>
        <Modal
          show={show}
          onHide={() => {
            if(editable){
              Notify.error("Please press the 'Close' button when finished editing")
              return
            }
            this.props.onClose()
          }}
          className={'sub-modal'}
          dialogClassName={"modal-70"}
        >

          <DeclarationForm
            inModal={true}
            editable={editable}
            submissionId={submission.id}
            onClose={this.props.onClose}
          />

        </Modal>
      </>
    )
  }

}
