import React from "react"

import { withRouter } from "react-router-dom";

import BaseTable from "./BaseTable"

import Edit from "./cells/Edit";
import Delete from "./cells/Delete";
import Duplicate from "./cells/DuplicateItem";

import Item from "../modal/Item";
import Confirmation from "../modal/Confirmation";

import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";
import General from "../../../utils/General";

class ProductsTable extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      live: props.live
    }

    this.table = React.createRef()
  }

  _handleDelete(product){
    Backend.deleteProduct(product).then(() => {
      Notify.success("Product Deleted.")
      this.setState({showConfirmationModal: false, selectedProduct: null})
      this.table.current.refresh()
    }).catch(e => Notify.error(e.message))
  }

  _getColumns(){
    let columns = [
      {
        Header: 'Name',
        id: 'name',
        accessor: commodity => (General.toTitleCase(commodity.name)),
      },
      {
        Header: 'Description',
        id: 'description',
        accessor: commodity => (General.toTitleCase(commodity.description)),
      },
      {
        Header: 'TARIC Code',
        id: 'commodity_code.taric.value',
        accessor: 'commodity_code.taric.value',
      },
      {
        Header: 'TARIC Description',
        id: 'commodity_code.taric.name',
        accessor: 'commodity_code.taric.name',
      },
      {
        Header: '',
        id: '',
        sortable: false,
        exportable: false,
        Cell: rowInfo => {
          let product = rowInfo.original
          return (
            <div className={"text-end my-auto"}>
              <Edit
                onEditClicked={() => {
                  this.setState({showProductInformationModal: true, selectedProduct: product})
                }}
              />
              <Duplicate
                product={product}
                onDuplicated={duplicatedProduct => {
                  this.setState({showProductInformationModal: true, selectedProduct: duplicatedProduct})
                }}
              />
              <Delete
                onDeleteClicked={() => {
                  this.setState({selectedProduct: product, showConfirmationModal: true})
                }}
              />
            </div>
          )
        }
      }
    ]

    return columns
  }

  render() {
    let {
      selectedProduct,
      showConfirmationModal,
      showProductInformationModal
    } = this.state

    const columns = this._getColumns()

    return (
      <>
        <BaseTable
          ref={this.table}
          endpoint={window.Api.Items}
          noDataMessage={"No commodities found"}
          title={this.props.title}
          columns={columns}
          defaultSorted={[
            {
              id: "created_at",
              desc: true
            }
          ]}
          renderToolbar={() => (
            <>
              <div className="card-toolbar" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover"
                   data-kt-initialized="1" data-bs-original-title="" title="">
                <a
                  href="#"
                  className="btn btn-primary"
                  onClick={e => this.setState({showProductInformationModal: true})}
                >
                  + Add Product
                </a>
              </div>
            </>
          )}
        />
        {
          showProductInformationModal &&
          <Item
            show={showProductInformationModal}
            productId={selectedProduct?.id}
            onHide={e => this.setState({selectedProduct: null, showProductInformationModal: false})}
            onSubmit={shipmentItem => {
              this.table.current.refresh()
              this.setState({showProductInformationModal: false, selectedProduct: null})
            }}
          />
        }
        {
            showConfirmationModal &&
            <Confirmation
                show={showConfirmationModal}
                onConfirm={() => this._handleDelete(selectedProduct)}
                onHide={() => this.setState({showConfirmationModal: false, selectedItem: null})}
            />
        }
      </>
    )

  }

}

export default withRouter(ProductsTable);
