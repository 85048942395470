import React from "react";

import CodeInput from "./CodeInput"

import Codes from "../common/Codes"
import Input from "./Input";
import SmartList from "../common/SmartList";

export default class Operations extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            editable: props.editable,
            operations: props.operations,
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState(nextProps)
    }

    _renderOperation(operation, index){
        let {
            editable,
            operations
        } = this.state

        return (
            <div className="form-group row">
                <div className="col-md-6">
                    <CodeInput
                        placeholder={"Code"}
                        editable={editable}
                        listKey={"AdditionsDeductionsCode"}
                        value={operation.code}
                        onSelected={option => {
                            operations[index].code = option
                            this.props.onUpdated(operations)
                        }}
                    />
                </div>
                <div className="col-md-6">
                    <Input
                        type="number"
                        placeholder="Amount"
                        value={operation.amount}
                        onChange={amount => {
                            operations[index].amount = amount
                            this.props.onUpdated(operations)
                        }}
                        editable={editable}
                    />
                </div>
            </div>
        )
    }


    render() {
        let {
            editable,
            operations,
        } = this.state

        let itemName = "Addition / Deduction"
        return (
            <SmartList
                minimal
                items={operations}
                showItemName={true}
                itemName={itemName}
                canDelete={editable}
                canAdd={editable}
                renderItem={(operation, index) => this._renderOperation(operation, index)}
                renderDelete={(item, index) => (
                    <a
                        className="btn btn-sm btn-light-danger"
                        onClick={() => {
                            operations.splice(index, 1)
                            this.props.onUpdated(operations)
                        }}
                    >
                        <i className="la la-trash-o fs-3">
                        </i>
                        {itemName}
                    </a>
                )}
                onUpdated={items => {
                    operations = items
                    this.props.onUpdated(operations)
                }}
                onAddItemPressed={() => {
                    operations.push({})
                    this.props.onUpdated(operations)
                }}
            />
        )
    }


}

Operations.defaultProps = {

}
