import React from 'react'
import Tooltip from '@material-ui/core/Tooltip';

export default class Delete extends React.Component {
  constructor(props){
    super(props)

    this.state = {

    }
  }

  render() {

    return (
      <Tooltip title={this.props.tooltip} placement="top" arrow>
        <a
          className="btn btn-icon btn-sm btn-secondary me-2"
          onClick={() => this.props.onDeleteClicked()}
        >
          <span
            className="svg-icon svg-icon-1"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect opacity="0.5" x="7.05025" y="15.5356" width="12" height="2" rx="1" transform="rotate(-45 7.05025 15.5356)" fill="currentColor"/>
              <rect x="8.46447" y="7.05029" width="12" height="2" rx="1" transform="rotate(45 8.46447 7.05029)" fill="currentColor"/>
            </svg>
          </span>
        </a>
      </Tooltip>
    )
  }
}

Delete.defaultProps = {
  tooltip: "Delete",
}
