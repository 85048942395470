import React from "react"
import PasswordStrengthBar from 'react-password-strength-bar';
import ReactPasswordStrength from 'react-password-strength';

import Notify from "../../../utils/Notify";
import AuthManager from "../../../utils/AuthManager";

export default class AccountDetails extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      data: props.data,
      isLoading: false
    }
  }

  _handleUserDetailsChange(e){
    let data = this.state.data
    data.members[0].user[e.target.name] = e.target.value
    this.setState({ data, error: null })
  }

  _isSignUpFormValid(){
    let {
      data,
      score
    } = this.state

    let error = null
    if(!data.name){
      error = "Please enter your business name"
    }
    else if(!data.members[0].user.first_name){
      error = "Please enter your first name"
    }
    else if(!data.members[0].user.last_name){
      error = "Please enter your last name"
    }
    else if(!data.members[0].user.email){
      error = "Please enter a valid email"
    }
    else if(!data.members[0].user.password) {
      error = "Please enter a valid password"
    }else if(!score.isValid) {
      error = "Please enter a strong password"
    }

    if(error){
      Notify.error(error)
      return false
    }

    return true
  }

  _register(){
    let {
      data
    } = this.state

    if(!this._isSignUpFormValid()){
      return
    }

    this.setState({
      isLoading: true,
    })

    AuthManager.register(data)
      .then(response => {
        this.setState({
          isLoading: false,
        })
        this.props.onRegistered()
      })
      .catch(error => {
        this.setState({
          isLoading: false,
        })
        Notify.error(error.message)
      })
  }

  render() {
    let {
      data,
      isLoading
    } = this.state

    return (
      <>
        <div className="current" data-kt-stepper-element="content">
          <div className="w-100">
            <div className="pb-10 pb-lg-15">
              <h2 className="fw-bolder text-dark">Account Info</h2>
              <div className="text-muted fw-bold fs-6">
                If you need more info, please <a href="https://www.customswindow.com/#Message" target="_blank" className="link-primary fw-bolder">Contact Support</a>.
              </div>
            </div>
            <div className="fv-row mb-10 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
              <label className="form-label">Business Name</label>
              <input
                name="business_name"
                className="form-control form-control-lg form-control-solid"
                value={data.name}
                onChange={e => {
                  data.name = e.target.value
                  this.setState({data})
                }}
              />
              <div className="fv-plugins-message-container invalid-feedback"/>
            </div>
            <div className="mb-10 fv-row">
              <label className="form-label mb-3">First Name</label>
              <input
                type="text"
                className="form-control form-control-lg form-control-solid"
                name="first_name"
                value={data.members[0].user.first_name}
                onChange={e => this._handleUserDetailsChange(e)}
              />
            </div>
            <div className="mb-10 fv-row">
              <label className="form-label mb-3">Last Name</label>
              <input
                type="text"
                className="form-control form-control-lg form-control-solid"
                name="last_name"
                value={data.members[0].user.last_name}
                onChange={e => this._handleUserDetailsChange(e)}
              />
            </div>
            <div className="mb-10 fv-row">
              <label className="form-label mb-3">Email</label>
              <input
                type="text"
                className="form-control form-control-lg form-control-solid"
                name="email"
                value={data.members[0].user.email}
                onChange={e => this._handleUserDetailsChange(e)}
              />
            </div>
            <div className="mb-10 fv-row fv-plugins-icon-container" data-kt-password-meter="true">
              <div className="mb-1">
                <label className="form-label fs-6">Password</label>
                <div className="position-relative mb-3">
                  <ReactPasswordStrength
                    minLength={8}
                    minScore={3}
                    scoreWords={['Weak', 'Okay', 'Good', 'Strong', 'Very Strong']}
                    changeCallback={(score, password, isValid) => {
                      data.members[0].user.password = score.password
                      this.setState({ data, score, error: null })
                    }}
                    inputProps={{ name: "password_input", autoComplete: "off", className: "form-control form-control-lg form-control-solid" }}
                  />
                </div>
              </div>
              <div className="text-muted">
                Use 8 or more characters with a mix of letters & numbers.
              </div>
              <div className="fv-plugins-message-container invalid-feedback"></div>
            </div>
          </div>
        </div>
        <div className="d-flex flex-stack pt-15">
          <div className="mr-2"/>
          <div>
            <button
              type="button"
              className="btn btn-lg btn-primary"
              data-kt-stepper-action="next"
              disabled={isLoading}
              onClick={e => this._register()}
            >
              Continue
              <span className="svg-icon svg-icon-4 ms-1">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)"
                        fill="black"></rect>
                  <path
                    d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                    fill="black"></path>
                </svg>
              </span>
            </button>
          </div>
        </div>
      </>
    )
  }

}