import React from "react"

import { Link, withRouter } from "react-router-dom";


import Download from "./cells/Download";
import Delete from "./cells/Delete";

import moment from "moment";
import Attachment from "../modal/Attachment";
import Confirmation from "../modal/Confirmation";

import BaseTable from "./BaseTable"

import General from "../../../utils/General";
import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";

class AttachmentsTable extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      submission: props.submission
    }

    this.table = React.createRef()
  }

  _handleDelete(attachment){
    Backend.deleteAttachment(attachment)
    .then(() => {
      Notify.success("Attachment Deleted.")
      this.setState({showConfirmationModal: false, selectedAttachment: null})
      this.table.current.refresh()
    }).catch(e => Notify.error(e.message))
  }

  _getColumns() {

    let columns = [
      {
        Header: 'Date Added',
        id: 'created_at',
        accessor: attachment => moment(attachment.created_at).format('DD MMM YYYY'),
        type: "date",
        format: "%d %b %Y"
      },
      {
        Header: 'Type',
        id: 'type',
        accessor: attachment => attachment.type.name,
      },
      {
        Header: 'Filename ',
        id: 'file',
        accessor: attachment => attachment.file.name,
      },
      {
        Header: '',
        id: '',
        sortable: false,
        exportable: false,
        Cell: rowInfo => {
          let attachment = rowInfo.original
          return (
            <div className={"text-end my-auto"}>
              <Download
                key={attachment.id}
                url={attachment.file.url}
                tooltip="Download Attachment"
              />
              <Delete
                onDeleteClicked={() => {
                  this.setState({showConfirmationModal: true, selectedAttachment: attachment})
                }}
              />
            </div>
          )
        }
      }
    ]

    return columns

  }

  render() {
    let {
      submission,
      selectedAttachment,
      showAttachmentModal,
      showConfirmationModal
    } = this.state

    const columns = this._getColumns()

    return (
      <>
        <BaseTable
          ref={this.table}
          showFilter={false}
          endpoint={`${window.Api.Attachments}?submission_id=${submission.id}`}
          noDataMessage={"No attachments found"}
          title={this.props.title}
          columns={columns}
          defaultSorted={[
            {
              id: "created_at",
              desc: true
            }
          ]}
          renderToolbar={() => (
            <>
              <div className="card-toolbar" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover"
                   data-kt-initialized="1" data-bs-original-title="" title="">
                <a
                  className="btn btn-primary"
                  onClick={() => {
                    this.setState({showAttachmentModal: true})
                  }}
                >
                  + Add Attachment
                </a>
              </div>
            </>
          )}
        />
        {
          showAttachmentModal &&
          <Attachment
            show={showAttachmentModal}
            submission={submission}
            onHide={() => this.setState({showAttachmentModal: false, selectedAttachment: null})}
            onAdded={() => {
              this.table.current.refresh()
              this.setState({showAttachmentModal: false, selectedAttachment: null})
            }}
          />
        }
        {
            showConfirmationModal &&
            <Confirmation
                show={showConfirmationModal}
                onConfirm={() => this._handleDelete(selectedAttachment)}
                onHide={() => this.setState({showConfirmationModal: false, selectedAttachment: null})}
            />
        }
      </>
    )

  }

}

export default withRouter(AttachmentsTable);
