import React from "react"

import { Link, withRouter } from "react-router-dom";
import moment from "moment";

import BaseTable from "./BaseTable"
import Authorisation from "../modal/Authorisation";
import Edit from "./cells/Edit";
import Delete from "./cells/Delete";
import Party from "../modal/Party";
import General from "../../../utils/General";

class StatesTable extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      submissionID: props.submissionID
    }

    this.table = React.createRef()
  }

  _renderStatus(state){
    let className = ''
    if(state.amendment_status){
      return null
    }
    if(state.route === "green"){
      className = 'svg-icon-light-success'
    }else if(state.route === "red"){
      className = 'svg-icon-light-danger'
    }else if(state.route === "yellow"){
      className = 'svg-icon-light-warning'
    }else if(state.route === "orange"){
      className = 'svg-icon-light-warning'
    }
    let route = (
        <span className={`svg-icon ${className}`}>
          <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              version="1.1"
          >
            <circle fill="currentColor" cx="12" cy="12" r="8"></circle>
          </svg>
      </span>
    )

    if(["draft", "complete", "processing","rejected"].indexOf(state.status) > -1){
      route = null
    }

    return (
        <>
          <span className="text-dark text-hover-primary fs-6">
            {route} {General.snakeCaseToTitleCase(state.status)}
          </span>
        </>
    )
  }

  _getUpdatedBy(state){
    let updatedBy = this.props.submission?.form.type == "cds" ? "HMRC" : "Revenue"
    if(state.company_member){
      let user = state.company_member.user
      updatedBy = `${user.first_name} ${user.last_name}`
    }
    return updatedBy
  }

  _getColumns(){
    let compactColumns = [
      {
        Header: 'Detail',
        id: 'reason',
        accessor: state => {
          let updatedBy = this._getUpdatedBy(state)

          return (
            <>
              <div className="row">
                <p className="col-6 mb-0">
                  <div className="text-dark fw-bolder text-hover-primary">
                  {General.toTitleCase(state.reason)}
                  </div>
                  <a className="text-muted d-block fs-7">
                    {updatedBy}
                  </a>
                </p>
                <div className="col-6 text-end">
                  {this._renderStatus(state)}
                  <span className="text-muted d-block fs-7">
                    {moment(state.created_at).format('lll')}
                  </span>
                </div>
              </div>
              <div style={{whiteSpace: "pre-wrap"}}>{state.message}</div>
            </>
          )
        },
      },
    ]

    let columns = [
      {
        Header: 'Detail',
        id: 'reason',
        accessor: state => General.toTitleCase(state.reason),
      },
      {
        Header: 'Message',
        id: 'message',
        accessor: state => {
          return (
              <div style={{whiteSpace: "pre-wrap"}}>{state.message}</div>
          )
        },
        width: 500,
      },
      {
        Header: "Status",
        id: "status",
        width: 150,
        accessor: state => this._renderStatus(state),
      },
      {
        Header: 'Update',
        id: 'created_at',
        accessor: state => {
          let updatedBy = this._getUpdatedBy(state)
          return (
              <>
                <a className="text-dark fw-bolder text-hover-primary fs-6">
                  {updatedBy}
                </a>
                <span className="text-muted fw-bold text-muted d-block fs-7">
                  {moment(state.created_at).format('lll')}
                </span>
              </>
          )
        },
      },
    ]

    return this.props.compact ? compactColumns : columns
  }

  render() {
    let {
      submissionID,
    } = this.state

    const columns = this._getColumns()

    return (
      <>
        <BaseTable
          ref={this.table}
          endpoint={`${window.Api.States}?submission_id=${submissionID}`}
          showHeader={false}
          showFilter={false}
          noDataMessage={"No changes found"}
          title={''}
          columns={columns}
          minRows={this.props.compact ? 0 : 5}
          pageSize={this.props.compact ? 5 : 20}
          defaultSorted={[
            {
              id: "created_at",
              desc: true
            }
          ]}
        />
      </>
    )

  }

}

export default withRouter(StatesTable);
