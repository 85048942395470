import React from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'

import LeftMenu from "./components/structure/LeftMenu";
import Header from "./components/structure/Header";
import Footer from "./components/structure/Footer";

import DevAlert from "./components/alert/DevAlert";

import Dashboard from "./Dashboard"
import DeclarationForm from "./DeclarationForm"
import Analytics from "./Analytics"
import Users from "./Users"
import CodeLists from "./CodeLists";
import Settings from "./Settings"
import Product from "./Product";
import Party from "./Party";
import Submissions from "./Submissions";
import BulkUploads from "./BulkUploads";
import Drafts from "./Drafts";
import Templates from "./Templates";
import CodeListKey from "./CodeListKey";
import Products from "./Products";
import Parties from "./Parties";
import Guarantees from "./Guarantees";
import Authorisations from "./Authorisations";
import Clients from "./Clients";
import Contacts from "./Contacts";
import Reports from "./Reports";

import LinkedSubmissions from "./LinkedSubmissions";
import PreBoardingNotifications from "./PreBoardingNotifications";

import AuthManager from "../utils/AuthManager";


export default class App extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      currentUser: AuthManager.currentUser
    }
  }

  componentDidMount() {
    if(!this.state.currentUser.company.onboarding_checklist.declarant ||
      !this.state.currentUser.company.subscription.active
    ){
      window.location = "/__register__"
    }

    if(document.getElementById("root").className === "bg-body"){
      document.getElementById("root").className = "header-fixed header-tablet-and-mobile-fixed aside-enabled aside-fixed"
    }
  }

  _renderRoutes() {
    return (
      <Switch>
        <Route exact path="/" component={Dashboard} />
        <Route exact path="/declaration-form" component={DeclarationForm} />
        <Route exact path="/submission/:submissionId/view" component={DeclarationForm} />
        <Route exact path="/submission/:submissionId" component={DeclarationForm} />
        <Route exact path="/import-submissions" component={() => <Submissions imports={true} />} />
        <Route exact path="/export-submissions" component={() => <Submissions imports={false} />} />
        <Route exact path="/linked-submissions" component={() => <LinkedSubmissions />} />
        <Route exact path="/bulk-uploads" component={BulkUploads} />
        <Route exact path="/draft-submissions" component={Drafts} />
        <Route exact path="/template-submissions" component={Templates} />
        <Route exact path="/pre-boarding-notifications" component={PreBoardingNotifications} />
        <Route exact path="/products" component={Products} />
        <Route exact path="/parties" component={Parties} />
        <Route exact path="/authorisations" component={Authorisations} />
        <Route exact path="/guarantees" component={Guarantees} />
        <Route exact path="/clients" component={Clients} />
        <Route exact path="/contacts" component={Contacts} />
        <Route exact path="/analytics" component={Analytics} />
        <Route exact path="/users" component={Users} />
        <Route exact path="/code-lists" component={CodeLists} />
        <Route exact path="/code-list/:listId" component={CodeListKey} />
        <Route exact path="/add-commodity" component={Product} />
        <Route exact path="/commodity/:commodityId" component={Product} />
        <Route exact path="/add-party" component={Party} />
        <Route exact path="/party/:partyId" component={Party} />
        <Route exact path="/settings" component={Settings} />
        <Route exact path="/reports" component={Reports} />
        <Redirect to="/404" />
      </Switch>
    )
  }

  render() {
    return (
      <>
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <LeftMenu/>
            <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
              <Header/>

              <DevAlert />

              <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                <div className="post d-flex flex-column-fluid" id="kt_post">
                  <div id="kt_content_container" className="container-xxl">
                    {this._renderRoutes()}
                  </div>
                </div>
              </div>
              <Footer/>
            </div>
          </div>
        </div>
      </>
    )
  }

}
