import React from "react";

import CodeInput from "./CodeInput";

import SmartList from "../common/SmartList";

export default class DangerousGoods extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editable: props.editable,
      dangerousGoods: props.dangerousGoods,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  _renderDangerousGoods(dangerousGoodsItem, index) {
    let { editable, dangerousGoods } = this.state;
    return (
      <div className="form-group row mb-5">
        <div className="col-md-6">
          <CodeInput
            placeholder={"Type"}
            listKey={"DangerousGoodsCode_AES"}
            value={dangerousGoodsItem.type}
            editable={editable}
            onSelected={(option) => {
              dangerousGoods[index].type = option;
              this.props.onUpdated(dangerousGoods);
            }}
          />
        </div>
      </div>
    );
  }

  render() {
    let { editable, dangerousGoods } = this.state;

    let itemName = "Dangerous Goods";
    return (
      <SmartList
        minimal
        items={dangerousGoods}
        itemName={itemName}
        showItemName={true}
        canDelete={editable}
        canAdd={editable}
        renderItem={(dangerousGoods, index) =>
          this._renderDangerousGoods(dangerousGoods, index)
        }
        renderDelete={(item, index) => (
          <a
            className="btn btn-sm btn-light-danger"
            onClick={() => {
              dangerousGoods.splice(index, 1);
              this.props.onUpdated(dangerousGoods);
            }}
          >
            <i className="la la-trash-o fs-3"></i>
            {itemName}
          </a>
        )}
        onUpdated={(items) => {
          dangerousGoods = items;
          this.props.onUpdated(dangerousGoods);
        }}
        onAddItemPressed={() => {
          dangerousGoods.push({});
          this.props.onUpdated(dangerousGoods);
        }}
      />
    );
  }
}

DangerousGoods.defaultProps = {};
