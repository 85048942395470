import React from "react"

import { Link, withRouter } from "react-router-dom";
import moment from "moment";

import BaseTable from "./BaseTable"
import Authorisation from "../modal/Authorisation";
import Edit from "./cells/Edit";
import Delete from "./cells/Delete";
import Party from "../modal/Party";
import General from "../../../utils/General";
import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";
import Confirmation from "../modal/Confirmation";

class CodesTable extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
    }

    this.table = React.createRef()
  }


  _getColumns(){
    let columns = [
      {
        Header: 'Name',
        id: 'name',
        width: 200,
        accessor: code => code.name,
      },
      {
        Header: 'Value',
        id: 'value',
        width: 200,
        accessor: code => code.value,
      },
      {
        Header: 'Description',
        id: 'description',
        accessor: code => code.description
      },
      {
        Header: '',
        id: '',
        width: 100,
        sortable: false,
        exportable: false,
        Cell: rowInfo => {
          let code = rowInfo.original
          return (
            <div className={"text-end my-auto"}>
              <br/>
              <p></p>
            </div>
          )
        }
      }
    ]

    return columns
  }

  render() {
    let {
    } = this.state

    const columns = this._getColumns()

    return (
      <>
        <BaseTable
          ref={this.table}
          endpoint={`${window.Api.Codes}`}
          params={{ list_id: this.props.listId }}
          noDataMessage={"No codes found"}
          title={this.props.title}
          columns={columns}
          defaultSorted={[
            {
              id: "name",
              desc: false
            }
          ]}
        />

      </>
    )

  }

}

export default withRouter(CodesTable);
