import React from "react";

import CodeInput from "./CodeInput"

import SmartList from "../common/SmartList";

export default class NationalAdditionalCodes extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            editable: props.editable,
            nationalAdditionalCodes: props.nationalAdditionalCodes
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState(nextProps)
    }

    _renderNationalAdditionalCode(nationalAdditionalCode, index){
        let {
            editable,
            nationalAdditionalCodes
        } = this.state

        return (
            <div className="form-group row mb-5">
                <div className="col-md-12">
                    <CodeInput
                        placeholder={"National Additional Code"}
                        listKey={"TaricNationalAdditionalCode"}
                        value={nationalAdditionalCode.code}
                        editable={editable}
                        onSelected={option => {
                            nationalAdditionalCodes[index].code = option
                            this.props.onUpdated(nationalAdditionalCodes)
                        }}
                    />
                </div>
            </div>
        )
    }


    render() {
        let {
            editable,
            nationalAdditionalCodes,
        } = this.state

        let itemName = "National Additional Code"
        let addItemName = `${itemName} (6/17)`
        return (
            <SmartList
                items={nationalAdditionalCodes}
                showItemName={true}
                canDelete={editable}
                canAdd={editable}
                itemName={itemName}
                addItemName={addItemName}
                renderItem={(item, index) => this._renderNationalAdditionalCode(item, index)}
                renderDelete={(item, index) => (
                    <a
                        className="btn btn-sm btn-light-danger"
                        onClick={() => {
                            nationalAdditionalCodes.splice(index, 1)
                            this.props.onUpdated(nationalAdditionalCodes)
                        }}
                    >
                        <i className="la la-trash-o fs-3">
                        </i>
                        {itemName}
                    </a>
                )}
                onUpdated={items => {
                    nationalAdditionalCodes = items
                    this.props.onUpdated(nationalAdditionalCodes)
                }}
                onAddItemPressed={() => {
                    nationalAdditionalCodes.push({})
                    this.props.onUpdated(nationalAdditionalCodes)
                }}
            />
        )
    }


}

NationalAdditionalCodes.defaultProps = {

}
