import React from "react"

import { Link, withRouter } from "react-router-dom";

import moment from "moment";

import Download from "./cells/Download";

import DeclarationsTable from "./SubmissionsTable"

import BaseTable from "./BaseTable"
import General from "../../../utils/General";
import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";


class BulkUploadsTable extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      mode: props.mode
    }

    this.table = React.createRef()
  }

  _getColumns(){

    return [
      {
        Header: 'Created At',
        id: 'created_at',
        width: 180,
        accessor: bulkUpload => {

          return (
            <>
              <span className="text-hover-primary text-dark fs-8">
                {moment(bulkUpload.created_at).format('lll')}
              </span>
              {
                bulkUpload.external_id &&
                <span className="text-muted fw-bold text-muted d-block fs-7">
                  <span>{bulkUpload.external_id}</span>
                </span>
              }
            </>

          )
        },
      },
      {
        Header: 'Declarations',
        accessor: 'declarations_no',
        width: 140,
      },
      {
        Header: 'Parsed',
        accessor: 'processed_declarations_no',
        width: 85,
      },
      {
        Header: 'Failed',
        accessor: 'failed_declarations_no',
        width: 85,
      },
      {
        Header: 'Rejected',
        accessor: 'rejected_status_no',
      },
      {
        Header: 'Green',
        accessor: 'green_routed_no',
        width: 70,
      },
      {
        Header: 'Orange',
        accessor: 'orange_routed_no',
      },
      {
        Header: 'Red',
        accessor: 'red_routed_no',
        width: 65,
      },
      {
        Header: 'Yellow',
        accessor: 'yellow_routed_no',
      },
      {
        Header: "Status",
        id: "status",
        width: 150,
        accessor: bulkUpload => {
          let className = ''
          if(bulkUpload.status === "pending"){
            className = 'svg-icon-light-primary'
          }else if(bulkUpload.status === "processing"){
            className = 'svg-icon-light-warning'
          }else if(bulkUpload.status === "complete"){
            className = 'svg-icon-light-success'
          }else if(bulkUpload.status === "failed"){
            className = 'svg-icon-light-danger'
          }
          let route = (
              <span className={`svg-icon ${className}`}>
                  <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                  >
                    <circle fill="currentColor" cx="12" cy="12" r="8"></circle>
                  </svg>
              </span>
          )

          return (
            <>
                <span className="text-dark text-hover-primary fs-6">
                  {route} {General.snakeCaseToTitleCase(bulkUpload.status)}
                </span>
            </>
          )
        },
      },
      {
        Header: "Actions",
        id: "actions",
        sortable: false,
        exportable: false,
        accessor: bulkUpload => {

          return (
            <>
              { bulkUpload.errors_file &&
                <Download
                  key={bulkUpload.errors_file.url}
                  url={bulkUpload.errors_file.url}
                  tooltip="Download Failed Rows"
                />
              }
            </>
          )
        },
      },
    ]
  }

  render() {
    let {
      mode,
    } = this.state

    const columns = this._getColumns()

    return (
      <>
        <BaseTable
          ref={this.table}
          endpoint={window.Api.BulkUploads}
          noDataMessage={"No uploads found"}
          title={this.props.title}
          subtitle={this.props.subtitle}
          showPagination={this.props.showPagination}
          columns={columns}
          showFilter={false}
          defaultSorted={[
            {
              id: "created_at",
              desc: true
            }
          ]}
          SubComponent={(row) => {
            return (
              <DeclarationsTable
                title={"Declarations"}
                showDateRange={false}
                bulkUpload={row.original}
                mode={"declaration"}
              />
            )
          }}
        />
      </>
    )

  }

}

BulkUploadsTable.defaultProps = {
  showDateRange: true,
  subtitle: "",
}

export default withRouter(BulkUploadsTable);
