import React from "react";
import Modal from 'react-bootstrap/Modal'
import General from "../../../utils/General";
import AsyncSelect from "../common/AsyncSelect";
import SmartList from "../common/SmartList";
import MockData from "../../../utils/MockData";
import Codes from "../common/Codes"
import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";
import FileUploader from "../common/FileUploader";

export default class DocumentsRequest extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      show: props.show,
      submission: props.submission,
      data: {
        submission: props.submission.id,
        records: [],
      },
    }
  }

  _submitDocuments(){
    let {
      data,
      submission
    } = this.state

    this.setState({loading: true})

    Backend.submitDocuments(data)
    .then(e => {
      this.setState({loading: false})
      Notify.success("Documents Submitted")
      this.props.onHide()
    }).catch(e => {
      this.setState({loading: false})
      Notify.error(e.message)
    })
  }

  _renderRecord(record, index){
    let {
      data
    } = this.state

    return (
      <div className="form-group row mt-5 mb-5">
        <div className="col-md-6">
          <div className="form-floating mb-4">
            <Codes
              placeholder={"Type"}
              orderBy={"name"}
              filter={`list_key=CommonDocumentsType`}
              value={record.additional_information.type}
              onSelected={option => {
                data.records[index].additional_information.type = option
                this.setState({data})
              }}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-floating mb-4">
            <input
              type="text"
              className="form-control"
              placeholder="Additional Statement Text"
              value={record.additional_information.info}
              onChange={e => {
                data.records[index].additional_information.info = e.target.value || null
                this.setState({data})
              }}
            />
            <label htmlFor="additionalStatementText">Additional Statement Text</label>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-floating mb-4">
            <input
              type="text"
              className="form-control" id="documentIdentifier"
              placeholder="Document Identifier"
              value={record.supporting_document.id}
              onChange={e => {
                data.records[index].supporting_document.id = e.target.value
                this.setState({data})
              }}
            />
            <label htmlFor="documentIdentifier">Document Identifier</label>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-floating mb-4">
            <input
              type="text"
              className="form-control" id="documentIdentifier"
              placeholder="Document Identifier"
              value={record.supporting_document.description}
              onChange={e => {
                data.records[index].supporting_document.description = e.target.value || null
                this.setState({data})
              }}
            />
            <label htmlFor="documentIdentifier">Document Description</label>
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-floating mt-3 mb-4">
            <FileUploader
              key={document?.file?.id}
              placeholder={"File"}
              endpoint={window.Api.Files}
              onUploading={() => {
                this.setState({loading: true})
              }}
              onUploaded={file => {
                data.records[index].supporting_document.file = file
                this.setState({data, loading: false})
              }}
              onRemoved={() => {
                data.records[index].supporting_document.file = null
                this.setState({data})
              }}
            />
          </div>
        </div>
      </div>
    )
  }


  render() {
    let {
      loading,
      show,
      data,
      submission
    } = this.state

    console.log("£££", submission)
    return (
      <>
        <Modal
          show={show}
          onHide={() => this.props.onHide()}
          className={'sub-modal'}
          dialogClassName={"modal-50"}
        >
          <Modal.Header closeButton>
            <Modal.Title>Documents Requested</Modal.Title>
          </Modal.Header>

          <Modal.Body>

            { submission.latest_state?.documents_upload_requested &&
              <>
                <h5>{submission.latest_state.reason}</h5>
                <p className="mb-5">
                  {submission.latest_state.message}
                </p>
              </>
            }

            <SmartList
              items={data.records}
              itemName="Document"
              renderItem={(record, index) => this._renderRecord(record, index)}
              renderDelete={(item, index) => (
                <a
                  href="javascript:;"
                  data-repeater-delete=""
                  className="btn btn-sm btn-light-danger"
                  onClick={() => {
                    data.records.splice(index, 1)
                    this.setState({data})
                  }}
                >
                  <i className="la la-trash-o fs-3"></i>Delete Document
                </a>
              )}
              onUpdated={items => {
                //TODO: how updating multiple sections will work
                data.records = items
                this.setState({data})
              }}
              onAddItemPressed={() => {
                data.records.push({
                  supporting_document: {},
                  additional_information: {}
                })
                this.setState({data})
              }}
            />

            <div className="form-group text-end mt-10">
              <button
                type="button"
                className="btn btn-light-primary"
                disabled={loading}
                onClick={() => this._submitDocuments()}
              >
                Submit
              </button>
            </div>
          </Modal.Body>

        </Modal>
      </>
    )
  }


}
