import React from "react";

import Modal from 'react-bootstrap/Modal'

import AdditionalExternalIds from "./AdditionalExternalIds"

import Select from "../common/Select";
import AsyncSelect from "../common/AsyncSelect";

import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";
import General from "../../../utils/General";

const FORM_TYPES = [
  {
    label: "In To Ireland (AIS)",
    value: "ais"
  },
  {
    label: "Out of Ireland (AES)",
    value: "aes"
  },
]

export default class PreBoardingNotification extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      show: props.show,
      contacts: [],
      emptyVehicle: null,
      formType: props.formType,
      additionalExternalIds: [],
      submissionIds: props.submissionIds,
      showAdditionalExternalIdsModal: false
    }
  }


  _isFormValid(){
    let {
      contacts,
      formType,
      emptyVehicle,
      submissionIds,
      additionalExternalIds
    } = this.state

    let error = null
    if(contacts.length == 0){
      error = "Please select at least 1 contact"
    }
    else if(!formType){
      error = "Please select a movement direction"
    }
    else if(emptyVehicle == null){
      error = "Please select whether this vehicle is empty or not."
    }

    if(error){
      Notify.error(error)
      return false
    }

    return true
  }

  _save(){
    let {
      contacts,
      formType,
      emptyVehicle,
      submissionIds,
      additionalExternalIds
    } = this.state

    if(!this._isFormValid()){
      return
    }

    let data = {
      form_type: formType,
      empty_vehicle: emptyVehicle,
      submission_ids: submissionIds,
      additional_external_ids: additionalExternalIds || null,
      contact_ids: contacts.map(contact => contact.id),
    }

    this.setState({ loading: true })
    Backend.sendPreBoardingNotification(data)
    .then(() => {
      this.props.onSend()
      Notify.success("Pre Boarding Notification Sent!")
      this.setState({ loading: false })
    }).catch(e => {
      Notify.error(e.message)
      this.setState({ loading: false })
    })
  }

  _getOptions(contacts){
    return contacts.map(contact => ({
        value: contact.id,
        label: contact.name,
        data: contact
    }))
  }

  render() {
    let {
      show,
      loading,
      contacts,
      formType,
      emptyVehicle,
      additionalExternalIds,
      showAdditionalExternalIdsModal
    } = this.state

    return (
      <>
        <Modal
          show={show}
          onHide={() => this.props.onHide()}
          className={'sub-modal'}
          dialogClassName={"modal-50"}
        >
          <Modal.Header closeButton>
            <Modal.Title>Pre Boarding Notification</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="flex-column" data-kt-stepper-element="content">
              <div className="row">

                <div className="col-md-12">
                  <div className="mb-5">
                    <label className={"mb-2"}>Contacts</label>
                    <AsyncSelect
                        endpoint={window.Api.Parties}
                        orderBy={"name"}
                        filter={`type=contact`}
                        isMulti={true}
                        className={'custom-async-select__container'}
                        classNamePrefix={'custom-async-select'}
                        value={this._getOptions(contacts)}
                        getOptions={options => this._getOptions(options)}
                        onSelected={options => {
                          let contacts = []
                          if(options){
                            contacts = options.map(option => option.data)
                          }
                          this.setState({ contacts })
                        }}
                    />
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="mb-5">
                    <label className="mb-2">Movement Direction</label>
                    <Select
                      value={formType}
                      options={FORM_TYPES}
                      placeholder="Select A Type"
                      getOptionLabel={option => option.label}
                      getOptionValue={option => option.value}
                      className={'custom-async-select__container'}
                      classNamePrefix={'custom-async-select'}
                      onSelected={option => {
                        formType = option.value
                        this.setState({ formType })
                      }}
                    />
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="mb-5">
                      <label className={"mb-2"}>Empty Vehicle</label>
                      <select
                          className="form-select"
                          aria-label="Container"
                          onChange={e => {
                              emptyVehicle = e.target.value == "true"
                              this.setState({ emptyVehicle })
                          }}
                      >
                        <option disabled selected={emptyVehicle == null}>Select An Option</option>
                        <option value="true" selected={emptyVehicle === "true"}>Yes</option>
                        <option value="false" selected={emptyVehicle === "false"}>No</option>
                    </select>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="mb-5">
                    <label className={"mb-2"}>Additional MRNs</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Additional MRNs"
                      disabled
                      value={additionalExternalIds.join(", ")}
                    />
                    <a
                      className="text-primary"
                      style={{ cursor: "pointer" }}
                      onClick={() => this.setState({ showAdditionalExternalIdsModal: true })}
                    >
                      + Add Additional MRNs
                    </a>
                  </div>
                </div>


              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex justify-content-end">
              <button
                className="btn btn-primary px-6"
                onClick={() => this._save()}
                disabled={loading}
              >
                Send
              </button>
            </div>
          </Modal.Footer>

        </Modal>

        { showAdditionalExternalIdsModal &&
          <AdditionalExternalIds
            show={showAdditionalExternalIdsModal}
            onHide={() => this.setState({ showAdditionalExternalIdsModal: false })}
            additionalExternalIds={additionalExternalIds}
            onUpdated={additionalExternalIds => {
              this.setState({
                additionalExternalIds,
                showAdditionalExternalIdsModal: false
              })
            }}
            name="Additional MRN"
            namePlural="Additional MRNs"
            message="Manage Additional MRNs via the below."
          />
        }
      </>
    )
  }
}

PreBoardingNotification.defaultProps = {

}
