import React from "react"
import Card from "./components/structure/Card";
import DeclarationsTable from "./components/tables/SubmissionsTable";
import ProductsTable from "./components/tables/ProductsTable";
import PartiesTable from "./components/tables/PartiesTable";
import AuthorizationsTable from "./components/tables/AuthorisationsTable";
import Backend from "../utils/Backend";
import Notify from "../utils/Notify";

export default class Product extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      commodity: {}
    }
  }

  _handleSubmission(){
    let {
      commodity
    } = this.state

    if(commodity.id){
      this._updateAuthorisation(commodity)
    }else{
      this._addAuthorisation(commodity)
    }
  }

  _addAuthorisation(commodity){
    this.setState({loading: true})
    Backend.addProduct(commodity).then(item => {
      this.setState({loading: false})
      Notify.success("Product Added")
      window.location = '/value-lists'
    }).catch(e => {
      this.setState({loading: false})
      Notify.error(e.message)
    })
  }

  _updateAuthorisation(commodity){
    this.setState({loading: true})
    Backend.updateProduct(commodity).then(item => {
      this.setState({loading: false})
      Notify.success("Product Updated")
      window.location = '/value-lists'
    }).catch(e => {
      this.setState({loading: false})
      Notify.error(e.message)
    })
  }

  render() {
    let {

    } = this.state

    return (
      <>
        <div className="row">
          <div className="col-sm-12 col-xl-12">
            <Card
              title={"Add Product"}
            >
              <div className="flex-column" data-kt-stepper-element="content">
                <div id="goodsShipmentItem">
                  <div className="form-group">
                    <div className="accordion" id="shipmentItemSections">
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="shipmentItemSections_header_1">
                          <button
                            className="accordion-button fs-4 fw-semibold collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#shipmentItemSections_body_1"
                            aria-expanded="false"
                            aria-controls="shipmentItemSections_body_1"
                          >
                            Product Details
                          </button>
                        </h2>
                        <div
                          id="shipmentItemSections_body_1"
                          className="accordion-collapse collapse"
                          aria-labelledby="shipmentItemSections_header_1"
                          data-bs-parent="#shipmentItemSections"
                        >
                          <div className="accordion-body">
                            <div className="row">
                              <div className="col-md-4">
                                <div className="form-floating mb-4">
                                  <select className="form-select" id="trader" aria-label="Trader">
                                    <option value="all" selected="">All / Global</option>
                                    <option value="ABC Plumbing">ABC Plumbing</option>
                                    <option value="ALB UK">ALB UK</option>
                                    <option value="Baking Matters">Baking Matters</option>
                                    <option value="CAP Group">CAP Group</option>
                                    <option value="Norwegian Planes">Norwegian Planes</option>
                                  </select>
                                  <label htmlFor="trader">Trader</label>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-floating mb-4">
                                  <input type="text" className="form-control" id="sadType" placeholder="SAD Type"/>
                                  <label htmlFor="sadType">SAD Type</label>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="row mx-0">
                                  <div className="col-md-7 mx-0">
                                    <div className="form-floating mb-4">
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="commodityCode"
                                        placeholder="Product"
                                        inputMode="text"
                                      />
                                      <label htmlFor="commodityCode">Product</label>
                                    </div>
                                  </div>
                                  <div className="col-md-5 mx-0">
                                    <a
                                      href="https://ec.europa.eu/taxation_customs/dds2/taric/taric_consultation.jsp?Lang=en"
                                      className="btn btn-light-primary btn-sm mt-2"
                                      target="_blank"
                                    >
                                      Taric Code
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-4">
                                <div className="form-floating mb-4">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="description"
                                    placeholder="SAD Type"
                                  />
                                  <label htmlFor="description">Goods Description</label>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-floating mb-4">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="partNumber"
                                    placeholder="Part Number"
                                  />
                                  <label htmlFor="partNumber">Part Number</label>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-floating mb-4">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="alternatePartNumber"
                                    placeholder="Alternate Part Number"
                                  />
                                  <label htmlFor="alternatePartNumber">Alternate Part Number</label>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-floating mb-4">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="supplier"
                                    placeholder="Supplier Name"
                                  />
                                  <label htmlFor="supplier">Supplier Name</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h2 className="accordion-header" id="shipmentItemSections_header_2">
                          <button
                            className="accordion-button fs-4 fw-semibold collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#shipmentItemSections_body_2"
                            aria-expanded="false"
                            aria-controls="shipmentItemSections_body_2"
                          >
                            VAT / Duty Information
                          </button>
                        </h2>
                        <div
                          id="shipmentItemSections_body_2"
                          className="accordion-collapse collapse"
                          aria-labelledby="shipmentItemSections_header_2"
                          data-bs-parent="#shipmentItemSections"
                        >
                          <div className="accordion-body">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-floating mb-4">
                                  <select className="form-select" id="vatDeclaration" aria-label="VAT Rate Declaration">
                                    <option value="21%" selected="">Standard Rate (21%)</option>
                                    <option value="13.5%">Medium Rate (13.5%)</option>
                                    <option value="9%">VAT on Services (9%)</option>
                                    <option value="4%">Low Rate (4%)</option>
                                    <option value="0%">Zero VAT Rate</option>
                                  </select>
                                  <label htmlFor="vatDeclaration">VAT Rate Declaration</label>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-floating mb-4">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="dutyRate"
                                    placeholder="Duty Rate"
                                  />
                                  <label htmlFor="dutyRate">Duty Rate</label>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-floating mb-4">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="vatRate"
                                    placeholder="VAT Rate"
                                  />
                                  <label htmlFor="vatRate">VAT Rate</label>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-floating mb-4">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="exciseRate"
                                    placeholder="Excise Rate"
                                  />
                                  <label htmlFor="exciseRate">Excise Rate</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h2 className="accordion-header" id="shipmentItemSections_header_3">
                          <button
                            className="accordion-button fs-4 fw-semibold collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#shipmentItemSections_body_3"
                            aria-expanded="false"
                            aria-controls="shipmentItemSections_body_3"
                          >
                            SAD Information
                          </button>
                        </h2>
                        <div
                          id="shipmentItemSections_body_3" className="accordion-collapse collapse"
                          aria-labelledby="shipmentItemSections_header_3" data-bs-parent="#shipmentItemSections"
                        >
                          <div className="accordion-body">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-floating mb-4">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="taricCode"
                                    placeholder="TARIC Additional Code 1"
                                  />
                                  <label htmlFor="taricCode">TARIC Additional Code 1</label>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-floating mb-4">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="taricCode2"
                                    placeholder="TARIC Additional Code 2"
                                  />
                                  <label htmlFor="taricCode2">TARIC Additional Code 2</label>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-floating mb-4">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="nationalAdditionalCode"
                                    placeholder="National Additional Code"
                                  />
                                  <label htmlFor="nationalAdditionalCode">National Additional Code</label>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-floating mb-4">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="nationalAdditionalCode2"
                                    placeholder="National Additional Code 2"
                                    value="VATS"
                                  />
                                  <label htmlFor="nationalAdditionalCode2">National Additional Code</label>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-floating mb-4">
                                  <select className="form-select" id="supplimentaryUnits"
                                          aria-label="Supplimentary Units">
                                    <option value="1U01" selected="">1U01 - Carrying Capacity in Metric Tonnes</option>
                                    <option value="1U02">1U02 - Hundred Items</option>
                                    <option value="1U03">1U03 - Carats (one metric carat = 2x10.4kg)</option>
                                    <option value="1U04">1U04 - HectoKilogram</option>
                                    <option value="1U05">1U05 - HectoKilogram - Net Drained Weight</option>
                                  </select>
                                  <label htmlFor="supplimentaryUnits">Supplimentary Units</label>
                                </div>
                              </div>
                            </div>
                            <div className="separator separator-dashed my-6" bis_skin_checked="1"></div>
                            <h4 className="fw-bold mb-5">Product Conditions</h4>
                            <div id="commodityConditionRepeater">
                              <div className="form-group">
                                <div data-repeater-list="commodityConditionRepeater_outer">
                                  <div data-repeater-item="" className="mb-5">
                                    <div className="form-group row mb-5">
                                      <div className="col-md-6">
                                        <div className="form-floating mb-4">
                                          <div className="position-absolute translate-middle-y top-50 end-0 me-3">
                                            <a href="javascript:;" className="btn btn-sm btn-light-primary btn-icon"
                                               data-bs-toggle="modal" data-bs-target="#conditionCodeList">
																																			<span className="svg-icon svg-icon-muted">
																																				<svg width="24" height="24" viewBox="0 0 24 24"
                                                                             fill="none"
                                                                             xmlns="http://www.w3.org/2000/svg">
																																				<path
                                                                          d="M21.7 18.9L18.6 15.8C17.9 16.9 16.9 17.9 15.8 18.6L18.9 21.7C19.3 22.1 19.9 22.1 20.3 21.7L21.7 20.3C22.1 19.9 22.1 19.3 21.7 18.9Z"
                                                                          fill="currentColor"></path>
																																				<path opacity="0.3"
                                                                              d="M11 20C6 20 2 16 2 11C2 6 6 2 11 2C16 2 20 6 20 11C20 16 16 20 11 20ZM11 4C7.1 4 4 7.1 4 11C4 14.9 7.1 18 11 18C14.9 18 18 14.9 18 11C18 7.1 14.9 4 11 4ZM8 11C8 9.3 9.3 8 11 8C11.6 8 12 7.6 12 7C12 6.4 11.6 6 11 6C8.2 6 6 8.2 6 11C6 11.6 6.4 12 7 12C7.6 12 8 11.6 8 11Z"
                                                                              fill="currentColor"></path>
																																				</svg>
																																			</span>
                                            </a>
                                          </div>
                                          <input type="text" className="form-control" id="commodityCondition"
                                                 placeholder="Product Condition"/>
                                            <label htmlFor="commodityCondition">Product Condition</label>
                                        </div>
                                      </div>
                                      <div className="col-md-6 text-end">
                                        <a href="javascript:;" data-repeater-delete=""
                                           className="btn btn-sm btn-light-danger">
                                          <i className="la la-trash-o fs-3"></i>Delete Record
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="form-group">
                                <a href="javascript:;" data-repeater-create="" className="btn btn-light-primary">
                                  <i className="la la-plus"></i>Add Another Condition
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h2 className="accordion-header" id="shipmentItemSections_header_4">
                          <button className="accordion-button fs-4 fw-semibold collapsed" type="button"
                                  data-bs-toggle="collapse" data-bs-target="#shipmentItemSections_body_4"
                                  aria-expanded="false" aria-controls="shipmentItemSections_body_4">
                            Customer Product Information
                          </button>
                        </h2>
                        <div id="shipmentItemSections_body_4" className="accordion-collapse collapse"
                             aria-labelledby="shipmentItemSections_header_4" data-bs-parent="#shipmentItemSections">
                          <div className="accordion-body">
                            <div className="form-floating mb-4">
                              <textarea rows="5" className="form-control" id="brokerNotes" placeholder="Notes to Broker"
                                        value=""></textarea>
                              <label htmlFor="brokerNotes">Notes to Broker</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" d-flex justify-content-end py-6">
                <button
                  className="btn btn-primary px-6"
                  id="addProductCode"
                  onClick={() => this._handleSubmission()}
                >
                  Add Product
                </button>
              </div>
            </Card>
          </div>

        </div>
      </>
    )
  }

}