import React, {useState} from "react"

import { Elements, ElementsConsumer, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import Notify from "../../../utils/Notify";
import AuthManager from "../../../utils/AuthManager";
import Backend from "../../../utils/Backend";
import Currency from "../../../utils/Currency";

const CheckoutForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [billingDetails, setBillingDetails] = useState({name: ''});
  const [error, setError] = useState(null);

  const handleSubmit = async (event) => {
    setLoading(true)
    event.preventDefault();

    if (billingDetails.name === '') {
      setError('Please include the name on the card.')
      setLoading(false)
      return null
    }

    const {error, paymentMethod} = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
      billing_details: billingDetails
    });

    if (!paymentMethod) {
      setLoading(false)
      return null
    }

    props.data.payment_method_id = paymentMethod.id;

    Backend.subscribe(props.data).then((response) => {
      if (response.requires_action) {
        handleValidation(response)
      } else {
        setLoading(false)
        props.handleSuccessfulPayment()
      }
    }).catch((error) => {
      setLoading(false)
      props.handleFailedPayment(error.message)
    })
  };

  const handleValidation = (response) => {
    stripe.handleCardAction(response.payment_intent_client_secret).then(result => {
      if (result.error) {
        setLoading(false)
        props.handleFailedPayment(result.error.message)
      } else {
        props.data['payment_intent_id'] = result.paymentIntent.id;
        Backend.subscribe(props.data).then((response) => {
          setLoading(false)
          props.handleSuccessfulPayment()
        }).catch(errors => {
          setLoading(false)
          props.handleFailedPayment(errors.message)
        });
      }
    })
  };

  return (
    <form onSubmit={handleSubmit}>
      {
        error &&
        <div className="col-md-12">
          <p className={'color--error'}>{error}</p>
        </div>
      }
      <div className="d-flex flex-column mb-7 fv-row fv-plugins-icon-container">
        <label className="d-flex align-items-center fs-6 fw-bold form-label mb-2">
          <span className="required">Name On Card</span>
          <i
            className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title=""
            data-bs-original-title="Specify a card holder's name" aria-label="Specify a card holder's name"/>
        </label>
        <input
          type="text"
          className="form-control form-control-solid"
          placeholder=""
          onChange={e => {
            let value = e.target.value
            setBillingDetails({name: value})
            setError(null)
          }}
        />
        <div className="fv-plugins-message-container invalid-feedback"></div>
      </div>

      <div className="d-flex flex-column mb-7 fv-row fv-plugins-icon-container">
        <label className="d-flex align-items-center fs-6 fw-bold form-label mb-2">
          <span className="required">Card Details</span>
          <i
            className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title=""
            data-bs-original-title="Specify a card details" aria-label="Specify a card details"/>
        </label>
        <div 
          className="form-control form-control-solid"
          style={{minHeight: '41px'}}
        >
          <CardElement
            hidePostalCode={true}
          />
        </div>
        <div className="fv-plugins-message-container invalid-feedback"></div>
      </div>

      {
        !loading &&
        <>
          <div className="d-flex flex-stack pt-15">
            <div className="mr-2"/>
            <div>
              <button
                type="submit"
                className="btn btn-lg btn-primary"
                data-kt-stepper-action="next"
                disabled={!stripe}
              >
                Submit
                <span className="svg-icon svg-icon-4 ms-1">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)"
                        fill="black"></rect>
                  <path
                    d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                    fill="black"></path>
                </svg>
              </span>
              </button>
            </div>
          </div>
        </>
      }
      {
        loading &&
        <div className={'text-center'}>
          {/*<Spinner name="line-scale" color="#F18F00"/>*/}
        </div>
      }
    </form>
  );
};

export default class Payment extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      data: props.data,
      isLoading: false,
      total: props.total,
      currentAddOns: props.currentAddOns,
    }
  }

  componentDidMount() {
    const stripePromise = loadStripe(window.General.StripePublishablekey)

    this.setState({stripePromise})
  }

  render() {
    let {
      pkg,
      data,
      total,
      isLoading,
      stripePromise,
      currentAddOns
    } = this.state

    if (!stripePromise) {
      return null
    }

    return (
      <>
        <div className="current" data-kt-stepper-element="content">

          <div className="w-100">

            <div className="pb-10 pb-lg-15">

              <h2 className="fw-bolder text-dark">Billing Details</h2>

              <div className="text-muted fw-bold fs-6">If you need more info, please
                <a href="https://www.customswindow.com/#Message" target="_blank" className="link-primary fw-bolder"> Contact Support</a>.
              </div>

            </div>

            <label className="d-flex flex-stack mb-10 bg-light p-10 cursor-pointer">
              <span className="d-flex align-items-center me-2">
                <span className="d-flex flex-column">
                  <span className="fw-boldest text-gray-800 text-hover-primary fs-4">Total </span>
                </span>
              </span>
              <span className="fw-boldest text-gray-800 text-hover-primary fs-4">{Currency.format('€', total)} p/m </span>
            </label>

            <Elements stripe={stripePromise}>
              <CheckoutForm
                price={total}
                data={data}
                addOns={currentAddOns}
                handleFailedPayment={(message) => this._handleFailedPayment(message)}
                handleSuccessfulPayment={() => this.props.onPaymentSuccessful()}
              />
            </Elements>

          </div>

        </div>

      </>
    )
  }

}
