import React from "react";

import moment from "moment/moment";
import Flatpickr from "react-flatpickr";

import Modal from 'react-bootstrap/Modal'

import CodeInput from "../item/CodeInput";

import AsyncSelect from "../common/AsyncSelect";

import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";
import General from "../../../utils/General";

export default class Arrival extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      show: props.show,
      submission: props.submission,
    }
  }


  _isFormValid(){
    let {
      country,
      location,
      arrivalAt,
      locationType,
    } = this.state

    let error = null
    if(!country){
      error = "Please select a country"
    }
    else if(!locationType){
      error = "Please select a location type"
    }
    else if(!location){
      error = "Please select a location"
    }
    else if(!arrivalAt){
      error = "Please specify an arrival time"
    }

    if(error){
      Notify.error(error)
      return false
    }

    return true
  }

  _save(name){
    let {
      submission,
      country,
      location,
      arrivalAt,
      locationType
    } = this.state

    if(!this._isFormValid()){
      return
    }

    let data = {
      country: country.id,
      location: location.id,
      arrival_at: arrivalAt,
      submission: submission.id,
      location_type: locationType.id,
    }

    this.setState({ loading: true })
    Backend.arrivalNotification(data)
    .then(submission => {
      this.props.onArrived()
      Notify.success("Arrival Notification Sent!")
      this.setState({ loading: false })
    }).catch(e => {
      Notify.error(e.message)
      this.setState({ loading: false })
    })
  }

  render() {
    let {
      show,
      loading,
      template,
      country,
      location,
      arrivalAt,
      locationType,
    } = this.state

    return (
      <>
        <Modal
          show={show}
          onHide={() => this.props.onHide()}
          className={'sub-modal'}
          dialogClassName={"modal-50"}
        >
          <Modal.Header closeButton>
            <Modal.Title>Arrive</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="flex-column" data-kt-stepper-element="content">
              <div className="row">

                <div className="col-md-12">
                  <div className="mb-5">
                    <label className={"mb-2"}>Arrival Time</label>
                    <Flatpickr
                      className="form-control"
                      placeholder="Arrival Time"
                      value={arrivalAt}
                      options={{
                        enableTime: true,
                        noCalendar: false,
                        dateFormat: "Y-m-d H:i K"
                      }}
                      onChange={dates => {
                        let arrivalAt = moment(dates[0]).format('YYYY-MM-DDThh:mm:ss')
                        this.setState({ arrivalAt })
                      }}
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <CodeInput
                    placeholder={"Country Code"}
                    listKey={"CountryCodesFullList_CDS"}
                    value={country}
                    required={true}
                    editable={true}
                    onSelected={country => {
                        this.setState({ country })
                    }}
                  />
                </div>

                <div className="col-md-4">
                  <CodeInput
                    placeholder={"Location Type"}
                    listKey={"LocationType_CDS"}
                    value={locationType}
                    required={true}
                    editable={true}
                    onSelected={locationType => {
                        this.setState({ locationType })
                    }}
                  />
                </div>

                <div className="col-md-4">
                  <label className="mb-2">Location Identifier</label>
                  <div className="mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Identifier"
                      value={"U - UN/LOCODE"}
                      disabled
                      style={{ height: 48}}
                    />
                  </div>
                </div>

                <div className="col-md-12">
                  <CodeInput
                    placeholder={"Location"}
                    listKey={"LocationOfGoodsCode_CDS"}
                    value={location}
                    required={true}
                    editable={true}
                    onSelected={location => {
                        this.setState({ location })
                    }}
                  />
                </div>

              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex justify-content-end">
              <button
                className="btn btn-primary px-6"
                onClick={() => this._save()}
                disabled={loading}
              >
                Save
              </button>
            </div>
          </Modal.Footer>

        </Modal>
      </>
    )
  }
}

Arrival.defaultProps = {

}
