import React from "react";

import Item from "./Item";

import Backend from "../../../utils/Backend";

export default class ShipmentItem extends Item {

    constructor(props) {
        super(props);
        this.state = {
            show: props.show,
            ...this._getState(props)
        }
    }

  _handleBackend(product){
    let { existingShipmentItem } = this.props

    if(existingShipmentItem){
        return Backend.updateProduct(product)
    }
    else if(product.id){
        return this._handleCreate(product)
    }
    else{
        return Backend.addProduct(product)
        .then(product => this._handleCreate(product))
    }
  }

  _handleCreate(product){
      let { submission } = this.state

      product.item = product.id
      product.submission = submission.id
      delete product.id
      return Backend.createShipmentItem(product)
  }

}

ShipmentItem.defaultProps = {
  declaration: true,
  shipmentItem: false
}
