import React from "react"

import General from "../../../utils/General";
import AsyncSelect from "../common/AsyncSelect";
import Email from "../../../utils/Email";

export default class Party extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            field: props.field,
            editable: props.editable,
        }
    }

    componentWillReceiveProps(props) {
        this.setState(props)
    }

    getError(){
        let {
            field
        } = this.state

        if(field.is_required && !field.answer?.guarantee){
            return "Please select a guarantee"
        }
        return null
    }

    render(){
        let {
            field,
            editable,
            error
        } = this.state

        let value = field.answer?.guarantee ? {
            value: field.answer.guarantee.id,
            label: `${field.answer.guarantee.type.value} - ${field.answer.guarantee.type.description}`,
        } : null

        return (
            <AsyncSelect
                placeholder={`${field.placeholder}`}
                endpoint={window.Api.Guarantees}
                orderBy={"type__name"}
                isClearable={true}
                isDisabled={!editable}
                className={'custom-async-select__container'}
                classNamePrefix={'custom-async-select'}
                value={value}
                getOptions={options => {
                    return options.map(option => ({
                        value: option.id,
                        label: `${option.type.value} - ${option.type.description}`,
                        data: option
                    }))
                }}
                onSelected={option => {
                    let fieldData = General.clone(field)
                    if(option){
                        fieldData.answer = {
                            id: field.answer?.id,
                            guarantee: option
                        }
                    }else{
                        delete fieldData.answer
                    }
                    this.props.onUpdated(fieldData)
                }}
                onBlur={e => this.props.onBlur()}
            />
        )
    }
}
