import React from "react";

import Modal from 'react-bootstrap/Modal'
import PhoneInput from "react-phone-input-2";

import AsyncSelect from "../common/AsyncSelect";

import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";
import General from "../../../utils/General";

export default class TemplateForm extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      show: props.show,
      template: {
        name: null,
        form: null
      },
    }
  }

  _isFormValid(){
    let { template } = this.state

    let error = null
    if(!template.name){
      error = "Please enter a name"
    }
    else if(!template.form){
      error = "Please select a form"
    }

    if(error){
      Notify.error(error)
      return false
    }

    return true
  }

  _save(){
    if(!this._isFormValid()){
      return
    }
    let { template } = this.state
    this.setState({ loading: true })
    return Backend.createTemplate(template)
    .then(template => {
      let actionPerformed = template.id ? "Updated" : "Created"
      Notify.success(`Template ${actionPerformed} successfully`)
      this.props.onUpdated(template)
      this.setState({ loading: false })
    })
    .catch(error => {
      Notify.error(error.message)
      this.setState({ loading: false })
    })
  }

  render() {
    let {
      show,
      loading,
      template
    } = this.state

    return (
      <>
        <Modal
          show={show}
          onHide={() => this.props.onHide()}
          className={'sub-modal'}
          dialogClassName={"modal-50"}
        >
          <Modal.Header closeButton>
            <Modal.Title>Create Template</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="flex-column" data-kt-stepper-element="content">
              <div className="row">

                <div className="col-md-12">
                  <div className="fv-row mb-3">
                    <div className="form-floating mb-2">
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        placeholder="Name"
                        value={template.name}
                        onChange={e => {
                          template.name = e.target.value
                          this.setState({template})
                        }}
                      />
                      <div className="position-absolute translate-middle-y top-50 end-0 me-3">
                        <i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title=""
                           data-bs-original-title="Description of Field" aria-label="Description of Field"></i>
                      </div>
                      <label htmlFor="name">Name</label>
                    </div>
                  </div>
                </div>

                <div className="col-md-12">
                  <AsyncSelect
                    placeholder={"Please select a form"}
                    endpoint={window.Api.Forms}
                    orderBy={"name"}
                    isClearable={false}
                    className={'custom-async-select__container'}
                    classNamePrefix={'custom-async-select'}
                    value={template.form ? {
                      value: template.form.id,
                      label: `${template.form.name} (${template.form.type.toUpperCase()})`,
                      data: template.form
                    }: null}
                    getOptions={options => {
                        return options.map(option => ({
                            value: option.id,
                            label: `${option.name} (${option.type.toUpperCase()})`,
                            data: option
                        }))
                    }}
                    onSelected={form => {
                      template.form = form
                      this.setState({
                        template
                      })
                    }}
                  />
                </div>

              </div>
            </div>
            <div className=" d-flex justify-content-end py-6">
              <button
                className="btn btn-primary px-6"
                onClick={() => this._save()}
                disabled={loading}
              >
                Save
              </button>
            </div>
          </Modal.Body>

        </Modal>
      </>
    )
  }
}

TemplateForm.defaultProps = {

}
