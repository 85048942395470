import React from 'react';
import Dropzone from 'react-dropzone-uploader'

import ContentLoader from "react-content-loader";

import 'react-dropzone-uploader/dist/styles.css'

import AuthManager from '../../../utils/AuthManager'
import Backend from '../../../utils/Backend'

import fileImg from "../../../assets/media/svg/files/folder-document.svg";
import Notify from "../../../utils/Notify";

const HEIGHT = 100

const Layout = (props) => {
  const {
    input,
    previews,
    submitButton,
    dropzoneProps,
    files,
    uploadedFiles,
    deletable,
    extra: { maxFiles },
  } = props;

  return (
    <div className="layout">
      <div {...dropzoneProps}>
        {previews}

        {files.length < maxFiles && input}

        {files.length > 0 && submitButton}
      </div>

      <div className="uploaded-files">
        {/* {uploadedFiles.map((uploadedFile, index) => {
          return (
            <div className="file">
              <img
                className="img"
                src={uploadedFile?.thumbnail || fileImg}
                alt="Photo"
              />
              {deletable && (
                <div className="delete" onClick={() => props.onDelete(index)}>
                  <i className="fas fa-trash"></i>
                </div>
              )}
            </div>
          );
        })} */}
      </div>
    </div>
  );
};

export default class CustomDropzone extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      loading: false,
      file: null
    }
  }

  _getUploadParams({ file, meta }){
    const body = new FormData()
    body.append('file', file)
    body.append('public', false)

    let headers = {}
    if(AuthManager.accessToken){
      // headers["Content-Type"] = "Bearer " + AuthManager.accessToken
      headers["Authorization"] = "Bearer " + AuthManager.accessToken
    }

    let url = window.Api.Files

    return {
      file,
      body,
      headers,
      url
    }
  }

  // called every time a file's `status` changes
  _handleChangeStatus({ meta, file, xhr }, status){
    let complete = status === 'done' || status === "exception_upload"

    this.setState({ loading: !complete })
    if(status === "done"){
      let file = JSON.parse(xhr.responseText)
      this.props.onUploaded(file)
      this.setState({file})
    }
    if (status === "rejected_file_type" || status === "error_upload" || status === "error_validation") {
      this.setState({ loading: false });
    }
  }

  render(){
    let {
      file,
      loading,
    } = this.state

    let className = "custom-dropzone ";
    if (this.props.variation) {
      className += this.props.variation;
    }

    if(loading){
      return (
        <ContentLoader speed={0.5} style={{ width: '100%', height: HEIGHT }}>
          <rect x="0" y="0" rx="10" ry="10" width="100%" height="100%" />
        </ContentLoader>
      )
    }

    return (
      <div className={className}>
        <label className="drop-label">
          <input style={{ display: "none" }} />
          <Dropzone
            getUploadParams={values => this._getUploadParams(values)}
            onChangeStatus={(values, status) => this._handleChangeStatus(values, status)}
            accept={this.props.fileTypes.join(",")}
            multiple={false}
            maxFiles={1}
            styles={{
              dropzone: {
                width: 300,
                height: 50,
                border: "none",
                cursor: "pointer",
                backgroundColor: '#f1faff',
              },
              dropzoneReject: {
                borderColor: "#F19373",
                backgroundColor: "#F1BDAB",
              },
            }}
            SubmitButtonComponent={null}
            LayoutComponent={(props) => (
              <Layout
                {...props}
                />
            )}
            PreviewComponent={null}
            validate={(fileWithMeta) => {
              let validExtensions = this.props.fileTypes

              let fileExtension = fileWithMeta.file.name.slice(fileWithMeta.file.name.indexOf('.'))

              if(!validExtensions.includes(fileExtension)){
                Notify.error(`You can only upload ${validExtensions.join(", ")} files`)
                fileWithMeta.meta.status = "rejected_file_type"
                this.setState({loading: false})
                return fileWithMeta.meta
              }

              return null
            }}
            inputContent={
              <div className="dropzone-container mt-3">
                {file ? (
                  <>

                    <h5 className="dropzone-msg-title font-fam">
                      {file.name}
                    </h5>
                    <div
                      style={{
                        width: "50px",
                        height: "50px",
                        position: "relative",
                      }}
                    >
                      <img
                        style={{
                          width: 50,
                          height: 50,
                          position: "relative",
                          zIndex: "100",
                        }}
                        src={fileImg}
                        alt="File"
                      />
                    </div>
                    <h5 className="dropzone-msg-title font-fam">
                      Click to change file
                    </h5>
                  </>
                ) : (
                  <>
                    <h5 className="dropzone-msg-title font-fam">
                      Drop files here or click to upload
                    </h5>
                    <div className="upload-file">
                      {" "}
                      <i
                        className="fas fa-cloud-upload-alt fa-2x"
                        style={{ color: "lightgrey" }}
                      ></i>
                    </div>
                  </>
                )}
              </div>
            }
          />
        </label>
      </div>
    )
  }
}

CustomDropzone.defaultProps = {
  type: 'file',
  fileTypes: ['.p12', '.bac', '.p12.bac'],
  placeholder: 'Click To Upload Or Drag File Here'
}
