import React from "react"

import CodesTable from "./components/tables/CodesTable";

export default class Codes extends React.Component {

  constructor(props) {
    super(props)

    this.state = {

    }
    this.list = React.createRef()
  }

  componentDidMount() {

  }

  render() {
    let {

    } = this.state

    return (
      <>
        <CodesTable
          title={"Codes"}
          listId={this.props.match.params.listId}
        />
      </>
    )
  }

}
