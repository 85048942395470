import React from 'react'
import Tooltip from '@material-ui/core/Tooltip';

import AuthManager from "../../../../utils/AuthManager";
import Backend from "../../../../utils/Backend";
import Notify from "../../../../utils/Notify";

import Div from "../../common/Div"

export default class Duplicate extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      product: props.product,
      currentUser: AuthManager.currentUser
    }
  }

  _duplicateItem(){
    let {
      product
    } = this.state

    this.setState({isLoading: true})

    return Backend.getProduct(product.id)
    .then(data => {
      delete data.id
      return Backend.addProduct(data)
    })
    .then(duplicate => {
      this.props.onDuplicated(duplicate)
      this.setState({isLoading: false})
    }).catch(e => {
      Notify.error(e.message)
      this.setState({isLoading: false})
    })
  }

  render() {
    let { isLoading } = this.state
    return (
        <Tooltip title={this.props.tooltip} placement="top" arrow>
          <a
            className="btn btn-icon btn-sm btn-secondary me-2"
            onClick={() => this._duplicateItem()}
          >
          <Div disabled={isLoading}>
            <span
              className="svg-icon svg-icon-1"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect opacity="0.5" x="7" y="2" width="14" height="16" rx="3" fill="currentColor"/>
                <rect x="3" y="6" width="14" height="16" rx="3" fill="currentColor"/>
              </svg>
            </span>
            </Div>
          </a>
        </Tooltip>
    )
  }
}

Duplicate.defaultProps = {
  tooltip: "Duplicate",
}
