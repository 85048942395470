import React from "react";

import CodeInput from "./CodeInput"

import Codes from "../common/Codes"
import Input from "./Input";
import SmartList from "../common/SmartList";

export default class TransportDocuments extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            editable: props.editable,
            transportDocuments: props.transportDocuments
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState(nextProps)
    }

    _renderTransportDocuments(transportDocument, index){
        let {
            editable,
            transportDocuments
        } = this.state
        return (
            <div className="form-group row mb-5">
                <div className="col-md-6">
                    <CodeInput
                        placeholder={"Type"}
                        listKey={"CommonDocumentsType"}
                        value={transportDocument.type}
                        editable={editable}
                        onSelected={option => {
                            transportDocuments[index].type = option
                            this.props.onUpdated(transportDocuments)
                        }}
                    />
                </div>
                <div className="col-md-6">
                    <Input
                        type="text"
                        placeholder="Reference Number"
                        value={transportDocument.reference_number}
                        editable={editable}
                        onChange={identifier => {
                            transportDocuments[index].reference_number = identifier
                            this.props.onUpdated(transportDocuments)
                        }}
                    />
                </div>
            </div>
        )
    }


    render() {
        let {
            editable,
            transportDocuments,
        } = this.state

        let itemName = "Document"
        return (
            <SmartList
                minimal
                items={transportDocuments}
                itemName={itemName}
                showItemName={true}
                canDelete={editable}
                canAdd={editable}
                renderItem={(transportDocument, index) => this._renderTransportDocuments(transportDocument, index)}
                renderDelete={(item, index) => (
                    <a
                        className="btn btn-sm btn-light-danger"
                        onClick={() => {
                            transportDocuments.splice(index, 1)
                            this.props.onUpdated(transportDocuments)
                        }}
                    >
                        <i className="la la-trash-o fs-3"></i>
                        {itemName}
                    </a>
                )}
                onUpdated={items => {
                    transportDocuments = items
                    this.props.onUpdated(transportDocuments)
                }}
                onAddItemPressed={() => {
                    transportDocuments.push({})
                    this.props.onUpdated(transportDocuments)
                }}
            />
        )
    }


}

TransportDocuments.defaultProps = {

}
