import React from "react";

import moment from "moment";
import Flatpickr from "react-flatpickr";
import Tooltip from '@material-ui/core/Tooltip';

import Input from "./Input";
import CodeInput from "./CodeInput";
import Operations from "./Operations";
import Valuation from "./Valuation";
import Collapsible from "./Collapsible";
import Documents from "./Documents";
import Packages from "./Packages";
import AdditionalProcedures from "./AdditionalProcedures";
import AdditionalInformation from "./AdditionalInformation";
import AdditionalFiscalReferences from "./AdditionalFiscalReferences";
import NationalAdditionalCodes from "./NationalAdditionalCodes";
import TaricAdditionalCodes from "./TaricAdditionalCodes";
import TransportDocuments from "./TransportDocuments";

import Codes from "../common/Codes";

import ProductSettings from "../modal/ProductSettings";

import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";
import AuthManager from "../../../utils/AuthManager";
import FormUtils from "../../../utils/Form";


export default class Form extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            show: props.show,
            accordionKey: 0,
            ...this._getState(props)
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState(this._getState(nextProps))
    }

    _hidden(sectionId){
      let { submission } = this.state
      if(!submission){
        return false
      }
      let { type, subtype } = submission.form
      let formTypeHiddenSectionIds = HIDDEN_SECTIONS[type]
      if(!formTypeHiddenSectionIds){
        return false
      }

      let hiddenSectionIds = formTypeHiddenSectionIds[subtype]
      if(!hiddenSectionIds){
        return false
      }

      return hiddenSectionIds.indexOf(sectionId) > -1
    }

    _fieldVisibilityClassName(key){
      let { showHiddenFields } = this.state
      if(showHiddenFields){
        return ""
      }

      let fieldSetting = this._getFieldSetting(key)
      return fieldSetting.hidden ? "d-none" : ""
    }

    _isHiddenField(key){
      let { showHiddenFields } = this.state
      if(showHiddenFields){
        return false
      }

      let fieldSetting = this._getFieldSetting(key)
      return fieldSetting.hidden
    }

    _isEditableField(key){
      let fieldSetting = this._getFieldSetting(key)
      return fieldSetting.editable
    }

    _isExpandedField(key){
      let fieldSetting = this._getFieldSetting(key)
      return fieldSetting.expanded
    }

    _getFieldSetting(key){
      let { submission, product } = this.state

      let extra = product.extra || {}
      return extra[key] || {
        hidden: false,
        editable: true,
        expanded: submission ? false : true
      }
    }

    _getState(props){
        return {
            editable: props.editable,
            submission: props.submission,
            showHiddenFields: props.showHiddenFields,
            product: props.product || FormUtils.getItemTemplate()
        }
    }

    _isFormValid(){
        let {
            product
        } = this.state

        let error = null

        if(!product.name){
            error = "Please enter a name"
        }else if(!product.description){
            error = "Please enter a description"
        }else if(!product.quantity){
            error = "Please enter the quantify"
        }else if(!product.commodity_code.taric){
            error = "Please select a TARIC code"
        }

        if(error){
            Notify.error(error)
            return false
        }

        return true
    }

    _renderInput(type, key, placeholder, required=false){
        let { product, editable, showHiddenFields } = this.state

        if(!showHiddenFields && this._isHiddenField(key)){
          return null
        }

        return (
            <Input
                type={type}
                editable={editable && this._isEditableField(key)}
                className="form-control"
                placeholder={placeholder}
                value={product[key]}
                onChange={value => {
                    product[key] = value
                    this.props.onUpdated(product)
                }}
                renderToolbar={() => this._renderSetting(key, placeholder)}
            />
        )
    }

    _renderCodeInput(listKey, value, placeholder, required=false, onSelected, nameKey="name", queryParams={}, isMulti=false, allowCopy=false){
      let { editable, showHiddenFields } = this.state

        let key = placeholder
        if(!showHiddenFields && this._isHiddenField(key)){
          return null
        }

        return (
            <CodeInput
                placeholder={placeholder}
                listKey={listKey}
                value={value}
                required={required}
                editable={editable && this._isEditableField(key)}
                onSelected={onSelected}
                nameKey={nameKey}
                queryParams={queryParams}
                isMulti={isMulti}
                allowCopy={allowCopy}
                renderToolbar={() => this._renderSetting(key, placeholder)}
            />
        )
    }

    _renderSetting(key, title, section = false){
      let {
        product,
        submission
      } = this.state
      let isCompanyMember = AuthManager.currentUser.user.role == "company_member"

      if(!submission || !submission.template || !isCompanyMember){
        return null
      }

      return (
        <Tooltip
          title={<p style={{ fontSize: 12, padding: 8, marginBottom: 0}}>Settings</p>}
          fontSize={16}
          placement="top"
          arrow
          className="mb-1"
        >
          <a onClick={(e) => {
              this.setState({
                showSettingsModal: true,
                selectedSetting: {
                  key,
                  title,
                  section
                }
              })
            }}
          >
            <span class="svg-icon-help svg-icon-2x">
              <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="18px" height="18px" viewBox="0 0 24 24" version="1.1">
                <defs/>
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <rect x="0" y="0" width="24" height="24"/>
                    <path d="M5,8.6862915 L5,5 L8.6862915,5 L11.5857864,2.10050506 L14.4852814,5 L19,5 L19,9.51471863 L21.4852814,12 L19,14.4852814 L19,19 L14.4852814,19 L11.5857864,21.8994949 L8.6862915,19 L5,19 L5,15.3137085 L1.6862915,12 L5,8.6862915 Z M12,15 C13.6568542,15 15,13.6568542 15,12 C15,10.3431458 13.6568542,9 12,9 C10.3431458,9 9,10.3431458 9,12 C9,13.6568542 10.3431458,15 12,15 Z" fill="#999999"/>
                </g>
              </svg>
            </span>
          </a>
        </Tooltip>
      )
    }


    render() {
        let {
          product,
          editable,
          accordionKey,
          selectedSetting,
          showHiddenFields,
          showSettingsModal
        } = this.state

        return (
           <div className={"item-form"}>
               <div className="row">
                 <div className={`col ${this._fieldVisibilityClassName("name")}`} >
                      <div className="fv-row mb-4">
                          {
                              this._renderInput(
                                    "text",
                                    "name",
                                    "Name",
                                    true
                              )
                          }
                      </div>
                  </div>
                  <div className={`col ${this._fieldVisibilityClassName("description")}`} >
                       <div className="fv-row mb-4">
                           {
                                this._renderInput(
                                    "text",
                                    "description",
                                    "Description (6/8)",
                                    true
                                )
                            }
                       </div>
                   </div>
                  <div className={`col ${this._fieldVisibilityClassName("gross_mass")}`} >
                       <div className="fv-row mb-4">
                           {
                               this._renderInput(
                                   "number",
                                   "gross_mass",
                                   "Gross Mass (6/5)",
                                   false
                               )
                           }
                       </div>
                   </div>
                   <div className={`col ${this._fieldVisibilityClassName("net_mass")}`} hidden={this._hidden("net_mass")}>
                       <div className="fv-row mb-4">
                           {
                               this._renderInput(
                                   "number",
                                   "net_mass",
                                   "Net Mass (6/1)",
                                   false
                               )
                           }
                       </div>
                   </div>
                   <div className={`col ${this._fieldVisibilityClassName("supplementary_units")}`} hidden={this._hidden("supplementary_units")}>
                       <div className="fv-row mb-4">
                           {
                               this._renderInput(
                                   "number",
                                   "supplementary_units",
                                   "Supplementary Units (6/2)",
                                   false
                               )
                           }
                       </div>
                   </div>
               </div>

                <div
                  className="accordion"
                  id="shipmentItemSections"
                  key={`${accordionKey}_${showHiddenFields}`}
                >
                  <Collapsible
                    id={"taric-codes"}
                    title={"TARIC"}
                    defaultExpanded={this._isExpandedField("taric_section")}
                    hidden={this._hidden("taric") || this._isHiddenField("taric_section")}
                    renderToolbar={() => this._renderSetting("taric_section", "TARIC", true)}
                  >

                    <div className="row">
                        <div className={`col-md-3 ${this._fieldVisibilityClassName("TARIC Code (6/15)")}`} >
                            <div className="fv-row mb-4">
                                {
                                    this._renderCodeInput(
                                        "NomenclatureCode",
                                        product.commodity_code.taric,
                                        "TARIC Code (6/15)",
                                        true,
                                        option => {
                                            product.commodity_code.taric = option
                                            product.y_codes = []
                                            this.props.onUpdated(product)
                                        },
                                        "value",
                                        {},
                                        false,
                                        true
                                    )
                                }
                            </div>
                        </div>
                        <div className={`col-md-3 ${this._fieldVisibilityClassName("Y Codes")}`} >
                            <div
                             key={product.commodity_code.taric?.id}
                             className="fv-row mb-4"
                            >
                                {
                                    this._renderCodeInput(
                                        "CommonDocumentsType",
                                        product.y_codes.map(yCode => yCode.code),
                                        "Y Codes",
                                        false,
                                        codes => {
                                          console.log("***", codes)
                                          product.y_codes = codes?.map(code => {
                                            return {
                                              code
                                            }
                                          }) || []
                                          this.props.onUpdated(product)
                                        },
                                        "value",
                                        {
                                          cites_search_term: "Y",
                                          cites_id: product.commodity_code.taric?.id
                                        },
                                        true
                                    )
                                }
                            </div>
                        </div>
                        <div className={`col-md-3 ${this._fieldVisibilityClassName("Procedure Code (1/10)")}`} >
                            <div className="fv-row mb-4">
                                {
                                    this._renderCodeInput(
                                        "ProcedureCode",
                                        product.procedure,
                                        "Procedure Code (1/10)",
                                        true,
                                        option => {
                                            product.procedure = option
                                            this.props.onUpdated(product)
                                        }
                                    )
                                }
                            </div>
                        </div>

                        <div className={`col-md-3 ${this._fieldVisibilityClassName("Previous Procedure Code (1/10)")}`} >
                            <div className="fv-row mb-4">
                                {
                                    this._renderCodeInput(
                                        "ProcedureCode",
                                        product.previous_procedure,
                                        "Previous Procedure Code (1/10)",
                                        false,
                                        option => {
                                            product.previous_procedure = option
                                            this.props.onUpdated(product)
                                        }
                                    )
                                }
                            </div>
                        </div>

                        <div className={`col-md-3 ${this._fieldVisibilityClassName("Cus Code (6/13)")}`} >
                            <div className="fv-row mb-4">
                              {
                                  this._renderInput(
                                      "text",
                                      "cus_code",
                                      "Cus Code (6/13)",
                                      false
                                  )
                              }
                            </div>
                        </div>

                        <div className={`col-md-3 ${this._fieldVisibilityClassName("Quota Order Number (8/1)")}`} >
                            <div className="fv-row mb-4">
                              {
                                  this._renderInput(
                                      "text",
                                      "quota_order_no",
                                      "Quota Order Number (8/1)",
                                      false
                                  )
                              }
                            </div>
                        </div>

                        <div className={`col-md-3 ${this._fieldVisibilityClassName("Taric Additional Codes (6/16)")}`} >
                            <div className="fv-row mb-4">
                            {
                                this._renderCodeInput(
                                    "TaricAdditionalCode",
                                    product.commodity_code.taric_additional_codes.map(code => code.code),
                                    "Taric Additional Codes",
                                    false,
                                    codes => {
                                      product.commodity_code.taric_additional_codes = codes?.map(code => {
                                        return {
                                          code
                                        }
                                      }) || []
                                      this.props.onUpdated(product)
                                    },
                                    "value",
                                    {},
                                    true
                                )
                            }

                            </div>
                        </div>

                        <div className={`col-md-3 ${this._fieldVisibilityClassName("National Additional Codes (6/17)")}`} >
                            <div className="fv-row mb-4">
                            {
                                this._renderCodeInput(
                                    "TaricNationalAdditionalCode",
                                    product.commodity_code.national_additional_codes.map(code => code.code),
                                    "National Additional Codes (6/17)",
                                    false,
                                    codes => {
                                      product.commodity_code.national_additional_codes = codes?.map(code => {
                                        return {
                                          code
                                        }
                                      }) || []
                                      this.props.onUpdated(product)
                                    },
                                    "value",
                                    {},
                                    true
                                )
                            }

                            </div>
                        </div>

                    </div>

                  </Collapsible>

                  <Collapsible
                    id={"valuation"}
                    title={"Product Valuation"}
                    defaultExpanded={this._isExpandedField("valuation_section")}
                    hidden={this._hidden("valuation") || this._isHiddenField("valuation_section")}
                    renderToolbar={() => this._renderSetting("valuation_section", "Product Valuation", true)}
                  >
                      <Valuation
                          editable={editable}
                          product={product}
                          valuation={product.valuation}
                          onUpdated={valuation => {
                              product.valuation = valuation
                              this.props.onUpdated(product)
                          }}
                          onQuantityUpdated={quantity => {
                            product.quantity = quantity
                            this.props.onUpdated(product)
                          }}
                          onTransactionNatureUpdated={transactionNature => {
                            product.transaction_nature = transactionNature
                            this.props.onUpdated(product)
                          }}
                          isEditableField={key => this._isEditableField(key)}
                          renderToolbar={(key, placeholder) => this._renderSetting(key, placeholder)}
                          fieldVisibilityClassName={key => this._fieldVisibilityClassName(key)}
                      />
                  </Collapsible>

                  <Collapsible
                    id={"previous-document"}
                    title={"Previous Document (2/1)"}
                    defaultExpanded={this._isExpandedField("previous_documents_section")}
                    hidden={this._hidden("previous_documents") || this._isHiddenField("previous_documents_section")}
                    renderToolbar={() => this._renderSetting("previous_documents_section", "Previous Document (2/1)", true)}
                  >
                      <div className="row">
                          <div className={`col ${this._fieldVisibilityClassName("Previous Document Type")}`} >
                              <div className="fv-row mb-4">
                                  {
                                      this._renderCodeInput(
                                          "PreviousDocumentType",
                                          product.previous_document_type,
                                          "Previous Document Type",
                                          false,
                                          option => {
                                              product.previous_document_type = option
                                              this.props.onUpdated(product)
                                          }
                                      )
                                  }
                              </div>
                          </div>

                          <div className={`col ${this._fieldVisibilityClassName("Previous Document Identifier")}`} >
                              <div className="fv-row mb-4">
                                  {
                                      this._renderInput(
                                          "text",
                                          "previous_document_identifier",
                                          "Previous Document Identifier",
                                          true
                                      )
                                  }
                              </div>
                          </div>
                          <div className={`col ${this._fieldVisibilityClassName("Previous Document Line ID")}`} >
                              <div className="fv-row mb-4">
                                  {
                                      this._renderInput(
                                          "text",
                                          "previous_document_line_id",
                                          "Previous Document Line ID",
                                          true
                                      )
                                  }
                              </div>
                          </div>
                      </div>


                  </Collapsible>

                  <Collapsible
                    id={"dates-places"}
                    title="Dates & Places"
                    defaultExpanded={this._isExpandedField("dates_places_section")}
                    hidden={this._hidden("dates_places") || this._isHiddenField("dates_places_section")}
                    renderToolbar={() => this._renderSetting("dates_places_section", "Dates & Places", true)}
                  >
                      <div className="row">
                          <div className={`col-4 ${this._fieldVisibilityClassName("Destination Country (5/8)")}`} >
                              {
                                  this._renderCodeInput(
                                      "CountryCodesEU",
                                      product.dates_and_places.destination_country,
                                      "Destination Country (5/8)",
                                      false,
                                      option => {
                                          product.dates_and_places.destination_country = option
                                          this.props.onUpdated(product)
                                      }
                                  )
                              }
                          </div>
                          <div className={`col-4 ${this._fieldVisibilityClassName("Destination Region (5/9)")}`} >
                              {
                                  this._renderCodeInput(
                                      "DestinationRegionCode",
                                      product.dates_and_places.destination_region,
                                      "Destination Region (5/9)",
                                      false,
                                      option => {
                                          product.dates_and_places.destination_region = option
                                          this.props.onUpdated(product)
                                      }
                                  )
                              }
                          </div>
                          <div className={`col-4 ${this._fieldVisibilityClassName("Dispatch Country (5/14)")}`} >
                              <div className="fv-row mb-3">
                                  {
                                      this._renderCodeInput(
                                          "CountryCodesFullList",
                                          product.dates_and_places.dispatch_country,
                                          "Dispatch Country (5/14)",
                                          false,
                                          option => {
                                              product.dates_and_places.dispatch_country = option
                                              this.props.onUpdated(product)
                                          }
                                      )
                                  }
                              </div>
                          </div>
                          <div className={`col-4 ${this._fieldVisibilityClassName("Origin Country (5/15)")}`} >
                              <div className="fv-row mb-3">
                                  {
                                      this._renderCodeInput(
                                          "CountryCodesFullList",
                                          product.dates_and_places.origin_country,
                                          "Origin Country (5/15)",
                                          false,
                                          option => {
                                              product.dates_and_places.origin_country = option
                                              this.props.onUpdated(product)
                                          }
                                      )
                                  }
                              </div>
                          </div>
                          <div className={`col-4 ${this._fieldVisibilityClassName("Preferential Origin Country (5/16)")}`} >
                              <div className="fv-row mb-3">
                                  {
                                      this._renderCodeInput(
                                          "CountryCodesFullList",
                                          product.dates_and_places.preferential_origin_country,
                                          "Preferential Origin Country (5/16)",
                                          false,
                                          option => {
                                              product.dates_and_places.preferential_origin_country = option
                                              this.props.onUpdated(product)
                                          }
                                      )
                                  }
                              </div>
                          </div>
                          <div className={`col-4 ${this._fieldVisibilityClassName("Acceptance Date (5/31)")}`} >
                              <div className="fv-row mb-3">
                                  <label className={"mb-2"}>
                                    <span>Acceptance Date (5/31)</span>
                                    { this._renderSetting("Acceptance Date (5/31)", "Acceptance Date (5/31)") }
                                  </label>
                                  <Flatpickr
                                      className="form-control "
                                      value={product.dates_and_places?.acceptance_date}
                                      placeholder={"Acceptance Date"}
                                      style={{ height: 47.5 }}
                                      options={{
                                          enableTime: false,
                                          noCalendar: false,
                                          dateFormat: "Y-m-d"
                                      }}
                                      disabled={!editable || !this._isEditableField("Acceptance Date (5/31)")}
                                      onChange={dates => {
                                          product.dates_and_places.acceptance_date = moment(dates[0]).format('YYYY-MM-DD')
                                          this.props.onUpdated(product)
                                      }}
                                  />
                              </div>
                          </div>
                      </div>
                  </Collapsible>

                  <Collapsible
                    id={"operations"}
                    title={"Additions & Deductions (4/9)"}
                    defaultExpanded={this._isExpandedField("operations_section")}
                    hidden={this._hidden("operations") || this._isHiddenField("operations_section")}
                    renderToolbar={() => this._renderSetting("operations_section", "Additions & Deductions (4/9)", true)}
                  >

                      <Operations
                          editable={editable}
                          operations={product.valuation.operations}
                          onUpdated={operations => {
                              product.valuation.operations = operations
                              this.props.onUpdated(product)
                          }}
                      />

                  </Collapsible>

                  <Collapsible
                    id={"document"}
                    title={"Documents (2/3)"}
                    defaultExpanded={this._isExpandedField("documents_section")}
                    hidden={this._hidden("documents") || this._isHiddenField("documents_section")}
                    renderToolbar={() => this._renderSetting("documents_section", "Documents (2/3)", true)}
                  >

                      <Documents
                          editable={editable}
                          documents={product.documents}
                          onUpdated={documents => {
                              product.documents = documents
                              this.props.onUpdated(product)
                          }}
                      />

                  </Collapsible>

                  <Collapsible
                    id={"packages"}
                    title={"Packages"}
                    defaultExpanded={this._isExpandedField("packages_section")}
                    hidden={this._hidden("packages") || this._isHiddenField("packages_section")}
                    renderToolbar={() => this._renderSetting("packages_section", "Packages", true)}
                  >

                      <Packages
                          editable={editable}
                          packages={product.packages}
                          onUpdated={documents => {
                              product.packages = documents
                              this.props.onUpdated(product)
                          }}
                      />

                  </Collapsible>

                  <Collapsible
                    id={"additional-procedure"}
                    title={"Additional Procedures (1/11)"}
                    defaultExpanded={this._isExpandedField("additional_procedures_section")}
                    hidden={this._hidden("additional_procedures") || this._isHiddenField("additional_procedures_section")}
                    renderToolbar={() => this._renderSetting("additional_procedures_section", "Additional Procedures (1/11)", true)}
                  >

                      <AdditionalProcedures
                          editable={editable}
                          additionalProcedures={product.additional_procedures}
                          onUpdated={additionalProcedures => {
                              product.additional_procedures = additionalProcedures
                              this.props.onUpdated(product)
                          }}
                      />

                  </Collapsible>

                  <Collapsible
                    id={"additional-information"}
                    title={"Additional Information (2/2)"}
                    defaultExpanded={this._isExpandedField("additional_information_section")}
                    hidden={this._hidden("additional_information") || this._isHiddenField("additional_information_section")}
                    renderToolbar={() => this._renderSetting("additional_information_section", "Additional Information (2/2)", true)}
                  >

                      <AdditionalInformation
                          editable={editable}
                          additionalInformation={product.additional_information}
                          onUpdated={additionalInformation => {
                              product.additional_information = additionalInformation
                              this.props.onUpdated(product)
                          }}
                      />

                  </Collapsible>

                  <Collapsible
                    id={"additional-fiscal-references"}
                    title={"Additional Fiscal References (3/40)"}
                    defaultExpanded={this._isExpandedField("additional_fiscal_references_section")}
                    hidden={this._hidden("additional_fiscal_references") || this._isHiddenField("additional_fiscal_references_section")}
                    renderToolbar={() => this._renderSetting("additional_fiscal_references_section", "Additional Fiscal References (3/40)", true)}
                  >

                      <AdditionalFiscalReferences
                          editable={editable}
                          additionalFiscalReferences={product.additional_fiscal_references}
                          onUpdated={additionalFiscalReferences => {
                              product.additional_fiscal_references = additionalFiscalReferences
                              this.props.onUpdated(product)
                          }}
                      />

                  </Collapsible>

                  <Collapsible
                    id={"transport-documents"}
                    title={"Transport Documents"}
                    defaultExpanded={this._isExpandedField("transport_documents_section")}
                    hidden={this._hidden("transport_documents") || this._isHiddenField("transport_documents_section")}
                    renderToolbar={() => this._renderSetting("transport_documents_section", "Transport Documents", true)}
                  >

                      <TransportDocuments
                          editable={editable}
                          transportDocuments={product.transport_documents}
                          onUpdated={transportDocuments => {
                              product.transport_documents = transportDocuments
                              this.props.onUpdated(product)
                          }}
                      />

                  </Collapsible>
                </div>

                { showSettingsModal &&
                  <ProductSettings
                    show={showSettingsModal}
                    setting={selectedSetting}
                    product={product}
                    onUpdated={(extra) => {
                      product.extra = extra
                      this.setState({
                        product,
                        showSettingsModal: false,
                        selectedSetting: null,
                        accordionKey: this.state.accordionKey + 1
                      })
                    }}
                    onHide={() => {
                      this.setState({
                        showSettingsModal: false,
                        selectedSetting: null,
                      })
                    }}
                  />
                }
           </div>
        )
    }


}

Form.defaultProps = {
    declaration: true
}

const HIDDEN_SECTIONS = {
  "ais":{
    "G4G3": [
      "net_mass",
      "supplementary_units",
      "taric",
      "valuation",
      "previous_documents",
      "dates_places",
      "operations",
      "documents",
      "additional_procedures",
      "additional_information",
      "additional_fiscal_references",
      "transport_documents"
    ]
  }
}
