import React from "react"
import AuthManager from "../../../utils/AuthManager";
import Backend from "../../../utils/Backend";
import Email from "../../../utils/Email";
import Notify from "../../../utils/Notify";

export default class Profile extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      currentUser: AuthManager.currentUser,
      data: {...AuthManager.currentUser}
    }
  }

  _handleChangeUser(e) {
    let data = this.state.data
    data.user[e.target.name] = e.target.value
    this.setState({ data, error: null })
  }

  isProfileValid(){
    let {
      data
    } = this.state

    let {
      first_name,
      last_name,
      email
    } = data.user

    let error = null

    if(!first_name || first_name === ""){
      error = "Please enter a valid first name"
    }
    else if(!last_name || last_name === ""){
      error = "Please enter a valid last name"
    }
    else if(!Email.isValid(email)){
      error = "Please enter a valid email"
    }

    if(error){
      Notify.error(error)
      return false

    }

    return true
  }

  isEmailValid(){
    let {
      data
    } = this.state

    let {
      email,
      current_password
    } = data.user

    let error = null

    if(!current_password || current_password === ""){
      error = "Please enter your password"
    }
    else if(!Email.isValid(email)){
      error = "Please enter a valid email"
    }

    if(error){
      Notify.error(error)
      return false

    }

    return true
  }

  isPasswordValid(){
    let {
      data
    } = this.state

    let {
      current_password,
      password,
      confirm_password
    } = data.user

    let error = null

    if(!current_password || current_password === ""){
      error = "Please enter your current password"
    }else if(!password || password === ""){
      error = "Please enter your new password"
    }else if(!confirm_password || confirm_password === ""){
      error = "Please confirm your new password"
    }
    else if(password !== confirm_password){
      error = "Your password doesn't match"
    }

    if(error){
      Notify.error(error)
      return false

    }

    return true
  }

  _updateProfile(){
    let {
      data
    } = this.state

    if(!this.isProfileValid()){
      return
    }

    this.setState({ loading: true })
    this._update(data)
  }

  _updateEmail(){
    let {
      data
    } = this.state

    if(!this.isEmailValid()){
      return
    }

    this.setState({ loading: true })
    this._update(data)
  }

  _updatePassword(){
    let {
      data
    } = this.state

    if(!this.isPasswordValid()){
      return
    }

    this.setState({ loading: true })
    this._update(data)
  }

  _update(data){
    Backend.updateAccount(data)
      .then(user => {
        AuthManager.currentUser = user
        data = user
        Notify.success('Your details have been changed successfully')
        this.setState({
          loading: false,
          data
        })
      })
      .catch(error => {
        this.setState({
          loading: false
        })
        Notify.error(error.message)
      })
  }

  render() {
    let {
      loading,
      data,
      changeEmail,
      changePassword
    } = this.state

    return (
      <>
        <div className="card-header border-0">
          <div className="card-title m-0" bis_skin_checked="1">
            <h3 className="fw-bold m-0">Profile Settings</h3>
          </div>
        </div>
        <form id="kt_account_profile_details_form" className="form fv-plugins-bootstrap5 fv-plugins-framework"
              noValidate="novalidate">
          <div className="card-body border-top p-9" bis_skin_checked="1">
            <div className="row mb-6" bis_skin_checked="1">
              <label className="col-lg-4 col-form-label required fw-semibold fs-6">Full Name</label>
              <div className="col-lg-8" bis_skin_checked="1">
                <div className="row" bis_skin_checked="1">
                  <div className="col-lg-6 fv-row fv-plugins-icon-container" bis_skin_checked="1">
                    <input
                      type="text"
                      name="first_name"
                      className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                      placeholder="First name"
                      value={data.user.first_name}
                      onChange={e => this._handleChangeUser(e)}
                      style={{backgroundImage: 'url(&quot;data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAAfBJREFUWAntVk1OwkAUZkoDKza4Utm61iP0AqyIDXahN2BjwiHYGU+gizap4QDuegWN7lyCbMSlCQjU7yO0TOlAi6GwgJc0fT/fzPfmzet0crmD7HsFBAvQbrcrw+Gw5fu+AfOYvgylJ4TwCoVCs1ardYTruqfj8fgV5OUMSVVT93VdP9dAzpVvm5wJHZFbg2LQ2pEYOlZ/oiDvwNcsFoseY4PBwMCrhaeCJyKWZU37KOJcYdi27QdhcuuBIb073BvTNL8ln4NeeR6NRi/wxZKQcGurQs5oNhqLshzVTMBewW/LMU3TTNlO0ieTiStjYhUIyi6DAp0xbEdgTt+LE0aCKQw24U4llsCs4ZRJrYopB6RwqnpA1YQ5NGFZ1YQ41Z5S8IQQdP5laEBRJcD4Vj5DEsW2gE6s6g3d/YP/g+BDnT7GNi2qCjTwGd6riBzHaaCEd3Js01vwCPIbmWBRx1nwAN/1ov+/drgFWIlfKpVukyYihtgkXNp4mABK+1GtVr+SBhJDbBIubVw+Cd/TDgKO2DPiN3YUo6y/nDCNEIsqTKH1en2tcwA9FKEItyDi3aIh8Gl1sRrVnSDzNFDJT1bAy5xpOYGn5fP5JuL95ZjMIn1ya7j5dPGfv0A5eAnpZUY3n5jXcoec5J67D9q+VuAPM47D3XaSeL4AAAAASUVORK5CYII=&quot;)', backgroundRepeat: 'no-repeat', backgroundAttachment: 'scroll', backgroundSize: '16px 18px', backgroundPosition: '98% 50%', cursor: 'auto'}}
                    />
                    <div className="fv-plugins-message-container invalid-feedback" bis_skin_checked="1"/>
                  </div>

                  <div className="col-lg-6 fv-row fv-plugins-icon-container" bis_skin_checked="1">
                    <input
                      type="text"
                      name="last_name"
                      className="form-control form-control-lg form-control-solid"
                      placeholder="Last name"
                      value={data.user.last_name}
                      onChange={e => this._handleChangeUser(e)}
                    />
                    <div className="fv-plugins-message-container invalid-feedback" bis_skin_checked="1"/>
                  </div>
                </div>
              </div>
            </div>
            <div id="kt_account_signin_method" className="collapse show">
              <div className="border-top pt-9">
                <div className="d-flex flex-wrap align-items-center">
                  <div id="kt_signin_email" className={`${changeEmail ? 'd-none' : ''}`}>
                    <div className="fs-6 fw-bolder mb-1">Email Address</div>
                    <div className="fw-bold text-gray-600">{data.user.email}</div>
                  </div>
                  <div id="kt_signin_email_edit" className={`flex-row-fluid ${changeEmail ? '' : 'd-none'}`}>
                    <div className="row mb-6">
                      <div className="col-lg-6 mb-4 mb-lg-0">
                        <div className="fv-row mb-0">
                          <label htmlFor="emailaddress" className="form-label fs-6 fw-bolder mb-3">
                            Enter New Email Address
                          </label>
                          <input
                            type="email"
                            className="form-control form-control-lg form-control-solid"
                            id="email"
                            placeholder="Email Address"
                            name="email"
                            value={data.user.email}
                            onChange={e => this._handleChangeUser(e)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="fv-row mb-0">
                          <label htmlFor="password" className="form-label fs-6 fw-bolder mb-3">
                            Confirm Password
                          </label>
                          <input
                            type="password"
                            className="form-control form-control-lg form-control-solid"
                            name="current_password"
                            id="password"
                            value={data.user.password}
                            onChange={e => this._handleChangeUser(e)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="d-flex">
                      <button
                        id="kt_signin_submit"
                        type="button"
                        className="btn btn-primary me-2 px-6"
                        onClick={() => this._updateEmail()}
                        disabled={loading}
                      >
                        Update Email
                      </button>
                      <button
                        id="kt_signin_cancel"
                        type="button"
                        className="btn btn-color-gray-400 btn-active-light-primary px-6"
                        onClick={() => this.setState({changeEmail: false})}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                  {
                    !changeEmail &&
                    <div id="kt_signin_email_button" className="ms-auto">
                      <button
                        type="button"
                        className="btn btn-light btn-active-light-primary"
                        onClick={() => this.setState({changeEmail: true})}
                      >
                        Change Email
                      </button>
                    </div>
                  }
                </div>
                <div className="separator separator-dashed my-6"></div>
                <div className="d-flex flex-wrap align-items-center mb-10">
                  <div id="kt_signin_password" className={`${changePassword ? 'd-none' : ''}`}>
                    <div className="fs-6 fw-bolder mb-1">Password</div>
                    <div className="fw-bold text-gray-600">************</div>
                  </div>
                  <div id="kt_signin_password_edit" className={`flex-row-fluid ${changePassword ? '' : 'd-none'}`}>
                    <form id="kt_signin_change_password" className="form" noValidate="novalidate">
                      <div className="row mb-1">
                        <div className="col-lg-4">
                          <div className="fv-row mb-0">
                            <label
                              htmlFor="currentpassword"
                              className="form-label fs-6 fw-bolder mb-3"
                            >
                              Current Password
                            </label>
                            <input
                              type="password"
                              className="form-control form-control-lg form-control-solid"
                              name="current_password"
                              id="currentpassword"
                              value={data.user.current_password}
                              onChange={e => this._handleChangeUser(e)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="fv-row mb-0">
                            <label
                              htmlFor="newpassword"
                              className="form-label fs-6 fw-bolder mb-3"
                            >
                              New Password
                            </label>
                            <input
                              type="password"
                              className="form-control form-control-lg form-control-solid"
                              name="password"
                              id="newpassword"
                              value={data.user.password}
                              onChange={e => this._handleChangeUser(e)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="fv-row mb-0">
                            <label
                              htmlFor="confirmpassword"
                              className="form-label fs-6 fw-bolder mb-3"
                            >
                              Confirm NewPassword
                            </label>
                            <input
                              type="password"
                              className="form-control form-control-lg form-control-solid"
                              name="confirm_password"
                              id="confirmpassword"
                              value={data.user.confirm_password}
                              onChange={e => this._handleChangeUser(e)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-text mb-5">
                        Password must be at least 8 character and contain symbols
                      </div>
                      <div className="d-flex">
                        <button
                          id="kt_password_submit"
                          type="button"
                          className="btn btn-primary me-2 px-6"
                          onClick={() => this._updatePassword()}
                          disabled={loading}
                        >
                          Update Password
                        </button>
                        <button
                          id="kt_password_cancel"
                          type="button"
                          className="btn btn-color-gray-400 btn-active-light-primary px-6"
                          onClick={() => this.setState({changePassword: false})}
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  </div>
                  {
                    !changePassword &&
                    <div id="kt_signin_password_button" className="ms-auto">
                      <button
                        type="button"
                        className="btn btn-light btn-active-light-primary"
                        onClick={() => this.setState({changePassword: true})}
                      >
                        Update Password
                      </button>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </form>
        <div className="card-footer d-flex justify-content-end py-6 px-9" bis_skin_checked="1">
          {/*<button type="reset" className="btn btn-light btn-active-light-primary me-2">*/}
          {/*  Discard*/}
          {/*</button>*/}
          <button
            type="submit"
            className="btn btn-primary"
            id="kt_account_profile_details_submit"
            onClick={() => this._updateProfile()}
            disabled={loading}
          >
            Save Changes
          </button>
        </div>
        <input type="hidden"/>
        <div bis_skin_checked="1"></div>
      </>
    )
  }

}