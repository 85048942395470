import React from "react"

import CodeListsTable from "./components/tables/CodeListsTable";

export default class CodeLists extends React.Component {

  constructor(props) {
    super(props)

    this.state = {

    }
    this.list = React.createRef()
  }

  componentDidMount() {

  }

  render() {
    let {

    } = this.state

    return (
      <>
        <CodeListsTable
          title={"Code Lists"}
        />
      </>
    )
  }

}
