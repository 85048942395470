import React from 'react'

import Tooltip from '@material-ui/core/Tooltip';

import Confirmation from "../../modal/Confirmation";

import Notify from "../../../../utils/Notify";
import Backend from "../../../../utils/Backend";

export default class Select extends React.Component {
  constructor(props){
    super(props)

    this.state = {

    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _handleSelect(){
    let { id } = this.props

    this.setState({ loading: true })
    Backend.isPBNApplicable(id)
    .then(response => {
      this.setState({ loading: false })
      if(response.pbn_applicable){
        this._select()
      }
      else{
        this.setState({ showConfirmationModal: true })
      }
    })
    .catch(error => {
      this.setState({ loading: false })
      Notify.error(error.message)
    })
  }

  _select(){
    let { id, ids } = this.props

    let newIds = [...ids]

    newIds.push(id)
    this.props.onUpdated(newIds)
  }

  render() {
    let { id, ids } = this.props
    let { loading, showConfirmationModal } = this.state

    let selected = ids.includes(id)

    let className = selected ? "btn-primary" : "btn-secondary"
    return (
      <>
        <Tooltip title={this.props.tooltip} placement="top" arrow>
          <a
            className={`btn btn-icon ${className} btn-sm me-2`}
            disabled={loading}
            onClick={e => {
              let newIds = [...ids]
              if(selected){
                let index = newIds.findIndex(o => o == id)
                newIds.splice(index, 1)
                this.props.onUpdated(newIds)
              }
              else{
                this._handleSelect()
              }
            }}
          >
            { loading ? (
                <i className="fa fa-spinner fa-spin" />
              ) : (
                <i className="fa fa-check" />
              )
            }
          </a>
        </Tooltip>
        {
          showConfirmationModal &&
          <Confirmation
            show={showConfirmationModal}
            message="This declaration does not appear to be RoRo / Sea Freight and thus the PBN could fail to be created."
            onConfirm={() => {
              this._select()
              this.setState({showConfirmationModal: false})
            }}
            onHide={() => this.setState({showConfirmationModal: false})}
          />
        }
      </>
    )
  }
}

Select.defaultProps = {
  tooltip: 'Select'
}
