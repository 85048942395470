import React from "react";

import CodeInput from "./CodeInput"

import Codes from "../common/Codes"
import Input from "./Input";
import SmartList from "../common/SmartList";

export default class Packages extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            editable: props.editable,
            packages: props.packages,
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState(nextProps)
    }

    _renderPackage(pkg, index){
        let {
            editable,
            packages
        } = this.state
        return (
            <div className="form-group row mb-5">
                <div className="col-md-4">
                    <CodeInput
                        placeholder={"Package Type (6/9)"}
                        listKey={"KindOfPackages"}
                        value={pkg.type}
                        editable={editable}
                        onSelected={option => {
                            packages[index].type = option
                            this.props.onUpdated(packages)
                        }}
                    />
                </div>
                <div className="col-md-4">
                    <Input
                        type="text"
                        placeholder="Shipping Remarks (6/11)"
                        value={pkg.shipping_marks}
                        editable={editable}
                        onChange={value => {
                            packages[index].shipping_marks = value
                            this.props.onUpdated(packages)
                        }}
                    />
                </div>
                <div className="col-md-4">
                    <Input
                        type="text"
                        placeholder="Number (6/10)"
                        value={pkg.count}
                        editable={editable}
                        onChange={value => {
                            packages[index].count = value
                            this.props.onUpdated(packages)
                        }}
                    />
                </div>
            </div>
        )
    }


    render() {
        let {
            editable,
            packages,
        } = this.state

        let itemName = "Package"
        return (
            <SmartList
                minimal
                items={packages}
                showItemName={true}
                itemName={itemName}
                canDelete={editable}
                canAdd={editable}
                renderItem={(item, index) => this._renderPackage(item, index)}
                renderDelete={(item, index) => (
                    <a
                        className="btn btn-sm btn-light-danger"
                        onClick={() => {
                            packages.splice(index, 1)
                            this.props.onUpdated(packages)
                        }}
                    >
                        <i className="la la-trash-o fs-3"></i>
                        {itemName}
                    </a>
                )}
                onUpdated={items => {
                    packages = items
                    this.props.onUpdated(packages)
                }}
                onAddItemPressed={() => {
                    packages.push({})
                    this.props.onUpdated(packages)
                }}
            />
        )
    }


}

Packages.defaultProps = {

}
