import React from "react";
import Modal from 'react-bootstrap/Modal'

import SmartList from "../common/SmartList";

import General from "../../../utils/General";
import Notify from "../../../utils/Notify";

export default class AdditionalExternalIds extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      show: props.show,
      additionalExternalIds: General.clone(props.additionalExternalIds),
    }
  }

  _update(additionalExternalIds){

    let error = null
    for(var i = 0; i < additionalExternalIds.length; i++){
      let additionalExternalId = additionalExternalIds[i]
      if(!additionalExternalId || additionalExternalId == ""){
        error = `${this.props.name} #${i+1} cannot be empty`
        break
      }
    }

    if(error){
      Notify.error(error)
      return
    }

    this.props.onUpdated(additionalExternalIds)
  }

  _renderId(id, index){
    let {
      additionalExternalIds
    } = this.state

    return (
      <div className="form-group row mt-5 mb-5">
        <div className="col-md-12">
          <div className="form-floating mb-4">
            <input
              type="text"
              className="form-control"
              placeholder={this.props.name}
              value={id}
              onChange={e => {
                additionalExternalIds[index] = e.target.value || null
                this.setState({ additionalExternalIds })
              }}
            />
            <label>{this.props.name}</label>
          </div>
        </div>
      </div>
    )
  }


  render() {
    let {
      show,
      additionalExternalIds
    } = this.state

    return (
      <>
        <Modal
          show={show}
          onHide={() => this.props.onHide()}
          className={'sub-modal'}
          dialogClassName={"modal-40"}
        >
          <Modal.Header closeButton>
            <Modal.Title>{this.props.namePlural}</Modal.Title>
          </Modal.Header>

          <Modal.Body>

            <p className="mb-10">
              {this.props.message}
            </p>

            <SmartList
              items={additionalExternalIds.map(id => ({ id }) )}
              itemName={this.props.name}
              renderItem={(item, index) => this._renderId(item.id, index)}
              renderDelete={(id, index) => (
                <a
                  href="javascript:;"
                  data-repeater-delete=""
                  className="btn btn-sm btn-light-danger"
                  onClick={() => {
                    additionalExternalIds.splice(index, 1)
                    this.setState({ additionalExternalIds })
                  }}
                >
                  <i className="la la-trash-o fs-3"></i>Delete {this.props.name}
                </a>
              )}
              onUpdated={additionalExternalIds => {
                this.setState({ additionalExternalIds: additionalExternalIds.map(item => item.id) })
              }}
              onAddItemPressed={() => {
                additionalExternalIds.push("")
                this.setState({additionalExternalIds})
              }}
            />

            <div className="form-group text-end mt-10">
              <button
                type="button"
                className="btn btn-light-primary"
                onClick={() => this._update(additionalExternalIds)}
              >
                Update
              </button>
            </div>
          </Modal.Body>

        </Modal>
      </>
    )
  }


}

AdditionalExternalIds.defaultProps = {
  name: "Additional Customer Reference",
  namePlural: "Additional Customer References",
  message: "Manage Additional Customer References via the below. Note the IDs entered below will not be sent to customs."
}
