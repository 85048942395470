import React from "react"

import Form from "../../../utils/Form";
import General from "../../../utils/General";

export default class Label extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            field: props.field,
            index: props.index,
            submission: props.submission
        }
    }

    componentWillReceiveProps(props) {
        this.setState(props)
    }

    _getGrossMass(submission){
      let answers = Form.getAnswers(submission)
      return answers.reduce((totalKg, answer) => {
        if(!answer.shipment_item){
          return totalKg
        }
        let shipmentItem = answer.shipment_item
        if(shipmentItem.gross_mass){
          totalKg += parseFloat(shipmentItem.gross_mass)
        }
        return totalKg
      }, 0)
    }

    render(){
        let {
            field,
            submission
        } = this.state

        let value = field.answer?.value

        if(field.placeholder === "LRN"){
            value = submission?.lrn
            if(submission.template || !value){
              value = "System Generated"
            }
        }else if(field.placeholder === "Status"){
            value = General.toTitleCase(submission?.status)
        }else if(field.placeholder === "Declarant"){
            value = submission?.declarant?.name
        }else if(field.placeholder === "Gross Mass (kg)"){
            value = this._getGrossMass(submission)
        }

        return (
            <input
                type="text"
                className="form-control"
                placeholder={field.placeholder}
                value={value}
                disabled={true}
            />
        )
    }
}
