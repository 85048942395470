export default class MockData {
  static H1_FORM = {
    "id": "form_cle5fwxiv0ihy2cutzpq67kng",
    "name": "H1",
    "type": "ais",
    "subtype": "H1",
    "sections": [
      {
        "id": "sctn_cle5fwy4n0ihz2cutovbqku4d",
        "title": "Declaration Details",
        "rows": [
          {
            "fields": [
              {
                "id": "fld_cle5fwy6o0ii12cutix0z3nb3",
                "title": "LRN",
                "placeholder": "LRN",
                "description": null,
                "is_required": false,
                "format": {
                  "type": "label",
                  "party_type": null,
                  "min_length": null,
                  "max_length": null,
                  "code_list_key": null
                }
              },
              {
                "id": "fld_cle5fwy7p0ii22cutyxbh3cbq",
                "title": "Status",
                "placeholder": "Status",
                "description": null,
                "is_required": false,
                "format": {
                  "type": "label",
                  "party_type": null,
                  "min_length": null,
                  "max_length": null,
                  "code_list_key": null
                }
              }
            ]
          },
          {
            "fields": [
              {
                "id": "fld_cle5fwy9l0ii42cutlvc9zf4k",
                "title": "Message Type",
                "placeholder": "Message Type",
                "description": null,
                "is_required": true,
                "format": {
                  "type": "code",
                  "party_type": null,
                  "min_length": 1,
                  "max_length": 255,
                  "code_list_key": "CL001"
                }
              },
              {
                "id": "fld_cle5fwyan0ii52cut35lh865u",
                "title": "Declaration Type",
                "placeholder": "Declaration Type",
                "description": null,
                "is_required": true,
                "format": {
                  "type": "code",
                  "party_type": null,
                  "min_length": 2,
                  "max_length": 2,
                  "code_list_key": "TypeOfDeclaration"
                }
              }
            ]
          },
          {
            "fields": [
              {
                "id": "fld_cle5fwych0ii72cut8xvb5ayi",
                "title": "No. of Products",
                "placeholder": "No. of Products",
                "description": null,
                "is_required": false,
                "format": {
                  "type": "text",
                  "party_type": null,
                  "min_length": 1,
                  "max_length": 8,
                  "code_list_key": null
                }
              },
              {
                "id": "fld_cle5fwydi0ii82cutzfn0wlu3",
                "title": "Declarant",
                "placeholder": "Declarant",
                "description": null,
                "is_required": false,
                "format": {
                  "type": "label",
                  "party_type": null,
                  "min_length": null,
                  "max_length": null,
                  "code_list_key": null
                }
              }
            ]
          },
          {
            "fields": [
              {
                "id": "fld_cle5fwyf90iia2cutj7qh498u",
                "title": "Additional Declaration Type",
                "placeholder": "Additional Declaration Type",
                "description": null,
                "is_required": false,
                "format": {
                  "type": "code",
                  "party_type": null,
                  "min_length": 1,
                  "max_length": 1,
                  "code_list_key": "AdditionalDeclarationType"
                }
              },
              {
                "id": "fld_cle5fwygd0iib2cuta0fe31ip",
                "title": "Deferred Payment",
                "placeholder": "Deferred Payment",
                "description": null,
                "is_required": false,
                "format": {
                  "type": "text",
                  "party_type": null,
                  "min_length": 1,
                  "max_length": 35,
                  "code_list_key": null
                }
              }
            ]
          }
        ],
        "sections": [],
        "repeatable": false,
        "collapsible": false,
        "allow_blank": false
      },
      {
        "id": "sctn_cle5fwz4s0iic2cutq1pb7wf0",
        "title": "Parties",
        "rows": [],
        "sections": [
          {
            "id": "sctn_cle5fwzr70iid2cutn6ria7m9",
            "title": "Declarant",
            "rows": [
              {
                "fields": [
                  {
                    "id": "fld_cle5fwzsv0iif2cut0yp38t1j",
                    "title": "Declarant",
                    "placeholder": "Declarant",
                    "description": null,
                    "is_required": false,
                    "format": {
                      "type": "label",
                      "party_type": null,
                      "min_length": null,
                      "max_length": null,
                      "code_list_key": null
                    }
                  }
                ]
              }
            ],
            "sections": [],
            "repeatable": false,
            "collapsible": true,
            "allow_blank": false
          },
          {
            "id": "sctn_cle5fx0f50iig2cut77g2evuu",
            "title": "Representative",
            "rows": [
              {
                "fields": [
                  {
                    "id": "fld_cle5fx0h30iii2cutflftlax6",
                    "title": "Identification Number",
                    "placeholder": "Identification Number",
                    "description": null,
                    "is_required": false,
                    "format": {
                      "type": "party",
                      "party_type": "representative",
                      "min_length": null,
                      "max_length": null,
                      "code_list_key": null
                    }
                  }
                ]
              }
            ],
            "sections": [],
            "repeatable": false,
            "collapsible": true,
            "allow_blank": false
          },
          {
            "id": "sctn_cle5fx13v0iij2cutee6ebi4v",
            "title": "Exporter",
            "rows": [
              {
                "fields": [
                  {
                    "id": "fld_cle5fx1650iil2cut8x41waf5",
                    "title": "Export",
                    "placeholder": "Exporter",
                    "description": null,
                    "is_required": false,
                    "format": {
                      "type": "party",
                      "party_type": "exporter",
                      "min_length": null,
                      "max_length": null,
                      "code_list_key": null
                    }
                  }
                ]
              }
            ],
            "sections": [],
            "repeatable": false,
            "collapsible": true,
            "allow_blank": false
          },
          {
            "id": "sctn_cle5fx1xv0iim2cutpp8hdhnv",
            "title": "Importer",
            "rows": [
              {
                "fields": [
                  {
                    "id": "fld_cle5fx1zv0iio2cut74brc0nu",
                    "title": "Importer",
                    "placeholder": "Importer",
                    "description": null,
                    "is_required": false,
                    "format": {
                      "type": "party",
                      "party_type": "importer",
                      "min_length": null,
                      "max_length": null,
                      "code_list_key": null
                    }
                  }
                ]
              }
            ],
            "sections": [],
            "repeatable": false,
            "collapsible": true,
            "allow_blank": false
          },
          {
            "id": "sctn_cle5fx2n80iip2cut4ftfsis5",
            "title": "Buyer",
            "rows": [
              {
                "fields": [
                  {
                    "id": "fld_cle5fx2oq0iir2cut1473384z",
                    "title": "Buyer",
                    "placeholder": "Buyer",
                    "description": null,
                    "is_required": false,
                    "format": {
                      "type": "party",
                      "party_type": "buyer",
                      "min_length": null,
                      "max_length": null,
                      "code_list_key": null
                    }
                  }
                ]
              }
            ],
            "sections": [],
            "repeatable": false,
            "collapsible": true,
            "allow_blank": false
          },
          {
            "id": "sctn_cle5fx3bw0iis2cutqmyb0srb",
            "title": "Seller",
            "rows": [
              {
                "fields": [
                  {
                    "id": "fld_cle5fx3dl0iiu2cutfxbfc4g9",
                    "title": "Seller",
                    "placeholder": "Seller",
                    "description": null,
                    "is_required": false,
                    "format": {
                      "type": "party",
                      "party_type": "seller",
                      "min_length": null,
                      "max_length": null,
                      "code_list_key": null
                    }
                  }
                ]
              }
            ],
            "sections": [],
            "repeatable": false,
            "collapsible": true,
            "allow_blank": false
          },
          {
            "id": "sctn_cle5fx4150iiv2cutx53tpr2y",
            "title": "Persons",
            "rows": [
              {
                "fields": [
                  {
                    "id": "fld_cle5fx42v0iix2cutr24xt14v",
                    "title": "Person Providing Guarantee",
                    "placeholder": "Person Providing Guarantee",
                    "description": null,
                    "is_required": false,
                    "format": {
                      "type": "text",
                      "party_type": null,
                      "min_length": 1,
                      "max_length": 17,
                      "code_list_key": null
                    }
                  },
                  {
                    "id": "fld_cle5fx43v0iiy2cut0gy3cddr",
                    "title": "Person Paying Customs Duty",
                    "placeholder": "Person Paying Customs Duty",
                    "description": null,
                    "is_required": false,
                    "format": {
                      "type": "text",
                      "party_type": null,
                      "min_length": 1,
                      "max_length": 17,
                      "code_list_key": null
                    }
                  }
                ]
              }
            ],
            "sections": [],
            "repeatable": false,
            "collapsible": true,
            "allow_blank": false
          },
          {
            "id": "sctn_cle5fx4r00iiz2cuteekh6wpj",
            "title": "Guarantee",
            "rows": [
              {
                "fields": [
                  {
                    "id": "fld_cle5fx4ss0ij12cut6p9ak2jf",
                    "title": "Guarantee",
                    "placeholder": "Guarantee",
                    "description": null,
                    "is_required": false,
                    "format": {
                      "type": "guarantee",
                      "party_type": null,
                      "min_length": null,
                      "max_length": null,
                      "code_list_key": null
                    }
                  }
                ]
              }
            ],
            "sections": [],
            "repeatable": false,
            "collapsible": true,
            "allow_blank": false
          }
        ],
        "repeatable": false,
        "collapsible": false,
        "allow_blank": false
      },
      {
        "id": "sctn_cle5fx5g70ij22cutmgsaxcwt",
        "title": "Payment Valuation",
        "rows": [
          {
            "fields": [
              {
                "id": "fld_cle5fx5hy0ij42cuti12fy1s7",
                "title": "Currency",
                "placeholder": "Currency",
                "description": null,
                "is_required": false,
                "format": {
                  "type": "code",
                  "party_type": null,
                  "min_length": 3,
                  "max_length": 3,
                  "code_list_key": "Currencies"
                }
              },
              {
                "id": "fld_cle5fx5j20ij52cutxd8c0uhw",
                "title": "Total Amount Invoiced",
                "placeholder": "Total Amount Invoiced",
                "description": null,
                "is_required": false,
                "format": {
                  "type": "cents",
                  "party_type": null,
                  "min_length": 1,
                  "max_length": 18,
                  "code_list_key": null
                }
              }
            ]
          },
          {
            "fields": [
              {
                "id": "fld_cle5fx5l60ij72cut7v5zrlvw",
                "title": "Method Of Payment",
                "placeholder": "Method Of Payment",
                "description": null,
                "is_required": false,
                "format": {
                  "type": "code",
                  "party_type": null,
                  "min_length": 1,
                  "max_length": 1,
                  "code_list_key": "TaxPaymentMethod"
                }
              }
            ]
          }
        ],
        "sections": [],
        "repeatable": false,
        "collapsible": true,
        "allow_blank": false
      },
      {
        "id": "sctn_cle5fx68f0ij82cut1xxb10ox",
        "title": "Shipment",
        "rows": [],
        "sections": [
          {
            "id": "sctn_cle5fx6uq0ij92cutpabliqen",
            "title": "Documents, Certificates, Authorisations",
            "rows": [],
            "sections": [
              {
                "id": "sctn_cle5fx7js0ija2cutyi389xlj",
                "title": "Simplified declaration / Previous documents / Writing-off",
                "rows": [],
                "sections": [
                  {
                    "id": "sctn_cle5fx88z0ijb2cutazmsgww0",
                    "title": "Declaration",
                    "rows": [
                      {
                        "fields": [
                          {
                            "id": "fld_cle5fx8at0ijd2cut3zr070ng",
                            "title": "Previous Document",
                            "placeholder": "Previous Document",
                            "description": null,
                            "is_required": false,
                            "format": {
                              "type": "code",
                              "party_type": null,
                              "min_length": 1,
                              "max_length": 3,
                              "code_list_key": "PreviousDocumentType"
                            }
                          },
                          {
                            "id": "fld_cle5fx8bz0ije2cut5mumu5vz",
                            "title": "Previous Document Number",
                            "placeholder": "Previous Document Number",
                            "description": null,
                            "is_required": false,
                            "format": {
                              "type": "text",
                              "party_type": null,
                              "min_length": 1,
                              "max_length": 35,
                              "code_list_key": null
                            }
                          }
                        ]
                      },
                      {
                        "fields": [
                          {
                            "id": "fld_cle5fx8du0ijg2cut1phxdw30",
                            "title": "Declaration Line Number Previously Recorded",
                            "placeholder": "Declaration Line Number Previously Recorded",
                            "description": null,
                            "is_required": false,
                            "format": {
                              "type": "text",
                              "party_type": null,
                              "min_length": 1,
                              "max_length": 5,
                              "code_list_key": null
                            }
                          }
                        ]
                      }
                    ],
                    "sections": [],
                    "repeatable": true,
                    "collapsible": false,
                    "allow_blank": false
                  }
                ],
                "repeatable": false,
                "collapsible": false,
                "allow_blank": false
              },
              {
                "id": "sctn_cle5fx9240ijh2cutq0zykphc",
                "title": "Documents Produced, Certificates & Authorisations, Additional references",
                "rows": [
                  {
                    "fields": [
                      {
                        "id": "fld_cle5fx93o0ijj2cutoad4w8pq",
                        "title": "Reference Number / UCR",
                        "placeholder": "Reference Number / UCR",
                        "description": null,
                        "is_required": false,
                        "format": {
                          "type": "text",
                          "party_type": null,
                          "min_length": 1,
                          "max_length": 35,
                          "code_list_key": null
                        }
                      }
                    ]
                  },
                  {
                    "fields": [
                      {
                        "id": "fld_cle5fx95e0ijl2cuth797ffms",
                        "title": "Warehouse Type",
                        "placeholder": "Warehouse Type",
                        "description": null,
                        "is_required": false,
                        "format": {
                          "type": "code",
                          "party_type": null,
                          "min_length": 1,
                          "max_length": 1,
                          "code_list_key": "WarehouseType"
                        }
                      }
                    ]
                  },
                  {
                    "fields": [
                      {
                        "id": "fld_cle5fx9780ijn2cutk2qrbaqm",
                        "title": "Warehouse Identifier",
                        "placeholder": "Warehouse Identifier",
                        "description": null,
                        "is_required": false,
                        "format": {
                          "type": "text",
                          "party_type": null,
                          "min_length": 1,
                          "max_length": 35,
                          "code_list_key": null
                        }
                      }
                    ]
                  }
                ],
                "sections": [
                  {
                    "id": "sctn_cle5fx9w30ijo2cut4hmynblx",
                    "title": "Documents",
                    "rows": [
                      {
                        "fields": [
                          {
                            "id": "fld_cle5fx9xw0ijq2cutxx09tj8e",
                            "title": "Document Type",
                            "placeholder": "Document Type",
                            "description": null,
                            "is_required": false,
                            "format": {
                              "type": "code",
                              "party_type": null,
                              "min_length": 4,
                              "max_length": 4,
                              "code_list_key": "CommonDocumentsType"
                            }
                          }
                        ]
                      },
                      {
                        "fields": [
                          {
                            "id": "fld_cle5fx9zs0ijs2cutoxlddcja",
                            "title": "Document Identifier",
                            "placeholder": "Document Identifier",
                            "description": null,
                            "is_required": false,
                            "format": {
                              "type": "text",
                              "party_type": null,
                              "min_length": 1,
                              "max_length": 35,
                              "code_list_key": null
                            }
                          }
                        ]
                      }
                    ],
                    "sections": [],
                    "repeatable": true,
                    "collapsible": false,
                    "allow_blank": false
                  },
                  {
                    "id": "sctn_cle5fxaos0ijt2cutgk1ebiw9",
                    "title": "Additional Information",
                    "rows": [
                      {
                        "fields": [
                          {
                            "id": "fld_cle5fxaqi0ijv2cutg7pero1s",
                            "title": "Additional Information Code",
                            "placeholder": "Additional Information Code",
                            "description": null,
                            "is_required": false,
                            "format": {
                              "type": "code",
                              "party_type": null,
                              "min_length": 5,
                              "max_length": 5,
                              "code_list_key": "AdditionalInformationCode"
                            }
                          }
                        ]
                      },
                      {
                        "fields": [
                          {
                            "id": "fld_cle5fxask0ijx2cut8kqmk965",
                            "title": "Additional Information Text",
                            "placeholder": "Additional Information Text",
                            "description": null,
                            "is_required": false,
                            "format": {
                              "type": "text",
                              "party_type": null,
                              "min_length": 1,
                              "max_length": 512,
                              "code_list_key": null
                            }
                          }
                        ]
                      }
                    ],
                    "sections": [],
                    "repeatable": true,
                    "collapsible": false,
                    "allow_blank": false
                  }
                ],
                "repeatable": false,
                "collapsible": false,
                "allow_blank": false
              }
            ],
            "repeatable": false,
            "collapsible": true,
            "allow_blank": false
          },
          {
            "id": "sctn_cle5fxbhb0ijy2cutxsy9948j",
            "title": "Valuation Information",
            "rows": [
              {
                "fields": [
                  {
                    "id": "fld_cle5fxbj70ik02cut4vjchxoc",
                    "title": "Incoterm Code",
                    "placeholder": "Incoterm Code",
                    "description": null,
                    "is_required": true,
                    "format": {
                      "type": "code",
                      "party_type": null,
                      "min_length": 3,
                      "max_length": 3,
                      "code_list_key": "IncotermCode"
                    }
                  },
                  {
                    "id": "fld_cle5fxbkg0ik12cutks36zvcf",
                    "title": "UN / LOCODE",
                    "placeholder": "UN / LOCODE",
                    "description": null,
                    "is_required": false,
                    "format": {
                      "type": "text",
                      "party_type": null,
                      "min_length": 1,
                      "max_length": 17,
                      "code_list_key": null
                    }
                  }
                ]
              },
              {
                "fields": [
                  {
                    "id": "fld_cle5fxbmc0ik32cutyinz2bwa",
                    "title": "Country Code",
                    "placeholder": "Country Code",
                    "description": null,
                    "is_required": false,
                    "format": {
                      "type": "code",
                      "party_type": null,
                      "min_length": 2,
                      "max_length": 2,
                      "code_list_key": "CountryCodesFullList"
                    }
                  },
                  {
                    "id": "fld_cle5fxbne0ik42cutzmgnksfg",
                    "title": "Location",
                    "placeholder": "Location",
                    "description": null,
                    "is_required": false,
                    "format": {
                      "type": "text",
                      "party_type": null,
                      "min_length": 1,
                      "max_length": 35,
                      "code_list_key": null
                    }
                  }
                ]
              }
            ],
            "sections": [
              {
                "id": "sctn_cle5fxcbs0ik52cutbhqaq0xc",
                "title": "Additions & Deductions",
                "rows": [],
                "sections": [
                  {
                    "id": "sctn_cle5fxd3u0ik62cut4n1xslgl",
                    "title": "Additions & Deduction",
                    "rows": [
                      {
                        "fields": [
                          {
                            "id": "fld_cle5fxd5s0ik82cuts8h22yeg",
                            "title": "Additions & Deduction",
                            "placeholder": "Additions & Deduction",
                            "description": null,
                            "is_required": false,
                            "format": {
                              "type": "code",
                              "party_type": null,
                              "min_length": 2,
                              "max_length": 2,
                              "code_list_key": "AdditionsDeductionsCode"
                            }
                          },
                          {
                            "id": "fld_cle5fxd6y0ik92cut9vdhdy8g",
                            "title": "Amount",
                            "placeholder": "Amount",
                            "description": null,
                            "is_required": false,
                            "format": {
                              "type": "cents",
                              "party_type": null,
                              "min_length": 1,
                              "max_length": 18,
                              "code_list_key": null
                            }
                          }
                        ]
                      }
                    ],
                    "sections": [],
                    "repeatable": true,
                    "collapsible": false,
                    "allow_blank": false
                  }
                ],
                "repeatable": false,
                "collapsible": false,
                "allow_blank": false
              }
            ],
            "repeatable": false,
            "collapsible": true,
            "allow_blank": false
          },
          {
            "id": "sctn_cle5fxdwg0ika2cut96bsyj3d",
            "title": "Goods Information",
            "rows": [
              {
                "fields": [
                  {
                    "id": "fld_cle5fxdye0ikc2cutltduusev",
                    "title": "Gross Mass (kg)",
                    "placeholder": "Gross Mass (kg)",
                    "description": null,
                    "is_required": false,
                    "format": {
                      "type": "number",
                      "party_type": null,
                      "min_length": 1,
                      "max_length": 16,
                      "code_list_key": null
                    }
                  },
                  {
                    "id": "fld_cle5fxdzg0ikd2cutb0wrngvj",
                    "title": "Total Packages No",
                    "placeholder": "Total Packages No",
                    "description": null,
                    "is_required": false,
                    "format": {
                      "type": "number",
                      "party_type": null,
                      "min_length": 1,
                      "max_length": 16,
                      "code_list_key": null
                    }
                  }
                ]
              }
            ],
            "sections": [],
            "repeatable": false,
            "collapsible": true,
            "allow_blank": false
          },
          {
            "id": "sctn_cle5fxeoq0ike2cut5ofq6k0f",
            "title": "Dates & Places",
            "rows": [
              {
                "fields": [
                  {
                    "id": "fld_cle5fxeqg0ikg2cut3uvk6llr",
                    "title": "Destination Country Code",
                    "placeholder": "Destination Country Code",
                    "description": null,
                    "is_required": false,
                    "format": {
                      "type": "code",
                      "party_type": null,
                      "min_length": 2,
                      "max_length": 2,
                      "code_list_key": "CountryCodesEU"
                    }
                  },
                  {
                    "id": "fld_cle5fxerl0ikh2cutek5hogc8",
                    "title": "Dispatch Country Code",
                    "placeholder": "Dispatch Country Code",
                    "description": null,
                    "is_required": false,
                    "format": {
                      "type": "code",
                      "party_type": null,
                      "min_length": 2,
                      "max_length": 2,
                      "code_list_key": "CountryCodesFullList"
                    }
                  }
                ]
              }
            ],
            "sections": [
              {
                "id": "sctn_cle5fxfgm0iki2cutl1ju8e3z",
                "title": "Location Of Goods",
                "rows": [
                  {
                    "fields": [
                      {
                        "id": "fld_cle5fxfij0ikk2cutqd3ltdk5",
                        "title": "Location Of Goods ID",
                        "placeholder": "Location Of Goods ID",
                        "description": null,
                        "is_required": false,
                        "format": {
                          "type": "code",
                          "party_type": null,
                          "min_length": 1,
                          "max_length": 35,
                          "code_list_key": "LocationOfGoodsCode"
                        }
                      },
                      {
                        "id": "fld_cle5fxfjk0ikl2cut668imxmy",
                        "title": "Location Of Goods Additional ID",
                        "placeholder": "Location Of Goods Additional ID",
                        "description": null,
                        "is_required": false,
                        "format": {
                          "type": "text",
                          "party_type": null,
                          "min_length": 3,
                          "max_length": 3,
                          "code_list_key": null
                        }
                      }
                    ]
                  },
                  {
                    "fields": [
                      {
                        "id": "fld_cle5fxfll0ikn2cut13txp6g1",
                        "title": "Type Of Location Code",
                        "placeholder": "Type Of Location Code",
                        "description": null,
                        "is_required": false,
                        "format": {
                          "type": "code",
                          "party_type": null,
                          "min_length": 1,
                          "max_length": 1,
                          "code_list_key": "LocationType"
                        }
                      }
                    ]
                  }
                ],
                "sections": [],
                "repeatable": false,
                "collapsible": false,
                "allow_blank": false
              },
              {
                "id": "sctn_cle5fxgai0iko2cut27171y54",
                "title": "Address",
                "rows": [
                  {
                    "fields": [
                      {
                        "id": "fld_cle5fxgcf0ikq2cut91hta0ln",
                        "title": "Address",
                        "placeholder": "Address",
                        "description": null,
                        "is_required": false,
                        "format": {
                          "type": "location",
                          "party_type": null,
                          "min_length": null,
                          "max_length": null,
                          "code_list_key": null
                        }
                      }
                    ]
                  },
                  {
                    "fields": [
                      {
                        "id": "fld_cle5fxgeg0iks2cutnqt1te38",
                        "title": "Acceptance Date",
                        "placeholder": "Acceptance Date",
                        "description": null,
                        "is_required": false,
                        "format": {
                          "type": "date",
                          "party_type": null,
                          "min_length": null,
                          "max_length": null,
                          "code_list_key": null
                        }
                      }
                    ]
                  }
                ],
                "sections": [],
                "repeatable": false,
                "collapsible": false,
                "allow_blank": false
              }
            ],
            "repeatable": false,
            "collapsible": true,
            "allow_blank": false
          },
          {
            "id": "sctn_cle5fxh350ikt2cutl8y0im9i",
            "title": "Customs",
            "rows": [
              {
                "fields": [
                  {
                    "id": "fld_cle5fxh4x0ikv2cutcbe5jw1q",
                    "title": "Customs Office of Lodgement",
                    "placeholder": "Customs Office of Lodgement",
                    "description": null,
                    "is_required": true,
                    "format": {
                      "type": "code",
                      "party_type": null,
                      "min_length": 8,
                      "max_length": 8,
                      "code_list_key": "NationalCustomsOffice"
                    }
                  }
                ]
              },
              {
                "fields": [
                  {
                    "id": "fld_cle5fxh6t0ikx2cutjmef3opq",
                    "title": "Customs Office of Presentation",
                    "placeholder": "Customs Office of Presentation",
                    "description": null,
                    "is_required": false,
                    "format": {
                      "type": "text",
                      "party_type": null,
                      "min_length": 8,
                      "max_length": 8,
                      "code_list_key": null
                    }
                  }
                ]
              },
              {
                "fields": [
                  {
                    "id": "fld_cle5fxh8j0ikz2cuthqb189xm",
                    "title": "Supervising Customs Office",
                    "placeholder": "Supervising Customs Office",
                    "description": null,
                    "is_required": false,
                    "format": {
                      "type": "text",
                      "party_type": null,
                      "min_length": 8,
                      "max_length": 8,
                      "code_list_key": null
                    }
                  }
                ]
              }
            ],
            "sections": [],
            "repeatable": false,
            "collapsible": true,
            "allow_blank": false
          },
          {
            "id": "sctn_cle5fxhws0il02cuthio6w42p",
            "title": "Transport Information",
            "rows": [
              {
                "fields": [
                  {
                    "id": "fld_cle5fxhys0il22cutava6crhs",
                    "title": "Container",
                    "placeholder": "Container",
                    "description": null,
                    "is_required": false,
                    "format": {
                      "type": "bool",
                      "party_type": null,
                      "min_length": null,
                      "max_length": null,
                      "code_list_key": null
                    }
                  },
                  {
                    "id": "fld_cle5fxhzv0il32cuthl2dac8r",
                    "title": "Mode of Transport at Border",
                    "placeholder": "Mode of Transport at Border",
                    "description": null,
                    "is_required": false,
                    "format": {
                      "type": "code",
                      "party_type": null,
                      "min_length": 1,
                      "max_length": 1,
                      "code_list_key": "TransportMode"
                    }
                  }
                ]
              },
              {
                "fields": [
                  {
                    "id": "fld_cle5fxi1p0il52cut7493puyf",
                    "title": "Identity of Means of Transport at Arrival",
                    "placeholder": "Identity of Means of Transport at Arrival",
                    "description": null,
                    "is_required": false,
                    "format": {
                      "type": "code",
                      "party_type": null,
                      "min_length": 2,
                      "max_length": 2,
                      "code_list_key": "MeansIdentityType"
                    }
                  },
                  {
                    "id": "fld_cle5fxi2u0il62cutmeh2x8n6",
                    "title": "Identification Number",
                    "placeholder": "Identification Number",
                    "description": null,
                    "is_required": false,
                    "format": {
                      "type": "text",
                      "party_type": null,
                      "min_length": 1,
                      "max_length": 35,
                      "code_list_key": null
                    }
                  }
                ]
              },
              {
                "fields": [
                  {
                    "id": "fld_cle5fxi4q0il82cutspt8noh9",
                    "title": "Estimated Arrival Date / Time",
                    "placeholder": "Estimated Arrival Date / Time",
                    "description": null,
                    "is_required": false,
                    "format": {
                      "type": "text",
                      "party_type": null,
                      "min_length": 1,
                      "max_length": 7,
                      "code_list_key": null
                    }
                  }
                ]
              },
              {
                "fields": [
                  {
                    "id": "fld_cle5fxi6g0ila2cutoyxofpgi",
                    "title": "Active Means of Transport Crossing Border Nationality",
                    "placeholder": "Active Means of Transport Crossing Border Nationality",
                    "description": null,
                    "is_required": false,
                    "format": {
                      "type": "code",
                      "party_type": null,
                      "min_length": 2,
                      "max_length": 2,
                      "code_list_key": "CountryCodesFullList"
                    }
                  },
                  {
                    "id": "fld_cle5fxi7g0ilb2cutple0jjjr",
                    "title": "Inland mode of transport",
                    "placeholder": "Inland mode of transport",
                    "description": null,
                    "is_required": false,
                    "format": {
                      "type": "code",
                      "party_type": null,
                      "min_length": 1,
                      "max_length": 1,
                      "code_list_key": "TransportMode"
                    }
                  }
                ]
              },
              {
                "fields": [
                  {
                    "id": "fld_cle5fxi970ild2cutdyph1jep",
                    "title": "Container Identification Number",
                    "placeholder": "Container Identification Number",
                    "description": null,
                    "is_required": false,
                    "format": {
                      "type": "text",
                      "party_type": null,
                      "min_length": 1,
                      "max_length": 17,
                      "code_list_key": null
                    }
                  }
                ]
              }
            ],
            "sections": [],
            "repeatable": false,
            "collapsible": true,
            "allow_blank": false
          },
          {
            "id": "sctn_cle5fxizr0ile2cutnw19tkl0",
            "title": "Transaction Nature",
            "rows": [
              {
                "fields": [
                  {
                    "id": "fld_cle5fxj1v0ilg2cut483ar0gp",
                    "title": "Transaction Nature",
                    "placeholder": "Transaction Nature",
                    "description": null,
                    "is_required": false,
                    "format": {
                      "type": "code",
                      "party_type": null,
                      "min_length": 1,
                      "max_length": 2,
                      "code_list_key": "NatureOfTransaction"
                    }
                  }
                ]
              }
            ],
            "sections": [],
            "repeatable": false,
            "collapsible": true,
            "allow_blank": false
          }
        ],
        "repeatable": false,
        "collapsible": true,
        "allow_blank": false
      },
      {
        "id": "sctn_cle5fxjr80ilh2cut62m55yne",
        "title": "Product Information",
        "rows": [],
        "sections": [
          {
            "id": "sctn_cle5fxkh00ili2cutz815sizn",
            "title": "Product",
            "rows": [
              {
                "fields": [
                  {
                    "id": "fld_cle5fxkj80ilk2cut4i0nbh8w",
                    "title": "Choose Existing Product",
                    "placeholder": "Choose Existing Product",
                    "description": null,
                    "is_required": true,
                    "format": {
                      "type": "shipment_item",
                      "party_type": null,
                      "min_length": null,
                      "max_length": null,
                      "code_list_key": null
                    }
                  }
                ]
              }
            ],
            "sections": [],
            "repeatable": true,
            "collapsible": false,
            "allow_blank": false
          }
        ],
        "repeatable": false,
        "collapsible": true,
        "allow_blank": false
      }
    ]
  }

  static PRODUCT_TEMPLATE = {
    "additional_procedures": [
      {
        "code": "code_cletd0530000vh3ut3aozsuwd"
      }
    ],
    "additional_information": [
      {
        "text": "abc",
        "code": "code_cletd0522000ih3utd5hwubc0"
      }
    ],
    "taxes": [
      {
        "type": "code_cletd53tx0ifoh3utv2plzgmt",
        "unit": "12345",
        "rate": 0.23
      }
    ],
    "documents": [
      {
        "type": "code_cleuj3dtf00062qutonjmo9bq",
        "identifier": "12345"
      }
    ],
    "packages": [],
    "previous_document_identifier": "1234",
    "previous_document_line_id": "12",
    "previous_document_type": "code_cletd52fp0iayh3utwwk6pexp",
    "procedure": "code_cletd52u00ic8h3utdn0fvamg",
    "previous_procedure": "code_cletd52r50ibzh3utd2tqz5et",
    "net_mass": 12,
    "gross_mass": 14,
    "quantity": 1,
    "supplementary_units": null,
    "name": "name",
    "description": "description",
    "shipping_marks": null,
    "valuation": {
      "operations": [
        {
          "code": "code_cletd058u004gh3ut82e1xuo1",
          "amount": 23
        }
      ],
      "amount": 10,
      "statistical": 10,
      "indicator": "code_cletd548q0ih1h3ut274a5lfo",
      "method": "code_cletd54dh0ihih3utu0oc29f0",
      "preference": "code_cletd527a0ia6h3ut4qdqacqh",
      "currency": null
    },
    "commodity_code": {
      "taric": "code_cletd4b6t0fx6h3utqa6lw8he",
      "national_additional_codes": [
        {
          "code": "code_cletd538r0idoh3utdfo13q4q"
        }
      ],
      "taric_additional_codes": []
    }
  }
}