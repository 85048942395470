import React from "react"
import Card from "./components/structure/Card";
import DeclarationsTable from "./components/tables/SubmissionsTable";
import ProductsTable from "./components/tables/ProductsTable";
import PartiesTable from "./components/tables/PartiesTable";
import ClientsTable from "./components/tables/ClientsTable";
import GuaranteesTable from "./components/tables/GuaranteesTable";

export default class Authorisations extends React.Component {

  constructor(props) {
    super(props)

    this.state = {

    }
  }

  render() {
    let {

    } = this.state

    return (
      <>
        <ClientsTable
          title={"Clients"}
        />
      </>
    )
  }

}