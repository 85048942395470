import React from "react"
import StatCard from "./components/common/StatCard";
import DeclarationsTable from "./components/tables/SubmissionsTable";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import Card from "./components/structure/Card";

import Api from "./components/settings/Api";
import Search from "./components/settings/Search";
import Profile from "./components/settings/Profile";
import Company from "./components/settings/Company";
import Guarantees from "./components/settings/Guarantees";
import Security from "./components/settings/Security";

import AuthManager from "../utils/AuthManager";

export default class Settings extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      currentUser: AuthManager.currentUser
    }
  }

  render() {
    let {
      currentUser
    } = this.state

    return (
      <>
        <Card
          showHeader={false}
          className="card mb-5 mb-xl-10"
          bodyClassName="card-body pt-9 pb-0"
        >
          <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
            <div className="flex-grow-1">
              <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                <div className="d-flex flex-column">
                  <div className="d-flex align-items-center mb-2">
                    <a href="#" className="text-gray-900 text-hover-primary fs-2 fw-bolder me-1">{currentUser.company.name}</a>
                  </div>
                  <div className="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                    <a href="#" className="d-flex align-items-center text-gray-400 text-hover-primary mb-2">
                      <span className="svg-icon svg-icon-4 me-1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                             fill="none">
                          <path
                            opacity="0.3"
                            d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19Z"
                            fill="black"
                          />
                          <path
                            d="M21 5H2.99999C2.69999 5 2.49999 5.10005 2.29999 5.30005L11.2 13.3C11.7 13.7 12.4 13.7 12.8 13.3L21.7 5.30005C21.5 5.10005 21.3 5 21 5Z"
                            fill="black"
                          />
                        </svg>
                      </span>
                      {currentUser.user.email}
                    </a>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-wrap flex-stack">
                <div className="d-flex flex-column flex-grow-1 pe-8">
                  <div className="d-flex flex-wrap">
                    <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                      <div className="d-flex align-items-center">
                        <div className="fs-2 fw-bolder counted" data-kt-countup="true"
                             data-kt-countup-value="1200"
                        >
                          {currentUser.company.declarations_created_no}
                        </div>
                      </div>
                      <div className="fw-bold fs-6 text-gray-400">Declarations</div>
                    </div>
                    <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                      <div className="d-flex align-items-center">
                        <div className="fs-2 fw-bolder counted" data-kt-countup="true"
                             data-kt-countup-value="1200"
                        >
                          {currentUser.company.members_no}
                        </div>
                      </div>
                      <div className="fw-bold fs-6 text-gray-400">Users</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex overflow-auto h-55px">
            <ul
              className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">
              <li className="nav-item">
                <a className="nav-link active" data-bs-toggle="tab" href="#profile">Profile Settings</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-bs-toggle="tab" href="#company">Company Settings</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-bs-toggle="tab" href="#api">API Settings</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-bs-toggle="tab" href="#search">Search Settings</a>
              </li>
              {/*<li className="nav-item">*/}
              {/*  <a className="nav-link" data-bs-toggle="tab" href="#errorLogs">Security</a>*/}
              {/*</li>*/}
              {/*<li className="nav-item">*/}
              {/*  <a className="nav-link" data-bs-toggle="tab" href="#commodityCodes">Billing</a>*/}
              {/*</li>*/}
            </ul>
          </div>
        </Card>
        <Card
          className="card mb-5 mb-xl-10"
          showHeader={false}
          bodyClassName={""}
        >
          <div className="tab-content" id="analytics">
            <div className="tab-pane fade show active" id="profile" role="tabpanel">
              <Profile/>
            </div>
            <div className="tab-pane fade" id="company" role="tabpanel">
              <Company/>
            </div>
            <div className="tab-pane fade" id="api" role="tabpanel">
              <Api/>
            </div>
            <div className="tab-pane fade" id="search" role="tabpanel">
              <Search/>
            </div>
            {/*<div className="tab-pane fade" id="errorLogs" role="tabpanel">*/}
            {/*  <Security/>*/}
            {/*</div>*/}
            {/*<div className="tab-pane fade" id="commodityCodes" role="tabpanel">*/}
            {/*  <Card*/}
            {/*    title="Billing"*/}
            {/*  >*/}

            {/*  </Card>*/}
            {/*</div>*/}
          </div>
        </Card>
      </>
    )
  }

}
