import React from "react"

import moment from "moment/moment";
import Flatpickr from "react-flatpickr";

import General from "../../../utils/General";
import Email from "../../../utils/Email";

export default class DateTime extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            field: props.field,
            index: props.index,
            editable: props.editable,
            submission: props.submission
        }
    }

    componentWillReceiveProps(props) {
        this.setState(props)
    }

    getError(){
        let {
            field
        } = this.state


        if(field.is_required && field.answer?.value === undefined){
             return "Please select a date and time"
        }

        return null
    }

    render(){
        let {
            field,
            editable,
        } = this.state

        let value = field.answer?.value

        return (
          <div>
            <Flatpickr
                className="form-control"
                value={value}
                options={{
                    enableTime: true,
                    noCalendar: false,
                    time_24hr: true,
                    dateFormat: "Y-m-d H:i",
                    minuteIncrement: 1,
                }}
                disabled={!editable}
                onChange={dates => {
                    let fieldData = General.clone(field)
                    if(dates.length > 0){
                        let value = moment(dates[0]).format('YYYY-MM-DDTHH:mm:ss')
                        fieldData.answer = {
                            value,
                            id: field.answer?.id,
                        }
                    }else{
                        delete fieldData.answer
                    }
                    this.props.onUpdated(fieldData)
                }}
                onBlur={e => {
                    this.props.onBlur()
                }}
            />
          </div>
        )
    }
}
