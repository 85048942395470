import React from "react"

import { Link, withRouter } from "react-router-dom";

import DateRangePicker from '@wojtekmaj/react-daterange-picker';

import BaseTable from "./BaseTable"
import General from "../../../utils/General";
import Edit from "./cells/Edit";
import Delete from "./cells/Delete";
import Party from "../modal/Party";

class ShipmentItemsTable extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      submission: props.submission
    }

    this.table = React.createRef()
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  _getColumns() {

    let columns = [
      {
        Header: 'Name',
        id: 'name',
        accessor: shipmentItem => General.snakeCaseToTitleCase(shipmentItem.name),
      },
      {
        Header: 'Description',
        id: 'description',
        accessor: shipmentItem => General.snakeCaseToTitleCase(shipmentItem.description),
      },
      {
        Header: 'TARIC Code',
        id: 'commodity_code.taric.value',
        accessor: 'commodity_code.taric.value',
      },
      {
        Header: 'Total Liability',
        id: 'total_tax_amount',
        sortable: false,
        accessor: shipmentItem => {
          let payableAmount = 0
          shipmentItem.taxes.forEach(tax => {
            payableAmount += parseFloat(tax.payable_amount)
          })
          return payableAmount.toFixed(2)
        },
      }
    ]

    return columns

  }

  _renderTaxes(shipmentItem){
    let totalAmount = 0
    let payableAmount = 0
    return (
      <>
        <div className="table-responsive">

          <table
            className="table align-middle table-striped border table-row-dashed fs-6 gy-4"
            id="vatSummaryTable"
          >
            <thead>
              <tr className="text-start fw-bold fs-7 text-uppercase gs-0">
                <th className="ps-5">Tax Type (47.1)</th>
                <th className="text-end pe-5">Tax Base (47.2)</th>
                <th className="text-end pe-5">Assessed Amount (47.4)</th>
                <th className="text-end pe-5">Total Liability</th>
              </tr>
            </thead>


            <tbody className="fw-bold">
              {
                shipmentItem.taxes.map(tax => {
                  totalAmount += parseFloat(tax.amount)
                  payableAmount += parseFloat(tax.payable_amount)
                  return (
                    <>
                      <tr className="text-start fs-7 text-uppercase gs-0">
                        <td className="ps-5">{tax.type.name} - {tax.type.description}</td>
                        <td className="text-end pe-5">{tax.rate || 0}%</td>
                        <td className="text-end pe-5">{tax.amount || 0}</td>
                        <td className="text-end pe-5">{tax.payable_amount || 0}</td>
                      </tr>
                    </>
                  )
                })
              }
              <tr className="text-start fs-7 text-uppercase gs-0">
                <td className="text-end pe-5"/>
                <td className="text-end pe-5"/>
                <td className="text-end pe-5"><b>{totalAmount}</b></td>
                <td className="text-end pe-5"><b>{payableAmount.toFixed(2)}</b></td>
              </tr>
            </tbody>
          </table>
        </div>
      </>
    )
  }

  render() {
    let {
      showPartyModal
    } = this.state

    const columns = this._getColumns()

    return (
      <>
        <BaseTable
          ref={this.table}
          endpoint={`${window.Api.ShipmentItems}?submission_id=${this.props.submission.id}`}
          noDataMessage={"No shipment items found"}
          showFilter={false}
          showHeader={false}
          title={this.props.title}
          showPagination={false}
          columns={columns}
          defaultSorted={[
            {
              id: "created_at",
              desc: true
            }
          ]}
          renderToolbar={() => (
            <>

            </>
          )}
          SubComponent={(row) => this._renderTaxes(row.original)}
        />
      </>
    )

  }

}

export default withRouter(ShipmentItemsTable);
