import React from 'react'
import Tooltip from '@material-ui/core/Tooltip';

export default class DeclarationSummary extends React.Component {
  constructor(props){
    super(props)

    this.state = {

    }
  }

  render() {

    return (
      <Tooltip title={this.props.tooltip} placement="top" arrow>
        <a
          className="btn btn-icon btn-sm btn-secondary me-2"
          onClick={() => this.props.onDeclarationSummaryClicked()}
        >
          <span
            className="svg-icon svg-icon-1"
          >
            <svg
              width="16"
              height="19"
              viewBox="0 0 16 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path opacity="0.3" d="M15 8.40002H1C0.4 8.40002 0 8.00002 0 7.40002C0 6.80002 0.4 6.40002 1 6.40002H15C15.6 6.40002 16 6.80002 16 7.40002C16 8.00002 15.6 8.40002 15 8.40002ZM16 12.4C16 11.8 15.6 11.4 15 11.4H1C0.4 11.4 0 11.8 0 12.4C0 13 0.4 13.4 1 13.4H15C15.6 13.4 16 13 16 12.4ZM12 17.4C12 16.8 11.6 16.4 11 16.4H1C0.4 16.4 0 16.8 0 17.4C0 18 0.4 18.4 1 18.4H11C11.6 18.4 12 18 12 17.4Z" fill="currentColor"/>
              <path d="M12 0.400024H1C0.4 0.400024 0 0.800024 0 1.40002V2.40002C0 3.00002 0.4 3.40002 1 3.40002H12C12.6 3.40002 13 3.00002 13 2.40002V1.40002C13 0.800024 12.6 0.400024 12 0.400024Z" fill="currentColor"/>
            </svg>
          </span>
        </a>
      </Tooltip>
    )
  }
}

DeclarationSummary.defaultProps = {
  tooltip: "Declaration Summary",
}
