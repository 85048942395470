import General from "./General";

export default class Form {

  static getSubSectionsCreatable(section){
    return section.sections.length > 0 && section.sections[0].repeatable
  }

  static getSubSectionCreatableTemplate(section, group=0){
    if(section.sections.length === 0){
      return null
    }

    let subSection = General.clone(section.sections[0])

    subSection = Form.resetAnswers(subSection, group)

    delete subSection.id

    return subSection
  }

  static getSubSectionsTitle(section){
    return section.sections.length > 0 && section.sections[0].title
  }

  static resetAnswers(section, group){
    section.rows = section.rows.map(row => {
      row.fields = row.fields.map(field => {
        field.answer = null
        field.group = group
        return field
      })
      return row
    })

    return section
  }

  static getItemTemplate(){
    return {
        additional_procedures: [],
        additional_information: [],
        additional_fiscal_references: [],
        additional_references: [],
        taxes: [],
        documents: [],
        packages: [],
        valuation: {
          operations: []
        },
        commodity_code: {
          national_additional_codes: [],
          taric_additional_codes: [],
        },
        dates_and_places: {},
        transport_documents: [],
        quantity: 1,
        y_codes: [],
        dangerous_goods: [],
    }
  }

  static getAnswers(submission){
    let answers = []

    submission.form.sections.forEach(section => {
      answers = [
          ...answers,
          ...Form.getSectionAnswers(section)
      ]
    })

    return answers
  }

  static getSectionAnswers(section){
    let answers = []
    section.rows.forEach(row => {
      row.fields.forEach(field => {
        if(!field.answer || field.format.type === "label") {
          return
        }
        let answer = General.clone(field.answer)

        answers.push(answer)
      })
    })

    section.sections.forEach(subSection => {
      answers = [
          ...answers,
          ...Form.getSectionAnswers(subSection)
      ]
    })

    return answers
  }
}
