import React from "react"
import StatCard from "./components/common/StatCard";
import DeclarationsTable from "./components/tables/SubmissionsTable";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import Card from "./components/structure/Card";

export default class Analytics extends React.Component {

  constructor(props) {
    super(props)

    this.state = {

    }
  }

  render() {
    let {

    } = this.state

    return (
      <>
        <Card
          title="Analytics"
          className="card mb-5 mb-xl-10"
          bodyClassName="card-body pt-9 pb-0"
        >
          <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
            <div className="flex-grow-1">
              <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                <div className="d-flex flex-column">
                  <div className="d-flex align-items-center mb-2">
                    <a href="#" className="text-gray-900 text-hover-primary fs-2 fw-bolder me-1">Analytics</a>
                  </div>
                </div>
                <div className="d-flex align-items-center w-200px w-sm-300px flex-column mt-0">
                  <DateRangePicker
                    onChange={e => {console.log(e)}}
                    className={"form-control form-control-solid"}
                  />
                </div>
              </div>
              <div className="d-flex flex-wrap flex-stack">
                <div className="d-flex flex-column flex-grow-1 pe-8">
                  <div className="d-flex flex-wrap">
                    <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                      <div className="d-flex align-items-center">
                        <div className="fs-2 fw-bolder counted" data-kt-countup="true"
                             data-kt-countup-value="1200">1,200
                        </div>
                      </div>
                      <div className="fw-bold fs-6 text-gray-400">Total Declarations</div>
                    </div>
                    <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                      <div className="d-flex align-items-center">
                        <div className="fs-2 fw-bolder counted" data-kt-countup="true"
                             data-kt-countup-value="1200">1,200
                        </div>
                      </div>
                      <div className="fw-bold fs-6 text-success">Green</div>
                    </div>
                    <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                      <div className="d-flex align-items-center">
                        <div className="fs-2 fw-bolder counted" data-kt-countup="true"
                             data-kt-countup-value="1200">1,200
                        </div>
                      </div>
                      <div className="fw-bold fs-6 text-warning">Orange</div>
                    </div>
                    <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                      <div className="d-flex align-items-center">
                        <div className="fs-2 fw-bolder counted" data-kt-countup="true"
                             data-kt-countup-value="1200">1,200
                        </div>
                      </div>
                      <div className="fw-bold fs-6 text-danger">Red</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex overflow-auto h-55px">
            <ul
              className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">
              <li className="nav-item">
                <a className="nav-link active" data-bs-toggle="tab" href="#declarationAnalytics">Declarations</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-bs-toggle="tab" href="#financialAnalytics">Financials</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-bs-toggle="tab" href="#errorLogs">Error Logs</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-bs-toggle="tab" href="#userAnalytics">Users</a>
              </li>
            </ul>
          </div>
        </Card>
        <Card
          className="card mb-5 mb-xl-10"
          showHeader={false}
          bodyClassName={""}
        >
          <div className="tab-content" id="analytics">
            <div className="tab-pane fade show active" id="declarationAnalytics" role="tabpanel">
              <Card
                title="Declaration Analytics"
              >

              </Card>
            </div>
            <div className="tab-pane fade" id="financialAnalytics" role="tabpanel">
              <Card
                title="Financial Analytics"
              >

              </Card>
            </div>
            <div className="tab-pane fade" id="errorLogs" role="tabpanel">
              <Card
                title="Error Logs"
              >

              </Card>
            </div>
            <div className="tab-pane fade" id="userAnalytics" role="tabpanel">
              <Card
                title="User Analytics"
              >

              </Card>
            </div>
          </div>
        </Card>
      </>
    )
  }

}