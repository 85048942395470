import React from "react"

import ImageEdit from "../common/ImageEdit";
import AsyncSelect from "../common/AsyncSelect";
import Select from "../common/Select"

import Notify from "../../../utils/Notify";
import Backend from "../../../utils/Backend";
import General from "../../../utils/General";
import AuthManager from "../../../utils/AuthManager";

export const STATUS_FILTERS = [
  {
    label: 'Rejected',
    value: 'rejected',
  },
  {
    label: 'Draft',
    value: 'draft',
  },
  {
    label: 'Complete',
    value: 'complete',
  },
  {
    label: 'Processing',
    value: 'processing',
  },
  {
    label: 'Registered',
    value: 'registered',
  },
  {
    label: 'Accepted',
    value: 'accepted',
  },
  {
    label: 'Under Review',
    value: 'under_review',
  },
  {
    label: 'Insufficient Funds',
    value: 'insufficient_funds',
  },
  {
    label: 'Released',
    value: 'released',
  },
]

export default class Search extends React.Component {

  constructor(props) {
    super(props)

    let companyMember = AuthManager.currentUser
    this.state = {
      data: {
        user: {
          id: companyMember.user.id,
        },
        hidden_submission_statuses: companyMember.hidden_submission_statuses,
      }
    }
  }

  _save(){
    let {
      data
    } = this.state
    this.setState({ loading: true })

    Backend.updateCompanyMember(data)
    .then(() => {
      AuthManager.currentUser.hidden_submission_statuses = data.hidden_submission_statuses
      Notify.success('Your search settings have been changed successfully')
      this.setState({ loading: false })
    })
    .catch(error => {
      this.setState({ loading: false })
      Notify.error(error.message)
    })
  }

  _getOptions(contacts){
    return contacts.map(contact => ({
        value: contact.id,
        label: contact.name,
        data: contact
    }))
  }

  render() {
    let {
      loading,
      data,
    } = this.state

    return (
      <>
        <div className="card-header border-0">
          <div className="card-title m-0" bis_skin_checked="1">
            <h3 className="fw-bold m-0">Search Settings</h3>
          </div>
        </div>
        <div
          id="kt_account_profile_details_form"
          className="form fv-plugins-bootstrap5 fv-plugins-framework"
          noValidate="novalidate"
        >
          <div className="card-body border-top p-9" bis_skin_checked="1">

            <div className="form-group row mb-10">
              <label className="form-label ">Hidden Statuses</label>
              <Select
                isClearable={true}
                isMulti={true}
                value={data.hidden_submission_statuses.map(route => {
                  return {
                    label: General.toTitleCase(route),
                    value: route
                  }
                })}
                options={STATUS_FILTERS}
                placeholder="Statuses"
                getOptionLabel={route => route.label}
                getOptionValue={route => route.value}
                className={'custom-async-select__container'}
                classNamePrefix={'custom-async-select'}
                onSelected={result => {
                  data.hidden_submission_statuses = result?.map(option => option.value) || []
                  this.setState({ data })
                }}
              />
              <span className="text-danger">Hides declarations with the above statuses</span>
            </div>

          </div>
        </div>

        <div className="card-footer d-flex justify-content-end py-6 px-9" bis_skin_checked="1">
          <button
            type="submit"
            className="btn btn-primary"
            id="kt_account_profile_details_submit"
            onClick={() => this._save()}
            disabled={loading}
          >
            Save
          </button>
        </div>
        <input type="hidden"/>
        <div bis_skin_checked="1"></div>
      </>
    )
  }

}
