import React from "react"


export default class Security extends React.Component {

  constructor(props) {
    super(props)

    this.state = {

    }
  }

  render() {
    let {

    } = this.state

    return (
      <>
        <div className="row g-lg-9">
          <div className="col-lg-12">
            <div className="card card-xxl-stretch mb-5 mb-xl-10">
              <div className="card-header card-header-stretch">
                <div className="card-title">
                  <h3 className="m-0 text-gray-900">Security Summary</h3>
                </div>
                <div className="card-toolbar">
                  <ul className="nav nav-tabs nav-line-tabs nav-stretch border-transparent fs-5 fw-bolder"
                      id="kt_security_summary_tabs">
                    <li className="nav-item">
                      <a className="nav-link text-active-primary active" data-kt-countup-tabs="true"
                         data-bs-toggle="tab" href="#kt_security_summary_tab_pane_hours">12 Hours</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link text-active-primary" data-kt-countup-tabs="true" data-bs-toggle="tab"
                         id="kt_security_summary_tab_day" href="#kt_security_summary_tab_pane_day">Day</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link text-active-primary" data-kt-countup-tabs="true" data-bs-toggle="tab"
                         id="kt_security_summary_tab_week" href="#kt_security_summary_tab_pane_week">Week</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="card-body pt-7 pb-0 px-0">
                <div className="tab-content">
                  <div className="tab-pane fade active show" id="kt_security_summary_tab_pane_hours" role="tabpanel">
                    <div className="row p-0 mb-5 px-9">
                      <div className="col">
                        <div
                          className="border border-dashed border-gray-300 text-center min-w-125px rounded pt-4 pb-2 my-3">
                          <span className="fs-4 fw-bold text-success d-block">User Sign-in</span>
                          <span className="fs-2hx fw-bolder text-gray-900 counted" data-kt-countup="true"
                                data-kt-countup-value="36899">36,899</span>
                        </div>
                      </div>
                      <div className="col">
                        <div
                          className="border border-dashed border-gray-300 text-center min-w-125px rounded pt-4 pb-2 my-3">
                          <span className="fs-4 fw-bold text-primary d-block">Admin Sign-in</span>
                          <span className="fs-2hx fw-bolder text-gray-900 counted" data-kt-countup="true"
                                data-kt-countup-value="72">72</span>
                        </div>
                      </div>
                      <div className="col">
                        <div
                          className="border border-dashed border-gray-300 text-center min-w-125px rounded pt-4 pb-2 my-3">
                          <span className="fs-4 fw-bold text-danger d-block">Failed Attempts</span>
                          <span className="fs-2hx fw-bolder text-gray-900 counted" data-kt-countup="true"
                                data-kt-countup-value="291">291</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="kt_security_summary_tab_pane_day" role="tabpanel">
                    <div className="row p-0 mb-5 px-9">
                      <div className="col">
                        <div
                          className="border border-dashed border-gray-300 text-center min-w-125px rounded pt-4 pb-2 my-3">
                          <span className="fs-4 fw-bold text-success d-block">User Sign-in</span>
                          <span className="fs-2hx fw-bolder text-gray-800" data-kt-countup="true"
                                data-kt-countup-value="30467">0</span>
                        </div>
                      </div>
                      <div className="col">
                        <div
                          className="border border-dashed border-gray-300 text-center min-w-125px rounded pt-4 pb-2 my-3">
                          <span className="fs-4 fw-bold text-primary d-block">Admin Sign-in</span>
                          <span className="fs-2hx fw-bolder text-gray-800" data-kt-countup="true"
                                data-kt-countup-value="120">0</span>
                        </div>
                      </div>
                      <div className="col">
                        <div
                          className="border border-dashed border-gray-300 text-center min-w-125px rounded pt-4 pb-2 my-3">
                          <span className="fs-4 fw-bold text-danger d-block">Failed Attempts</span>
                          <span className="fs-2hx fw-bolder text-gray-800" data-kt-countup="true"
                                data-kt-countup-value="23">0</span>
                        </div>
                      </div>
                    </div>
                    <div className="pt-2">
                      <div className="d-flex align-items-center pb-9 px-9">
                        <h3 className="m-0 text-gray-800 flex-grow-1">Activity Chart</h3>
                        <ul className="nav nav-pills nav-line-pills border rounded p-1">
                          <li className="nav-item me-2">
                            <a
                              className="nav-link btn btn-active-light btn-active-color-gray-700 btn-color-gray-400 py-2 px-5 fs-6 fw-bold active"
                              data-bs-toggle="tab" id="kt_security_summary_tab_day_agents"
                              href="#kt_security_summary_tab_pane_day_agents">Agents</a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link btn btn-active-light btn-active-color-gray-700 btn-color-gray-400 py-2 px-5 fs-6 fw-bold"
                              data-bs-toggle="tab" id="kt_security_summary_tab_day_clients"
                              href="#kt_security_summary_tab_pane_day_clients">Clients</a>
                          </li>
                        </ul>
                      </div>
                      <div className="tab-content">
                        <div className="tab-pane fade active show" id="kt_security_summary_tab_pane_day_agents"
                             role="tabpanel">
                          <div id="kt_security_summary_chart_day_agents" style={{height: 300}}></div>
                        </div>
                        <div className="tab-pane fade" id="kt_security_summary_tab_pane_day_clients" role="tabpanel">
                          <div id="kt_security_summary_chart_day_clients" style={{height: 300}}></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="kt_security_summary_tab_pane_week" role="tabpanel">
                    <div className="row p-0 mb-5 px-9">
                      <div className="col">
                        <div
                          className="border border-dashed border-gray-300 text-center min-w-125px rounded pt-4 pb-2 my-3">
                          <span className="fs-lg-4 fs-6 fw-bold text-success d-block">User Sign-in</span>
                          <span className="fs-lg-2hx fs-2 fw-bolder text-gray-800" data-kt-countup="true"
                                data-kt-countup-value="340">0</span>
                        </div>
                      </div>
                      <div className="col">
                        <div
                          className="border border-dashed border-gray-300 text-center min-w-125px rounded pt-4 pb-2 my-3">
                          <span className="fs-lg-4 fs-6 fw-bold text-primary d-block">Admin Sign-in</span>
                          <span className="fs-lg-2hx fs-2 fw-bolder text-gray-800" data-kt-countup="true"
                                data-kt-countup-value="90">0</span>
                        </div>
                      </div>
                      <div className="col">
                        <div
                          className="border border-dashed border-gray-300 text-center min-w-125px rounded pt-4 pb-2 my-3">
                          <span className="fs-lg-4 fs-6 fw-bold text-danger d-block">Failed Attempts</span>
                          <span className="fs-lg-2hx fs-2 fw-bolder text-gray-800" data-kt-countup="true"
                                data-kt-countup-value="230">0</span>
                        </div>
                      </div>
                    </div>
                    <div className="pt-2">
                      <div className="d-flex align-items-center pb-9 px-9">
                        <h3 className="m-0 text-gray-800 flex-grow-1">Activity Chart</h3>
                        <ul className="nav nav-pills nav-line-pills border rounded p-1">
                          <li className="nav-item me-2">
                            <a
                              className="nav-link btn btn-active-light py-2 px-5 fs-6 btn-active-color-gray-700 btn-color-gray-400 fw-bold active"
                              data-bs-toggle="tab" id="kt_security_summary_tab_week_agents"
                              href="#kt_security_summary_tab_pane_week_agents">Agents</a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link btn btn-active-light py-2 px-5 btn-active-color-gray-700 btn-color-gray-400 fs-6 fw-bold"
                              data-bs-toggle="tab" id="kt_security_summary_tab_week_clients"
                              href="#kt_security_summary_tab_pane_week_clients">Clients</a>
                          </li>
                        </ul>
                      </div>
                      <div className="tab-content">
                        <div className="tab-pane fade active show" id="kt_security_summary_tab_pane_week_agents"
                             role="tabpanel">
                          <div id="kt_security_summary_chart_week_agents" style={{height: 300}}></div>
                        </div>
                        <div className="tab-pane fade" id="kt_security_summary_tab_pane_week_clients" role="tabpanel">
                          <div id="kt_security_summary_chart_week_clients" style={{height: 300}}></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card mb-5 mb-xl-10">
          <div className="card-header">
            <div className="card-title">
              <h3>Login Sessions</h3>
            </div>
            <div className="card-toolbar">
              <div className="my-1 me-4">
                <select className="form-select form-select-sm form-select-solid w-125px select2-hidden-accessible"
                        data-control="select2" data-placeholder="Select Hours" data-hide-search="true"
                        data-select2-id="select2-data-1-ru5w" tabIndex="-1" aria-hidden="true">
                  <option value="1" selected="selected" data-select2-id="select2-data-3-rv1v">1 Hours</option>
                  <option value="2">6 Hours</option>
                  <option value="3">12 Hours</option>
                  <option value="4">24 Hours</option>
                </select>
                <span
                  className="select2 select2-container select2-container--bootstrap5" dir="ltr"
                  data-select2-id="select2-data-2-55vz" style={{width: '100%'}}
                >
                  <span className="selection">
                    <span
                      className="select2-selection select2-selection--single form-select form-select-sm form-select-solid w-125px"
                      role="combobox" aria-haspopup="true" aria-expanded="false" tabIndex="0" aria-disabled="false"
                      aria-labelledby="select2-9rws-container" aria-controls="select2-9rws-container"
                    >
                      <span
                        className="select2-selection__rendered" id="select2-9rws-container" role="textbox"
                        aria-readonly="true" title="1 Hours"
                      >
                        1 Hours
                      </span>
                      <span className="select2-selection__arrow" role="presentation">
                        <b role="presentation"></b>
                      </span>
                    </span>
                  </span>
                  <span className="dropdown-wrapper" aria-hidden="true"></span>
                </span>
              </div>
              <a href="#" className="btn btn-sm btn-primary my-1">View All</a>
            </div>
          </div>
          <div className="card-body p-0">
            <div className="table-responsive">
              <table className="table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9">
                <thead className="border-gray-200 fs-5 fw-bold bg-lighten">
                <tr>
                  <th className="min-w-250px">Location</th>
                  <th className="min-w-100px">Status</th>
                  <th className="min-w-150px">Device</th>
                  <th className="min-w-150px">IP Address</th>
                  <th className="min-w-150px">Time</th>
                </tr>
                </thead>
                <tbody className="fw-6 fw-bold text-gray-600">
                <tr>
                  <td>
                    <a href="#" className="text-hover-primary text-gray-600">USA(5)</a>
                  </td>
                  <td>
                    <span className="badge badge-light-success fs-7 fw-bolder">OK</span>
                  </td>
                  <td>Chrome - Windows</td>
                  <td>236.125.56.78</td>
                  <td>2 mins ago</td>
                </tr>
                <tr>
                  <td>
                    <a href="#" className="text-hover-primary text-gray-600">United Kingdom(10)</a>
                  </td>
                  <td>
                    <span className="badge badge-light-success fs-7 fw-bolder">OK</span>
                  </td>
                  <td>Safari - Mac OS</td>
                  <td>236.125.56.78</td>
                  <td>10 mins ago</td>
                </tr>
                <tr>
                  <td>
                    <a href="#" className="text-hover-primary text-gray-600">Norway(-)</a>
                  </td>
                  <td>
                    <span className="badge badge-light-danger fs-7 fw-bolder">ERR</span>
                  </td>
                  <td>Firefox - Windows</td>
                  <td>236.125.56.10</td>
                  <td>20 mins ago</td>
                </tr>
                <tr>
                  <td>
                    <a href="#" className="text-hover-primary text-gray-600">Japan(112)</a>
                  </td>
                  <td>
                    <span className="badge badge-light-success fs-7 fw-bolder">OK</span>
                  </td>
                  <td>iOS - iPhone Pro</td>
                  <td>236.125.56.54</td>
                  <td>30 mins ago</td>
                </tr>
                <tr>
                  <td>
                    <a href="#" className="text-hover-primary text-gray-600">Italy(5)</a>
                  </td>
                  <td>
                    <span className="badge badge-light-success fs-7 fw-bolder">OK</span>
                  </td>
                  <td>Samsung Noted 5- Android</td>
                  <td>236.100.56.50</td>
                  <td>40 mins ago</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </>
    )
  }

}