import React from 'react'
import Modal from 'react-bootstrap/Modal'

import Notify from "../../../utils/Notify";

export default class AmendSubmission extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      show: props.show,
      reason: null,
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  onConfirm(){
    let { reason } = this.state
    if(!reason){
      Notify.error("You must specify a reason")
      return
    }
    this.props.onConfirm(reason)
  }

  render() {
    let {
      show,
      reason,
    } = this.state

    return (
      <Modal
        show={show}
        onHide={() => this.props.onHide()}
        centered
        className={'sub-modal'}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {this.props.title}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {this.props.message &&
            <span>{this.props.message}</span>
          }
          <div className="flex-column mt-5" data-kt-stepper-element="content">
            <div className="row">

              <div className="col-md-12">
                <div className="fv-row mb-3">
                  <div className="form-floating mb-2">
                    <textarea
                      type="text"
                      className="form-control"
                      placeholder="Reason"
                      id="reason"
                      value={reason}
                      onChange={e => {
                        reason = e.target.value || null
                        this.setState({ reason })
                      }}
                    />
                    <div className="position-absolute translate-middle-y top-50 end-0 me-3">
                      <i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title=""
                         data-bs-original-title="Description of Field" aria-label="Description of Field"></i>
                    </div>
                    <label htmlFor="reason">Reason</label>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>

          <div className='buttons-grid gg-7'>
            <button
                type="button"
                className="btn btn-light me-3"
                onClick={() => this.props.onHide()}
            >
              Close
            </button>

            <button
                type="button"
                className="btn btn-primary"
                disabled={this.props.loading}
                onClick={() => this.onConfirm()}
            >
              Amend
            </button>
          </div>

        </Modal.Footer>

      </Modal>
    )
  }
}

AmendSubmission.defaultProps = {
  title: "Are you sure?",
  message: "Please specify a reason. Note: This action cannot be undone"
}
