import React from "react";

import { withRouter } from "react-router-dom";

import Alert from "./Alert";

import AuthManager from "../../../utils/AuthManager";

class DevAlert extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {
    if (!window.Api.IsDev) {
      return null;
    }

    let text = "You are in the Test Environment. Declarations made here are not submitted to Revenue or HMRC.";
    let onClick = () => {};

    return <Alert text={text} closable={false} onClick={onClick} />;
  }
}

export default withRouter(DevAlert);
