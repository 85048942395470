import React from "react";

const STAT_VISUAL = require("../../../assets/media/svg/shapes/abstract-2.svg");

export default class StatCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      variation: props.variation,
      title: props.title,
      className: props.className,
      cardClassName: props.cardClassName,
      count: props.count,
      percentage: props.percentage,
      stats: props.stats,
      unreadCount: props.unreadCount,
    };
  }

  _renderBadgeNotifications = (count, type) => {
    return (
      count > 0 &&
      type !== "green" && (
        <div className={`notification-badge-${type} small mt-1`}>
          <span className="me-1 ms-2">
            <i className="fas fa-info-circle"></i>
          </span>
          <span>{count} need attention</span>
        </div>
      )
    );
  };

  _renderNumberCard() {
    const { overall } = this.state.stats;

    if (!overall) return null;

    const typeVariations = {
      "bg-light-success": "green",
      "bg-light-orange": "orange",
      "bg-light-yellow": "yellow",
      "bg-light-danger": "red",
    };

    const type = typeVariations[this.props.bodyClassName] || "";
    const unreadCount = this.state.unreadCount;

    return (
      <div className="col">
        <div
          className="card h-lg-100 mb-3"
          onClick={() => {
            this.props.onFilterByRoute(type);
          }}
          style={{ cursor: "pointer" }}
        >
          {this._renderBadgeNotifications(unreadCount, this.props.className)}
          <div
            className={`card-body d-flex justify-content-between align-items-start flex-column ${this.props.bodyClassName}`}
          >
            <div className="m-0">
              <span
                className={`svg-icon svg-icon-2hx svg-icon-${this.state.className}`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                  style={{ verticalAlign: "top" }}
                >
                  <circle fill="currentColor" cx="12" cy="12" r="8" />
                </svg>
              </span>
              <span
                className={`fw-bold fs-2x text-${this.state.className}`}
                style={{ lineHeight: 1.3 }}
              >
                {this.state.count}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  _renderDetailedCard() {
    return (
      <div className={`col-12 ${this.state.cardClassName}`}>
        <div
          className="card bgi-no-repeat card-xl-stretch mb-8"
          style={{
            backgroundPosition: "right top",
            backgroundSize: "30% auto",
            backgroundImage: `url(${STAT_VISUAL.default})`,
          }}
        >
          <div className="card-body">
            <a
              href="#"
              className="card-title fw-bold text-muted text-hover-primary fs-7"
            >
              {this.state.title}
            </a>
            <div className="fw-bold text-primary my-3 fs-2x lh-1 ls-n2">
              {this.state.count}
              {this.state.percentage && (
                <span className=" ps-3 text-dark fw-bold fs-7 m-0">
                  <span
                    className={`badge badge-light-${this.state.className} badge-sm fs-base`}
                  >
                    <span
                      className={`svg-icon svg-icon-5 svg-icon-${this.state.className} ms-n1`}
                    ></span>
                    {(this.state.percentage * 100).toFixed(2)}%
                  </span>
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  _renderBadgeCard() {
    const { stats, title } = this.state;

    return (
      <div className="col">
        <div className="card h-lg-100">
          <div className="d-flex justify-content-left m-auto">
            <div className="fw-bold text-muted text-hover-primary fs-7 m-auto">
              Invoices.
            </div>
            <div className="flex-column align-items-left m-auto mx-13">
              {stats.map((stat, index) => (
                <div key={index} className="">
                  <span className={`text-primary ${stat.labelClass}`}>
                    {stat.value}
                  </span>
                  <span className={`fw-bold ${stat.labelClass} fs-7 ms-2`}>
                    {stat.label}
                  </span>
                </div>
              ))}
            </div>
          </div>

          <span
            className="position-absolute top-0 translate-middle badge badge-primary fs-9"
            style={{ left: "96%" }}
          >
            {title}
          </span>
        </div>
      </div>
    );
  }

  render() {
    let { variation } = this.state;

    return (
      <>
        {variation === "detail"
          ? this._renderDetailedCard()
          : variation === "badge"
          ? this._renderBadgeCard()
          : this._renderNumberCard()}
      </>
    );
  }
}

StatCard.defaultProps = {
  bodyClassName: "",
};
