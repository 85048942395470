import React from "react";
import Modal from 'react-bootstrap/Modal'
import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";
import LocationInput from "../common/LocationInput";
import General from "../../../utils/General";
import AsyncSelect from "../common/AsyncSelect";
import ShipmentItemsTable from "../tables/ShipmentItemsTable";

export default class Submission extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      show: props.show,
      submission: props.submission
    }
  }

  componentDidMount() {
    Backend.getSubmissionShipmentItems(this.state.submission).then(shipmentItems => {
      this.setState({shipmentItems: shipmentItems.results})
    }).catch(e => Notify.error(e.message))
  }

  render() {
    let {
      show,
      submission,
      shipmentItems,
    } = this.state

    if(!shipmentItems) return null

    let paymentMethodDescription = "-"

    let shipmentItem = shipmentItems[0]
    if(shipmentItem.taxes.length > 0){
      let tax = shipmentItem.taxes[0]
      let paymentMethod = tax.payment_method
      if(paymentMethod) {
        paymentMethodDescription = `${paymentMethod.name} - ${paymentMethod.description}`
      }
    }

    return (
      <>
        <Modal
          show={show}
          onHide={() => this.props.onHide()}
          className={'sub-modal'}
          dialogClassName={"modal-70"}
        >
          <Modal.Header closeButton>
            <Modal.Title>Revenue Returned VAT/Duty Summary</Modal.Title>
          </Modal.Header>

          <Modal.Body>

            <ShipmentItemsTable
              submission={submission}
            />

            <div className="row">
              <div className="col-md-6">
                <div className="card bg-light">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6">
                        <p className="fw-bold">Total Liability :</p>
                        <p className="fw-bold">Method of Payment (47.5):</p>
                        <p className="fw-bold">LRN:</p>
                        <p className="fw-bold">MRN:</p>
                      </div>
                      <div className="col-md-6">
                        <p className="text-end fw-bold">{submission.total_tax_amount || 0}</p>
                        <p className="text-end fw-bold">{paymentMethodDescription}</p>
                        <p className="text-end fw-bold">{submission.lrn}</p>
                        <p className="text-end fw-bold">{submission.mrn}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </Modal.Body>

          <Modal.Footer>
            <button
              type="button"
              className="btn btn-light"
              data-bs-dismiss="modal"
              onClick={() => this.props.onHide()}
            >
              Close
            </button>
          </Modal.Footer>

        </Modal>
      </>
    )
  }

}