import React from "react";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

import Chips from "react-chips";

import ReactTable from "react-table";
import "react-table/react-table.css";
import moment from "moment";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";

import Card from "../structure/Card";

import Select from "../common/Select";
import DateRange from "../common/DateRange";
import Separator from "../common/Separator";
import AsyncSelect from "../common/AsyncSelect";

import Notify from "../../../utils/Notify";
import General from "../../../utils/General";
import FetchHelper from "../../../utils/FetchHelper";
import AuthManager from "../../../utils/AuthManager";

const OBJECTS_FILTERS = {
  name: {
    api: "objects",
    display: "Status",
  },
  values: [
    {
      label: "All",
      value: "all",
    },
    {
      label: "Active",
      value: "active",
    },
    {
      label: "Hidden",
      value: "deleted",
    },
  ],
};

export default class BaseTable extends React.Component {
  constructor(props) {
    super(props);

    let url = new URL(window.location.href)
    let params = new URLSearchParams(url.search)

    this.state = {
      loading: true,
      data: [],
      dateFrom: null,
      dateTo: null,
      pagesNo: 0,
      searchTerm: params.get("searchTerm") || "",
      csvData: [],
      filterValue: {},
      searchFilter: this.props.searchFilter,
      expandedRowIds: (params.get("expandedRowIds") || "").split(","),
      filters: this.props.filters
        ? [...this.props.filters, OBJECTS_FILTERS]
        : [OBJECTS_FILTERS],
    };

    this.reactTable = React.createRef();
  }

  refresh() {
    let current = this.reactTable.current;
    if (current) {
      current.state.page = 0;
      this.setState(
        {
          loading: false,
        },
        () => this._fetchData(current.state, current)
      );
    }
  }

  _handleSearch = General.debounce(() => {
    let current = this.reactTable.current;
    this.refresh();
  }, 1000);

  _handleFilterChange(event) {
    let { filterValue } = this.state;

    filterValue[event.target.name] = event.target.value;
    this.setState({ filterValue }, () => this._handleSearch());
  }

  _getUrl(endpoint, state) {
    let params = {
      ...this.props.params,
      page_size: state.pageSize,
      page: state.page + 1,
      pagination_type: "page",
      search_term: this.state.searchTerm,
    };

    let sorted = state.sorted[0];
    if (sorted) {
      let orderBy = sorted.id;
      orderBy = orderBy.replace(/\./g, "__");
      if (sorted.desc) {
        orderBy = `-${orderBy}`;
      }
      params["order_by"] = orderBy;
    }

    if (this.state.filterValue) {
      Object.entries(this.state.filterValue).map((filter) => {
        let key = filter[0];
        let value = filter[1];
        if (value.id) {
          value = value.id;
        } else if (value.user) {
          value = value.user.id;
        } else if (value.constructor === Array) {
          value = value.map((o) => o.id).join(",");
        }

        params[key] = value;
      });
    }

    if (this.props.dateRange) {
      params["min_date"] = this.state.dateFrom?.format("YYYY-MM-DD");
      params["max_date"] = this.state.dateTo?.format("YYYY-MM-DD");
    }

    return this._addParams(endpoint, params);
  }

  _fetchData(state, instance) {
    this.setState({ loading: true });
    let url = this._getUrl(this.props.endpoint, state);

    if (this.props.endpoint === "") {
      this.setState({
        data: this.props.mockData,
        pagesNo: 1,
        loading: false,
      });
      return;
    }
    FetchHelper.get(url, false)
      .then((response) => {
        this.setState({
          data: response.results,
          pagesNo: Math.ceil(response.count / state.pageSize),
          totalRecords: response.count,
          loading: false,
        });
      })
      .catch((error) => {});
  }

  _addParams(url, params) {
    if (Object.keys(params).length == 0) {
      return url;
    }

    // TODO: switch to an actual url helper here to avoid bugs/edge cases
    if (url.indexOf("?") == -1) {
      url += "?";
    } else if (!url.endsWith("&")) {
      url += "&";
    }

    Object.keys(params).forEach(function (key) {
      url += key + "=" + params[key] + "&";
    });

    // remove last '&'
    url = url.slice(0, -1);
    return url;
  }

  _exportPressed() {
    if (this.state.exporting) {
      return;
    }

    let exportableColumns = this.props.columns.filter(
      (column) => column.exportable != false
    );

    let prefetchRelated = [];
    let columns = exportableColumns.map((column) => {
      let data = {
        name: column.Header,
        accessor: column.id || column.accessor,
        type: column.type,
        format: column.format,
        timezone: column.timezone,
      };

      data.accessor.replace(/\./g, "__");
      if (data.accessor.indexOf("__") !== -1) {
        prefetchRelated.push(data.accessor);
      }
      return data;
    });
    let filename = `${this.props.exportFilename || this.props.title}.csv`;
    let sendExportAsEmail = this.props.sendExportAsEmail || false;
    let data = {
      filename,
      export: true,
      options: encodeURIComponent(
        JSON.stringify({
          columns,
          prefetch_related: prefetchRelated,
          select_related: [],
        })
      ),
      search_term: this.state.searchTerm,
      send_export_as_email: sendExportAsEmail,
    };

    if (this.state.searchFilter) {
      data[this.props.searchFilterName] = this.state.searchFilter;
    }

    if (this.props.defaultSorted && this.props.defaultSorted.length > 0) {
      data.order_by = this.props.defaultSorted[0].id.replace(/\./g, "__");
      if (this.props.defaultSorted[0].desc) {
        data.order_by = "-" + data.order_by;
      }
    }

    let endpoint = this._addParams(this.props.endpoint, data);
    this.setState({ exporting: true });
    this._handleExportBackend(endpoint, filename, sendExportAsEmail)
      .then(() => {
        this.setState({ exporting: false });
        if (sendExportAsEmail) {
          let email = "your email";
          if (AuthManager.currentUser) {
            email = AuthManager.currentUser.user.email;
          }
          Notify.success(`Your export will be sent to ${email} shortly!`);
        }
      })
      .catch((error) => {
        this.setState({ exporting: false });
        Notify.error(error.message);
      });
  }

  _handleExportBackend(endpoint, filename, sendExportAsEmail) {
    if (sendExportAsEmail) {
      return FetchHelper.get(endpoint);
    }
    return FetchHelper.download(endpoint, filename);
  }

  _renderHeaderLeftContent() {}

  _onExpandedChange(expanded, index, event) {
    let { data } = this.state;

    let expandedRowIds = [];
    Object.keys(expanded).forEach((key, i) => {
      if (expanded[key]) {
        console.log(key, i);
        expandedRowIds.push(data[key][this.props.expandedKey]);
      }
    });

    this.setState({ expandedRowIds });
  }

  _getExpanded() {
    let { data, expandedRowIds } = this.state;

    let expanded = {};
    expandedRowIds.forEach((id, i) => {
      let rowIndex = data.findIndex((row) => row[this.props.expandedKey] == id);
      if (rowIndex > -1) {
        expanded[rowIndex] = {};
      }
    });

    return expanded;
  }

  _getFilterOption(value) {
    if (!value || typeof value !== "object") return "";
    return {
      value: value.id,
      label: `${
        value.user ? `${value.user.first_name} ${value.user.last_name}` : ""
      } ${value.name ? value.name : ""} ${
        value.description ? `- ${value.description}` : ""
      }`,
      data: value,
    };
  }

  _getFilterOptions(values) {
    if (!values) {
      return [];
    }
    if (!values.map) {
      return values;
    }
    return values.map((value) => ({
      value: value.id,
      label: `${
        value.user ? `${value.user.first_name} ${value.user.last_name}` : ""
      } ${value.name ? value.name : ""} ${
        value.description ? `- ${value.description}` : ""
      }`,
      data: value,
    }));
  }

  _renderFilterDropdown() {
    let { filterValue, showDropdown, showFilterSubMenu } = this.state;

    if (this.props.showFiltersInSubMenu) {
      return (
        <a
          className="btn btn-secondary mx-2"
          onClick={() =>
            this.setState({ showFilterSubMenu: !showFilterSubMenu })
          }
        >
          <span className="svg-icon svg-icon-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                fill="black"
              />
            </svg>
          </span>
          {this.props.filterButtonName}
        </a>
      );
    }

    return (
      <Dropdown
        className="d-inline mx-2"
        autoClose="outside"
        show={showDropdown}
        onToggle={() => this.setState({ showDropdown: !showDropdown })}
      >
        <Dropdown.Toggle
          id="dropdown-autoclose-outside"
          variant={"secondary"}
          onClick={() => this.setState({ showDropdown: true })}
        >
          <span className="svg-icon svg-icon-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                fill="black"
              />
            </svg>
          </span>
          {this.props.filterButtonName}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {this.props.filters.map((filter) => {
            return (
              <>
                <div className="mb-10">
                  <label className="form-label fs-6 fw-bold">
                    {filter.name.display}:
                  </label>
                  {filter.endpoint ? (
                    <Dropdown.Item>
                      <AsyncSelect
                        isClearable={true}
                        placeholder={filter.name.display}
                        endpoint={filter.endpoint.url}
                        orderBy={filter.endpoint.orderBy || "name"}
                        value={this._getFilterOption(
                          filterValue[filter.name.api]
                        )}
                        filter={filter.endpoint.filters}
                        className={
                          "filter-solid custom-async-select__container"
                        }
                        classNamePrefix={"custom-async-select"}
                        getOptions={(options) =>
                          this._getFilterOptions(options)
                        }
                        onSelected={(option) => {
                          if (option) {
                            filterValue[filter.name.api] = option;
                          } else {
                            delete filterValue[filter.name.api];
                          }
                          this.setState({ filterValue });
                        }}
                      />
                    </Dropdown.Item>
                  ) : (
                    <Dropdown.Item>
                      <Select
                        isClearable={true}
                        value={this._getFilterOption(
                          filterValue[filter.name.api]
                        )}
                        options={filter.values}
                        placeholder={filter.name.label}
                        getOptionLabel={(role) => role.label}
                        getOptionValue={(role) => role.value}
                        className="form-control form-control-solid h-auto c-selectbox"
                        classNamePrefix="filter-select"
                        onSelected={(option) => {
                          if (option) {
                            filterValue[filter.name.api] = option.value;
                          } else {
                            delete filterValue[filter.name.api];
                          }
                          this.setState({ filterValue });
                        }}
                      />
                    </Dropdown.Item>
                  )}
                </div>
              </>
            );
          })}
          <div className="d-flex justify-content-end">
            <button
              type="submit"
              className="btn btn-primary fw-bold px-6"
              data-kt-menu-dismiss="true"
              data-kt-docs-table-filter="filter"
              onClick={() => {
                this._handleSearch();
                this.setState({ showDropdown: false });
              }}
            >
              Apply
            </button>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  _renderFilterSubMenu() {
    let { filterValue, showDropdown } = this.state;

    return (
      <div className="table-filters">
        <Separator text="Filters" />
        <div className="row">
          {this.props.filters.map((filter) => {
            let isMulti = filter.isMulti;
            let getValue = isMulti
              ? this._getFilterOptions
              : this._getFilterOption;
            let value = getValue(filterValue[filter.name.api]);
            return (
              <div className="col-3">
                <div className="mb-10">
                  {filter.endpoint ? (
                    <AsyncSelect
                      isMulti={isMulti}
                      isClearable={true}
                      placeholder={filter.name.display}
                      endpoint={filter.endpoint.url}
                      orderBy={filter.endpoint.orderBy || "name"}
                      value={value}
                      filter={filter.endpoint.filters}
                      className={"filter-solid custom-async-select__container"}
                      classNamePrefix={"custom-async-select"}
                      getOptions={(options) => this._getFilterOptions(options)}
                      onSelected={(result) => {
                        if (result) {
                          if (isMulti) {
                            let options = [];
                            if (result) {
                              options = result.map((option) => option.data);
                            }
                            filterValue[filter.name.api] = options;
                          } else {
                            filterValue[filter.name.api] = result;
                          }
                        } else {
                          delete filterValue[filter.name.api];
                        }
                        this.setState({ filterValue });
                        this._handleSearch();
                      }}
                    />
                  ) : (
                    <Select
                      isClearable={true}
                      isMulti={isMulti}
                      value={filter.name.api === "routes" ? filterValue[filter.name.api] : this._getFilterOption(
                        filterValue[filter.name.api]
                      )}
                      options={filter.values}
                      placeholder={filter.name.display}
                      getOptionLabel={(role) => role.label}
                      getOptionValue={(role) => role.value}
                      className={"filter-solid custom-async-select__container"}
                      classNamePrefix={"custom-async-select"}
                      onSelected={(result) => {
                        console.log("Base Table: Results Filters", result);
                        if (result) {
                          if (isMulti) {
                            filterValue[filter.name.api] = result
                              .map((option) => option.value)
                              .join(",");
                          } else {
                            filterValue[filter.name.api] = result.value;
                          }
                        } else {
                          delete filterValue[filter.name.api];
                        }
                        this.setState({ filterValue });
                        this._handleSearch();
                      }}
                    />
                  )}
                </div>
              </div>
            );
          })}
        </div>
        {this.props.renderFilterSubMenuFooter()}
      </div>
    );
  }

  _renderFilter() {
    let { searchTerm, filterValue, showDropdown, showFilterSubMenu } =
      this.state;

    if (!this.props.showFilter) return null;
    return (
      <div className="row card-header border-0 pt-5 justify-content-start">
        <div className="col-lg-4 my-1">
          <div className="w-100 position-relative chips-search-container">
            <span className="svg-icon svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 ms-5 translate-middle-y">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <rect
                  opacity="0.5"
                  x="17.0365"
                  y="15.1223"
                  width="8.15546"
                  height="2"
                  rx="1"
                  transform="rotate(45 17.0365 15.1223)"
                  fill="black"
                ></rect>
                <path
                  d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                  fill="black"
                ></path>
              </svg>
            </span>

            {this.props.chipsSearch && (
              <div className="scrollable-input">
                <Chips
                  name="search"
                  placeholder="Search... (comma, separated, ids, MRNs, UCRs)"
                  className="form-control form-control-solid px-15"
                  value={searchTerm ? searchTerm.split(",") : []}
                  onChange={(values) => {
                    const formattedValues = values
                      .flatMap((value) =>
                        value
                          .replace(/['"]/g, "")
                          .split(/<br\s*\/?>|\s+/)
                          .filter(Boolean)
                          .map((item) => item.trim())
                      )
                      .filter((item) => item !== "");

                    const searchTerm = formattedValues.join(",");

                    this.setState(
                      {
                        searchTerm,
                      },
                      () => {
                        this._handleSearch();
                      }
                    );
                  }}
                />
              </div>
            )}

            {!this.props.chipsSearch && (
              <input
                type="text"
                className="form-control form-control-solid px-15"
                name="search"
                onChange={(e) => {
                  this.setState(
                    {
                      searchTerm: e.target.value,
                    },
                    () => this._handleSearch()
                  );
                }}
                placeholder="Search..."
                data-kt-search-element="input"
              />
            )}
          </div>
        </div>

        {this.props.dateRange && (
          <div className="col-lg-4 my-1">
            <DateRange
              dateFrom={this.state.dateFrom}
              dateTo={this.state.dateTo}
              onFromDateUpdated={(dateFrom) => {
                this.setState(
                  {
                    dateFrom,
                  },
                  () => this._handleSearch()
                );
              }}
              onToDateUpdated={(dateTo) => {
                this.setState(
                  {
                    dateTo,
                  },
                  () => this._handleSearch()
                );
              }}
            />
          </div>
        )}

        <div className="col-lg my-1 basetable-filter text-end">
          <>
            {this.props.filters && this._renderFilterDropdown()}
            {this.props.exportButtonsEnabled && this._renderExportButtons()}
          </>
        </div>

        {showFilterSubMenu && (
          <div className="col-12">{this._renderFilterSubMenu()}</div>
        )}
      </div>
    );
  }

  _renderExportButtons() {
    let { exporting } = this.state;
    return (
      <a
        className="btn btn-secondary"
        disabled={exporting}
        onClick={() => this._exportPressed()}
      >
        Export to CSV
      </a>
    );
  }

  render() {
    const { data, pagesNo, loading, totalRecords, expanded } = this.state;

    let expandedProps = {};
    if (this.props.expandedKey) {
      expandedProps = {
        expanded: this._getExpanded(),
        onExpandedChange: (expanded, index, event) =>
          this._onExpandedChange(expanded, index, event),
      };
    }
    return (
      <>
        <Card
          title={this.props.title}
          subtitle={this.props.subtitle || `${totalRecords || 0} Total`}
          showHeader={this.props.showHeader}
          showToolbar={this.props.showToolbar}
          renderToolbar={this.props.renderToolbar}
          renderFilter={() => this._renderFilter()}
        >
          <div className="table-responsive">
            {this.props.renderTableInnerHeader &&
              this.props.renderTableInnerHeader()}

            <ReactTable
              ref={this.reactTable}
              manual
              {...expandedProps}
              data={data}
              pages={pagesNo}
              totalRecords={totalRecords}
              loading={loading}
              onFetchData={this._fetchData.bind(this)}
              columns={this.props.columns}
              getTdProps={this.props.getTdProps}
              getTrProps={this.props.getTrProps}
              getTheadProps={this.props.getTheadProps}
              getTheadThProps={this.props.getTheadThProps}
              showPagination={this.props.showPagination}
              showPaginationTop={this.props.showPaginationTop}
              showPaginationBottom={this.props.showPaginationBottom}
              defaultSorted={this.props.defaultSorted}
              SubComponent={this.props.SubComponent}
              collapseOnDataChange={this.props.collapseOnDataChange}
              minRows={this.props.minRows}
              defaultPageSize={this.props.pageSize}
              NoDataComponent={() => {
                return (
                  <div className="rt-noData">
                    <div className="text-center m-20 py-10">
                      <span className="fw-bolder fs-3 text-muted">
                        {this.props.noDataMessage}
                      </span>
                    </div>
                  </div>
                );
              }}
            />
          </div>
        </Card>
      </>
    );
  }
}

BaseTable.defaultProps = {
  params: {},
  label: "Search:",
  searchPlaceholder: "Search...",
  showToolbar: true,
  showHeader: true,
  showFilter: true,
  icon: null,
  minRows: 5,
  pageSize: 20,
  filters: null,
  chipsSearch: false,
  searchFilter: null,
  searchFilterName: "name",
  filterButtonName: "Filter",
  noDataMessage: "No documents found",
  showSearch: true,
  showPagination: true,
  showPaginationTop: false,
  showPaginationBottom: true,
  exportButtonsEnabled: false,
  collapseOnDataChange: true,
  getTdProps: (state, rowInfo, column, instance) => {
    return {
      style: {
        paddingLeft: 10,
      },
    };
  },
  getTrProps: (state, rowInfo, column, instance) => {
    return {
      style: {
        paddingTop: 10,
        paddingBottom: 10,
      },
    };
  },
  getTheadProps: (state, rowInfo, column, instance) => {
    return {
      style: {
        boxShadow: "0 1px 1px 0 rgba(0,0,0,0.1)",
        paddingTop: 10,
        paddingBottom: 10,
        textAlign: "left",
      },
    };
  },
  getTheadThProps: (state, rowInfo, column, instance) => {
    return {
      style: {
        textAlign: "left",
        paddingLeft: 10,
      },
    };
  },
  SubComponent: null,
  renderHeaderRightContent: () => null,
  renderToolbar: () => null,
  renderFilter: () => null,
  renderTableInnerHeader: () => null,
  renderFilterSubMenuFooter: () => null,
  expandedKey: null,
  showFiltersInSubMenu: false,
};

class Loading extends React.Component {
  render() {
    return this.props.loading ? (
      <div className="-loading -active">
        <div className="-loading-inner"></div>
      </div>
    ) : null;
  }
}
