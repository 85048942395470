import React from 'react'
import Tooltip from '@material-ui/core/Tooltip';

import TemplateForm from "../../modal/Template";

import Notify from "../../../../utils/Notify";
import Backend from "../../../../utils/Backend";
import Declarant from "../../../../utils/Declarant";
import AuthManager from "../../../../utils/AuthManager";

import Div from "../../common/Div"

export default class Template extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      submission: props.submission,
      currentUser: AuthManager.currentUser
    }
  }

  _createTemplate(name){
    let {
      submission
    } = this.state

    let data = {
      form: submission.form.id,
      template_id: submission.id,
      declarant: Declarant.getDeclarantId(submission.form),
      answers: [],
      template: true,
      name: name
    }

    this.setState({ isLoading: true })
    Backend.submitDeclaration(data)
    .then(submission => {
      if(!AuthManager.currentUser.company.onboarding_checklist.template){
        AuthManager.currentUser.company.onboarding_checklist.template = true
      }
      window.location = `/template-submissions`
    }).catch(e => {
      Notify.error(e.message)
      this.setState({ isLoading: false })
    })
  }

  render() {
    let {
      isLoading,
      showTemplateForm
    } = this.state

    return (
      <Div disabled={isLoading}>
        { this.props.type == "button" &&
            <button
              className="btn btn-sm btn-outline-primary btn-submission-action"
              onClick={() => this.setState({showTemplateForm: true})}
            >
              Create Template
            </button>
        }
        { this.props.type == "icon" &&
          <Tooltip title={this.props.tooltip} placement="top" arrow>
            <a
              className="btn btn-icon btn-sm btn-secondary me-2"
              onClick={() => this.setState({showTemplateForm: true})}
            >
              <span
                className="svg-icon svg-icon-1"
              >
                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <rect x="0" y="0" width="24" height="24"/>
                        <path d="M12,8 L8,8 C5.790861,8 4,9.790861 4,12 L4,13 C4,14.6568542 5.34314575,16 7,16 L7,18 C4.23857625,18 2,15.7614237 2,13 L2,12 C2,8.6862915 4.6862915,6 8,6 L12,6 L12,4.72799742 C12,4.62015048 12.0348702,4.51519416 12.0994077,4.42878885 C12.264656,4.2075478 12.5779675,4.16215674 12.7992086,4.32740507 L15.656242,6.46136716 C15.6951359,6.49041758 15.7295917,6.52497737 15.7585249,6.56395854 C15.9231063,6.78569617 15.876772,7.09886961 15.6550344,7.263451 L12.798001,9.3840407 C12.7118152,9.44801079 12.607332,9.48254921 12.5,9.48254921 C12.2238576,9.48254921 12,9.25869158 12,8.98254921 L12,8 Z" fill="#000000"/>
                        <path d="M12.0583175,16 L16,16 C18.209139,16 20,14.209139 20,12 L20,11 C20,9.34314575 18.6568542,8 17,8 L17,6 C19.7614237,6 22,8.23857625 22,11 L22,12 C22,15.3137085 19.3137085,18 16,18 L12.0583175,18 L12.0583175,18.9825492 C12.0583175,19.2586916 11.8344599,19.4825492 11.5583175,19.4825492 C11.4509855,19.4825492 11.3465023,19.4480108 11.2603165,19.3840407 L8.40328311,17.263451 C8.18154548,17.0988696 8.13521119,16.7856962 8.29979258,16.5639585 C8.32872576,16.5249774 8.36318164,16.4904176 8.40207551,16.4613672 L11.2591089,14.3274051 C11.48035,14.1621567 11.7936615,14.2075478 11.9589099,14.4287888 C12.0234473,14.5151942 12.0583175,14.6201505 12.0583175,14.7279974 L12.0583175,16 Z" fill="#000000" opacity="0.3"/>
                    </g>
                </svg>
              </span>
            </a>
          </Tooltip>
        }
        {
          showTemplateForm &&
          <TemplateForm
            show={showTemplateForm}
            onCreateTemplate={(template) => {
              this.setState({showTemplateForm: false})
              this._createTemplate(template.name)
            }}
            onHide={() => this.setState({showTemplateForm: false})}
          />
        }
      </Div>
    )
  }
}

Template.defaultProps = {
  type: "icon",
  tooltip: "Create Template",
}
