import React from "react";

import moment from "moment/moment";
import Flatpickr from "react-flatpickr";

import Modal from 'react-bootstrap/Modal'

import CodeInput from "../item/CodeInput";

import AsyncSelect from "../common/AsyncSelect";
import Separator from "../common/Separator";

import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";
import General from "../../../utils/General";

export default class Departure extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      show: props.show,
      submission: props.submission,
    }
  }


  _isFormValid(){
    let {
      country,
      location,
      departedAt,
      locationType,
      transportId,
      transportMode,
      transportNationality
    } = this.state

    let error = null

    if(!departedAt){
      error = "Please specify an arrival time"
    }
    else if(!country){
      error = "Please select a country"
    }
    else if(!locationType){
      error = "Please select a location type"
    }
    else if(!location){
      error = "Please select a location"
    }
    else if(!transportMode){
      error = "Please select a transport mode"
    }
    else if(!transportNationality){
      error = "Please select a transport nationalift"
    }

    if(error){
      Notify.error(error)
      return false
    }

    return true
  }

  _save(name){
    let {
      submission,
      country,
      location,
      departedAt,
      locationType,
      transportId,
      transportMode,
      transportNationality
    } = this.state

    if(!this._isFormValid()){
      return
    }

    let data = {
      country: country.id,
      location: location.id,
      departure_at: departedAt,
      submission: submission.id,
      location_type: locationType.id,
      transport_id: transportId,
      transport_mode: transportMode.id,
      transport_nationality: transportNationality.id
    }

    this.setState({ loading: true })
    Backend.departureNotification(data)
    .then(submission => {
      this.props.onDeparted()
      Notify.success("Departure Notification Sent!")
      this.setState({ loading: false })
    }).catch(e => {
      Notify.error(e.message)
      this.setState({ loading: false })
    })
  }

  render() {
    let {
      show,
      loading,
      template,
      country,
      location,
      departedAt,
      locationType,
      transportId,
      transportMode,
      transportNationality
    } = this.state

    return (
      <>
        <Modal
          show={show}
          onHide={() => this.props.onHide()}
          className={'sub-modal'}
          dialogClassName={"modal-50"}
        >
          <Modal.Header closeButton>
            <Modal.Title>Depart</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="flex-column" data-kt-stepper-element="content">
              <div className="row">

                <div className="col-md-12">
                  <div className="mb-5">
                    <label className={"mb-2"}>Departure Time</label>
                    <Flatpickr
                      className="form-control"
                      placeholder="Departure Time"
                      value={departedAt}
                      options={{
                        enableTime: true,
                        noCalendar: false,
                        dateFormat: "Y-m-d H:i K"
                      }}
                      onChange={dates => {
                        let departedAt = moment(dates[0]).format('YYYY-MM-DDThh:mm:ss')
                        this.setState({ departedAt })
                      }}
                    />
                  </div>
                </div>

                <Separator text="Location"/>

                <div className="col-md-4">
                  <CodeInput
                    placeholder={"Country Code"}
                    listKey={"CountryCodesFullList_CDS"}
                    value={country}
                    required={true}
                    editable={true}
                    onSelected={country => {
                        this.setState({ country })
                    }}
                  />
                </div>

                <div className="col-md-4">
                  <CodeInput
                    placeholder={"Location Type"}
                    listKey={"LocationType_CDS"}
                    value={locationType}
                    required={true}
                    editable={true}
                    onSelected={locationType => {
                        this.setState({ locationType })
                    }}
                  />
                </div>

                <div className="col-md-4">
                  <label className="mb-2">Location Identifier</label>
                  <div className="mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Identifier"
                      value={"U - UN/LOCODE"}
                      disabled
                      style={{ height: 48}}
                    />
                  </div>
                </div>

                <div className="col-md-12">
                  <CodeInput
                    placeholder={"Location"}
                    listKey={"LocationOfGoodsCode_CDS"}
                    value={location}
                    required={true}
                    editable={true}
                    onSelected={location => {
                        this.setState({ location })
                    }}
                  />
                </div>

                <Separator text="Transport"/>

                <div className="col-md-6">
                  <CodeInput
                    placeholder={"Transport Mode"}
                    listKey={"TransportMode_CDS"}
                    value={transportMode}
                    required={true}
                    editable={true}
                    onSelected={transportMode => {
                        this.setState({ transportMode })
                    }}
                  />
                </div>

                <div className="col-md-6">
                  <CodeInput
                    placeholder={"Transport Nationality"}
                    listKey={"CountryCodesFullList_CDS"}
                    value={transportNationality}
                    required={true}
                    editable={true}
                    onSelected={transportNationality => {
                        this.setState({ transportNationality })
                    }}
                  />
                </div>

                <div className="col-md-12">
                  <label>Transport ID</label>
                  <div className="mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Transport ID"
                      value={transportId}
                      onChange={e => {
                        this.setState({transportId: e.target.value || null})
                      }}
                    />
                  </div>
                </div>


              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex justify-content-end">
              <button
                className="btn btn-primary px-6"
                onClick={() => this._save()}
                disabled={loading}
              >
                Save
              </button>
            </div>
          </Modal.Footer>

        </Modal>
      </>
    )
  }
}

Departure.defaultProps = {

}
