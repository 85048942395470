import React from "react";
import Modal from "react-bootstrap/Modal";

import moment from "moment/moment";
import Flatpickr from "react-flatpickr";

import Select from "../common/Select";
import AsyncSelect from "../common/AsyncSelect";

import Notify from "../../../utils/Notify";
import General from "../../../utils/General";
import Backend from "../../../utils/Backend";

import { ROUTE_FILTERS, STATUS_FILTERS } from "../tables/SubmissionsTable";

const TYPES = [
  {
    label: "General Reporting",
    value: "submissions",
  },
  {
    label: "AI Invoice Verification Report",
    value: "invoice_verification",
  },
];

export default class Reports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: props.show,
      data: {},
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  _isFormValid() {
    let { data } = this.state;

    let error = null;
    if (!data.type) {
      error = "Please select a type";
    } else if (!data.form) {
      error = "Please select a form";
    } else if (data.type == "invoice_verification" && !data.clients) {
      error = "Please select a client";
    }

    if (error) {
      Notify.error(error);
      return false;
    }

    return true;
  }

  _constructPayload(data) {
    const common = {
      form: data.form,
      starts_at: data.starts_at,
      ends_at: data.ends_at,
      type: data.type,
    };

    if (data.type === "submissions") {
      return {
        ...common,
        routes: data.routes,
        submission_statuses: data.submission_statuses,
      };
    }

    return {
      ...common,
      client_ids: data.clients?.map((client) => client.id),
    };
  }

  _create() {
    let { data } = this.state;
    if (!this._isFormValid()) {
      return;
    }
    const payload = this._constructPayload(data);

    this.setState({ loading: true });

    Backend.createReport(payload)
      .then((report) => {
        this.setState({ loading: false });
        this.props.onCreated(report);
        Notify.success(
          "You will shortly receive an email with the report attached"
        );
      })
      .catch((error) => {
        this.setState({ loading: false });
        Notify.error(error.message);
      });
  }

  _getOptions(clients) {
    return clients?.map((client) => ({
      value: client.id,
      label: client.name,
      data: client,
    }));
  }

  _renderGeneral() {
    let { data } = this.state;

    return (
      <>
        <div className="form-group row mb-5">
          <label className="form-label">Routes</label>
          <Select
            isClearable={true}
            isMulti={true}
            value={data.routes?.map((route) => {
              return {
                label: General.toTitleCase(route),
                value: route,
              };
            })}
            options={ROUTE_FILTERS.values}
            placeholder="Routes"
            getOptionLabel={(route) => route.label}
            getOptionValue={(route) => route.value}
            className={"custom-async-select__container"}
            classNamePrefix={"custom-async-select"}
            onSelected={(result) => {
              data.routes = result?.map((option) => option.value) || [];
              this.setState({ data });
            }}
          />
        </div>

        <div className="form-group row mb-5">
          <label className="form-label">Statuses</label>
          <Select
            isClearable={true}
            isMulti={true}
            value={data.submission_statuses?.map((status) => {
              return {
                label: General.toTitleCase(status),
                value: status,
              };
            })}
            options={STATUS_FILTERS.values}
            placeholder="Statuses"
            getOptionLabel={(status) => status.label}
            getOptionValue={(status) => status.value}
            className={"custom-async-select__container"}
            classNamePrefix={"custom-async-select"}
            onSelected={(result) => {
              data.submission_statuses =
                result?.map((option) => option.value) || [];
              this.setState({ data });
            }}
          />
        </div>
      </>
    );
  }

  _renderVerification() {
    let { data } = this.state;

    return (
      <div className="form-group row mb-5">
        <label className="form-label ">Clients</label>
        <AsyncSelect
          placeholder={"Please select a Client"}
          endpoint={window.Api.Parties}
          orderBy={"name"}
          filter={`type=client`}
          isMulti={true}
          isClearable={true}
          creatable={true}
          className={"custom-async-select__container"}
          classNamePrefix={"custom-async-select"}
          value={this._getOptions(data?.clients)}
          getOptions={(options) => this._getOptions(options)}
          onSelected={(options) => {
            let clients = [];
            if (options) {
              clients = options?.map((option) => option.data) || [];
            }
            data.clients = clients;
            this.setState({ data });
          }}
        />
      </div>
    );
  }

  render() {
    let { show, data, loading } = this.state;

    return (
      <Modal
        show={show}
        onHide={() => this.props.onHide()}
        centered
        className={"sub-modal"}
      >
        <Modal.Header closeButton>
          <Modal.Title>{this.props.title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="form-group row mb-5">
            <label className="form-label">Type</label>
            <Select
              value={data.type}
              options={TYPES}
              placeholder="Select a type"
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
              className={"custom-async-select__container"}
              classNamePrefix={"custom-async-select"}
              onSelected={(option) => {
                data.type = option.value;
                this.setState({ data });
              }}
            />
          </div>

          <div className="form-group row mb-5">
            <label className="form-label">Form</label>
            <AsyncSelect
              placeholder={"Please select a form"}
              endpoint={window.Api.Forms}
              orderBy={"name"}
              isClearable={false}
              className={"custom-async-select__container"}
              classNamePrefix={"custom-async-select"}
              value={
                data.form
                  ? {
                      value: data.form.id,
                      label: `${
                        data.form.name
                      } (${data.form.type.toUpperCase()})`,
                      data: data.form,
                    }
                  : null
              }
              getOptions={(options) => {
                return options.map((option) => ({
                  value: option.id,
                  label: `${option.name} (${option.type.toUpperCase()})`,
                  data: option,
                }));
              }}
              onSelected={(form) => {
                data.form = form;
                this.setState({ data });
              }}
            />
          </div>

          {data.type == "submissions" && this._renderGeneral()}
          {data.type == "invoice_verification" && this._renderVerification()}

          <div className="row">
            <div className="col-md-6">
              <div className="mb-5">
                <label className={"mb-2"}>Min Date</label>
                <Flatpickr
                  className="form-control"
                  placeholder="Min Date"
                  value={data.starts_at}
                  options={{
                    enableTime: false,
                    noCalendar: false,
                    dateFormat: "Y-m-d",
                    static: true,
                  }}
                  onChange={(dates) => {
                    data.starts_at = moment(dates[0]).format("YYYY-MM-DD");
                    this.setState({ data });
                  }}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-5">
                <label className={"mb-2"}>Max Date</label>
                <Flatpickr
                  className="form-control"
                  placeholder="Max Date"
                  value={data.ends_at}
                  options={{
                    enableTime: false,
                    noCalendar: false,
                    dateFormat: "Y-m-d",
                    static: true,
                  }}
                  onChange={(dates) => {
                    data.ends_at = moment(dates[0]).format("YYYY-MM-DD");
                    this.setState({ data });
                  }}
                />
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="buttons-grid gg-7">
            <button
              type="button"
              className="btn btn-light me-3"
              onClick={() => this.props.onHide()}
            >
              Dismiss
            </button>

            <button
              type="button"
              className="btn btn-primary"
              onClick={() => this._create()}
              disabled={loading}
            >
              Create
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

Reports.defaultProps = {
  title: "Create Report",
  message: "This action cannot be undone",
};
