import React from "react"

const STAT_VISUAL = require('../../../assets/media/svg/shapes/abstract-2.svg')

export default class StatCard extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      variation: props.variation,
      title: props.title,
      className: props.className,
      cardClassName: props.cardClassName,
      count: props.count,
      percentage: props.percentage,
    }
  }

  _renderNumberCard(){
    return (
      <div className="col-12 col-md-4">
        <div className="card h-lg-100 mb-3">
          <div className="card-body d-flex justify-content-between align-items-start flex-column">
            <div className="m-0">
              <span className={`svg-icon svg-icon-2hx svg-icon-${this.state.className}`}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24"
                     version="1.1" style={{verticalAlign: 'top'}}>
                  <circle fill="currentColor" cx="12" cy="12" r="8"/>
                </svg>
              </span>
              <span className={`fw-bold fs-2x text-${this.state.className}`} style={{lineHeight:1.3}}>{this.state.count}</span>
            </div>
          </div>
        </div>
      </div>
    )
  }

  _renderDetailedCard(){
    return (
      <div className={`col-12 ${this.state.cardClassName}`}>
        <div
          className="card bgi-no-repeat card-xl-stretch mb-8"
          style={{backgroundPosition: 'right top', backgroundSize: '30% auto', backgroundImage: `url(${STAT_VISUAL.default})`}}
        >
          <div className="card-body">
            <a href="#" className="card-title fw-bold text-muted text-hover-primary fs-7">
              {this.state.title}
            </a>
            <div className="fw-bold text-primary my-3 fs-2x lh-1 ls-n2">
              {this.state.count}
              {
                this.state.percentage &&
                <span className=" ps-3 text-dark fw-bold fs-7 m-0">
                  <span className={`badge badge-light-${this.state.className} badge-sm fs-base`}>
                    <span className={`svg-icon svg-icon-5 svg-icon-${this.state.className} ms-n1`}>
                    </span>
                    {(this.state.percentage*100).toFixed(2)}%
                  </span>
                </span>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    let {
      variation
    } = this.state

    return (
      <>
        {
          variation === "detail" ?
            this._renderDetailedCard()
          :
            this._renderNumberCard()
        }
      </>
    )
  }

}