import React from "react"

import { Link, withRouter } from "react-router-dom";

import DateRangePicker from '@wojtekmaj/react-daterange-picker';

import BaseTable from "./BaseTable"
import Edit from "./cells/Edit";
import Delete from "./cells/Delete";
import CompanyMember from "../modal/CompanyMember";
import Confirmation from "../modal/Confirmation";
import Notify from "../../../utils/Notify";
import Backend from "../../../utils/Backend";
import AuthManager from "../../../utils/AuthManager";
import moment from "moment/moment";

class UsersTable extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      live: props.live
    }

    this.table = React.createRef()
  }

  _getColumns(){
    let columns = [
      {
        Header: "User",
        id: "user.first_name",
        accessor: company_member => (
          <>
            <div className="d-flex align-items-center" bis_skin_checked="1">
              <div className="d-flex flex-column justify-content-center" bis_skin_checked="1">
                <a href="" className="mb-1 text-gray-800 text-hover-primary">
                  {company_member.user.first_name} {company_member.user.last_name}
                </a>
                <div className="fw-semibold fs-6 text-gray-400" bis_skin_checked="1">
                  {company_member.user.email}
                </div>
              </div>
            </div>
          </>
        )
      },
      {
        Header: "Date Added",
        accessor: company_member => moment(company_member.created_at).format('DD MMM YYYY HH:mm'),
        id: "created_at",
        type: "date",
        format: "%d %b %Y"
      },
      {
        Header: "",
        id: '',
        sortable: false,
        exportable: false,
        Cell: rowInfo => {
          let companyMember = rowInfo.original
          return (
            <div className={"text-end my-auto"}>
              <Edit
                onEditClicked={() => this.setState({showUsersModal: true, selectedCompanyMember: companyMember})}
              />
              {/*
                AuthManager.currentUser.user.id !== companyMember.user.id &&
                <Delete
                  onDeleteClicked={() => this.setState({selectedCompanyMember: companyMember, showConfirmationModal: true})}
                />
              */}
            </div>
          )
        }
      },
    ]

    return columns
  }

  _handleDelete(){
    Backend.deleteCompanyMember(this.state.selectedCompanyMember).then(() => {
      Notify.success("Company Member Deleted")
      this.setState({selectedCompanyMember: null, showConfirmationModal: false})
      this.table.current.refresh()
    }).catch(e => Notify.error(e.message))
  }

  render() {
    let {
      showUsersModal,
      selectedCompanyMember,
      showConfirmationModal
    } = this.state

    const columns = this._getColumns()

    return (
      <>
        <BaseTable
          ref={this.table}
          endpoint={window.Api.CompanyMembers}
          noDataMessage={"No users found"}
          title={this.props.title}
          subtitle={this.props.subtitle}
          columns={columns}
          defaultSorted={[
            {
              id: "created_at",
              desc: true
            }
          ]}
          renderToolbar={() => (
            <>
              <div className="card-toolbar" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover"
                   data-kt-initialized="1" data-bs-original-title="" title="">
                <a
                  href="#"
                  className="btn btn-primary "
                  data-bs-toggle="modal"
                  data-bs-target="#kt_modal_invite_friends"
                  onClick={() => this.setState({showUsersModal: true})}
                >
                  <span className="svg-icon svg-icon-3">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                      <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1"
                            transform="rotate(-90 11.364 20.364)" fill="currentColor"/>
                      <rect x="4.36396" y="11.364" width="16" height="2" rx="1" fill="currentColor"/>
                    </svg>
                  </span>
                  Add User
                </a>
              </div>
            </>
          )}
        />
        {
          showUsersModal &&
          <CompanyMember
            show={showUsersModal}
            companyMember={selectedCompanyMember}
            onHide={() => this.setState({showUsersModal: false, selectedCompanyMember: null})}
            onSubmit={() => {
              this.table.current.refresh()
              this.setState({showUsersModal: false, selectedCompanyMember: null})
            }}
          />
        }
        {
          showConfirmationModal &&
          <Confirmation
            show={showConfirmationModal}
            onHide={() => this.setState({showConfirmationModal: false, selectedCompanyMember: null})}
            onConfirm={() => this._handleDelete()}
          />
        }
      </>
    )

  }

}

export default withRouter(UsersTable);
