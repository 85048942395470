import React from "react"

import Badge from "./Badge"

import AuthManager from "../../../utils/AuthManager"


export default class AIVerified extends React.Component {


  render() {
    if(!AuthManager.isInvoiceVerificationEnabled()){
      return null
    }

    return (
      <Badge
        label="AI Verified"
        tooltip="AI Verification enabled. Uploaded Invoices will be automatically checked against declaration details."
      />
    )
  }

}
