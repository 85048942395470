import React from "react"
import StatCard from "./components/common/StatCard";
import SubmissionsTable from "./components/tables/SubmissionsTable";
import AuthManager from "../utils/AuthManager";

export default class Submissions extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      currentUser: AuthManager.currentUser,
      imports: props.imports,
    }
  }

  componentDidMount() {

  }

  render() {
    let {
      imports
    } = this.state

    return (
      <>
        <div className="row">
          <div className="col-sm-12 col-xl-12">
            <SubmissionsTable
              title={"Declarations"}
              showDateRange={false}
              mode={"declaration"}
              imports={imports}
            />
          </div>
        </div>
      </>
    )
  }

}
