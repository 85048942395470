import React from 'react'

import { components } from "react-select"
import Creatable from "react-select/creatable";
import AsyncPaginate from 'react-select-async-paginate';

import FetchHelper from '../../../utils/FetchHelper'
import Notify from '../../../utils/Notify'

export default class AsyncSelect extends React.Component  {
  constructor(props){
    super(props)

    this.state = {
      ...this._getState(props),
      search: '',
      prevSearch: '',
      items: [],
      nextPageUrl: null,
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(this._getState(nextProps))
  }

  _getState(props){
    return {
      ...props,
      placeholder: props.placeholder,
      endpoint: props.endpoint,
      isMulti: props.isMulti ? props.isMulti : false,
      creatable: props.creatable ? props.creatable : false,
      styles: props.styles,
      filter: props.filter ? props.filter : '',
      value: props.value,
      disabled: props.disabled
    }
  }

  _loadOptions(search, prevOptions){
    let {
      endpoint,
      prevSearch,
      nextPageUrl,
      filter,
      items
    } = this.state

    let url = `${endpoint}?${filter ? `${filter}&` : ''}search_term=${search}&order_by=${this.props.orderBy}`
    let nextPage = false
    if(search === prevSearch && nextPageUrl){
      url = nextPageUrl
      nextPage = true
    }

    return FetchHelper.get(url)
      .then(response => {
        let newOptions = this.props.getOptions(response.results)
        if(nextPage){
          items = [
            ...items,
            ...response.results
          ]
        }
        else{
          items = response.results
        }

        this.setState({
          items,
          nextPageUrl: response.next,
          prevSearch: search
        })
        return {
          options: newOptions,
          hasMore: response.next != null
        }
      })
  };

  _handleChange(option){
    if(this.state.isMulti){
      this.setState({ value: option }, () =>{
        this.props.onSelected(this.state.value)
      })
    }
    else if(!option){
      this.setState({ value: option }, () => {
        this.props.onSelected(option)
      })
    }
    else if(option.__isNew__){
      this.setState({ value: option }, () => {
        this.props.onCreated(option)
      })
    } else{
      this.setState({ value: option }, () => {
        this.props.onSelected(option.data)
      })
    }
  }

  render(){
    let props = {...this.props}

    if(this.state.creatable){
      props.SelectComponent = Creatable
    }

    if(props.allowCopy){
      props.components = { Control }
    }

    return (
      <AsyncPaginate
        { ...props }
        isMulti={this.props.isMulti}
        value={this.state.value || ''}
        styles={this.state.styles}
        closeMenuOnSelect={!this.state.isMulti}
        loadOptions={this._loadOptions.bind(this)}
        debounceTimeout={300}
        onChange={value => {
          this._handleChange(value)
        }}
        onBlur={() => this.props.onBlur()}
        isDisabled={this.state.disabled}
      />
    )
  }
}

AsyncSelect.defaultProps = {
  isMulti: false,
  orderBy: "name",
  onBlur: () => null
}

const Control = ({ children, ...props }) => (
  <components.Control {...props}>
    { children }
    <Copy options={props.getValue && props.getValue()}/>
  </components.Control>
)

const Copy = ({ options }) => {
  if(!options || options.length == 0){
    return null
  }
  let option = options[0]
  let value = option?.data.value
  if (!value){
    return null
  }
  return (
    <>
      <span class="custom-async-select__indicator-separator css-1okebmr-indicatorSeparator"></span>
      <a
        className="btn btn-icon btn-sm bg-transparent me-2"
        onClick={(e) => {
          // close menu
          document.activeElement?.blur && document.activeElement.blur()

          navigator.clipboard.writeText(value);
          Notify.success(`${value} - Copied!`)
        }}
      >
        <span
          className="svg-icon svg-icon-1"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{
              stroke: "hsl(0,0%,80%)",
              strokeWidth: 2
            }}
          >
            <rect opacity="0.5" x="7" y="2" width="14" height="16" rx="3"/>
            <rect x="3" y="6" width="14" height="16" rx="3" fill="white" style={{ fill: "white"}}/>
          </svg>
        </span>
      </a>
    </>
  )
}
