import React from "react"

import { Link, withRouter } from "react-router-dom";

import Reports from "../modal/Reports";

import Download from "./cells/Download";

import BaseTable from "./BaseTable"
import DeclarationsTable from "./SubmissionsTable"

import Notify from "../../../utils/Notify";
import General from "../../../utils/General";
import Backend from "../../../utils/Backend";
import AuthManager from "../../../utils/AuthManager";

import moment from "moment/moment";

class PreBoardingNotificationsTable extends React.Component {

  constructor(props) {
    super(props)

    this.state = {

    }

    this.table = React.createRef()
  }


  _getColumns(){
    let columns = [
      {
        Header: "Date Created",
        accessor: pbn => moment(pbn.created_at).format('DD MMM YYYY HH:mm'),
        id: "created_at",
        type: "date",
        format: "%d %b %Y"
      },
      {
        Header: "ID",
        accessor: "external_id",
      },
      {
        Header: "Declarations No.",
        accessor: "submissions_no",
      },
      {
        Header: "Status",
        accessor: "status",
      },
    ]

    return columns
  }


  render() {
    let {
    } = this.state

    const columns = this._getColumns()

    return (
      <>
        <BaseTable
          ref={this.table}
          endpoint={window.Api.PreBoardingNotifications}
          noDataMessage={"No Pre Boarding Notifications found"}
          title={this.props.title}
          subtitle={this.props.subtitle}
          showFilter={false}
          columns={columns}
          defaultSorted={[
            {
              id: "created_at",
              desc: true
            }
          ]}
          SubComponent={(row) => {
            return (
              <DeclarationsTable
                title={"Declarations"}
                showDateRange={false}
                preBoardingNotification={row.original}
                mode={"declaration"}
              />
            )
          }}
        />
      </>
    )

  }

}

export default withRouter(PreBoardingNotificationsTable);
