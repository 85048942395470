import React from "react";

import CodeInput from "./CodeInput"

import Codes from "../common/Codes"
import Input from "./Input";

export default class Valuation extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            editable: props.editable,
            product: props.product,
            valuation: props.valuation
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState(nextProps)
    }



    render() {
        let {
            editable,
            product,
            valuation,
        } = this.state

        return (
            <>
                <div className="row">
                    <div className={`col ${this.props.fieldVisibilityClassName("Item Price (4/14)")}`}>
                        <div className="fv-row mb-3">
                            <Input
                                type="number"
                                value={valuation.amount}
                                placeholder="Item Price (4/14)"
                                onChange={amount => {
                                    valuation.amount = amount
                                    this.props.onUpdated(valuation)
                                }}
                                editable={editable && this.props.isEditableField("Item Price (4/14)")}
                                renderToolbar={() => this.props.renderToolbar("Item Price (4/14)", "Item Price (4/14)")}
                            />
                        </div>
                    </div>


                    <div className={`col ${this.props.fieldVisibilityClassName("Statistical Value (8/6)")}`}>
                        <div className="fv-row mb-3">
                            <Input
                                type="number"
                                value={valuation.statistical}
                                placeholder="Statistical Value (8/6)"
                                editable={editable && this.props.isEditableField("Statistical Value (8/6)")}
                                renderToolbar={() => this.props.renderToolbar("Statistical Value (8/6)", "Statistical Value (8/6)")}
                            />
                        </div>
                    </div>

                    <div className={`col-1 ${this.props.fieldVisibilityClassName("quanity")}`}>
                        <div className="fv-row mb-3">
                            <Input
                                type="number"
                                value={product.quantity}
                                placeholder="Item Quantity"
                                onChange={quantity => {
                                    product.quantity = quantity
                                    this.props.onQuantityUpdated(quantity)
                                }}
                                editable={editable && this.props.isEditableField("quantity")}
                                renderToolbar={() => this.props.renderToolbar("quantity", "Item Quantity")}
                            />
                        </div>
                    </div>

                    <div className={`col ${this.props.fieldVisibilityClassName("Preference (4/17)")}`}>
                        <div className="fv-row mb-3">
                            <CodeInput
                                editable={editable && this.props.isEditableField("Preference (4/17)")}
                                placeholder={"Preference (4/17)"}
                                listKey={"Preference"}
                                value={valuation.preference}
                                onSelected={option => {
                                    valuation.preference = option
                                    this.props.onUpdated(valuation)
                                }}
                                renderToolbar={() => this.props.renderToolbar("Preference (4/17)", "Preference (4/17)")}
                            />
                        </div>
                    </div>

                    <div className={`col ${this.props.fieldVisibilityClassName("Indicator (4/13)")}`}>
                        <div className="fv-row mb-3">
                            <CodeInput
                                editable={editable && this.props.isEditableField("Indicator (4/13)")}
                                placeholder={"Indicator (4/13)"}
                                listKey={"ValuationIndicators"}
                                value={valuation.indicator}
                                onSelected={option => {
                                    valuation.indicator = option
                                    this.props.onUpdated(valuation)
                                }}
                                renderToolbar={() => this.props.renderToolbar("Indicator (4/13)", "Indicator (4/13)")}
                            />
                        </div>
                    </div>
                    <div className={`col ${this.props.fieldVisibilityClassName("Valuation Method (4/16)")}`}>
                        <div className="fv-row mb-3">
                            <CodeInput
                                editable={editable && this.props.isEditableField("Valuation Method (4/16)")}
                                placeholder={"Valuation Method (4/16)"}
                                listKey={"ValuationMethod"}
                                value={valuation.method}
                                onSelected={option => {
                                    valuation.method = option
                                    this.props.onUpdated(valuation)
                                }}
                                renderToolbar={() => this.props.renderToolbar("Valuation Method (4/16)", "Valuation Method (4/16)")}
                            />
                        </div>
                    </div>

                    <div className={`col ${this.props.fieldVisibilityClassName("TransactionNature (8/5)")}`}>
                        <div className="fv-row mb-3">
                            <CodeInput
                                editable={editable && this.props.isEditableField("TransactionNature (8/5)")}
                                placeholder={"TransactionNature (8/5)"}
                                listKey={"NatureOfTransaction"}
                                value={product.transaction_nature}
                                onSelected={option => {
                                    product.transaction_nature = option
                                    this.props.onTransactionNatureUpdated(option)
                                }}
                                renderToolbar={() => this.props.renderToolbar("TransactionNature (8/5)", "TransactionNature (8/5)")}
                            />
                        </div>
                    </div>
                </div>
            </>
        )
    }


}

Valuation.defaultProps = {

}
