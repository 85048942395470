import React from "react"
import Card from "./components/structure/Card";
import DeclarationsTable from "./components/tables/SubmissionsTable";
import ProductsTable from "./components/tables/ProductsTable";
import PartiesTable from "./components/tables/PartiesTable";
import AuthorizationsTable from "./components/tables/AuthorisationsTable";
import LocationInput from "./components/common/LocationInput";
import Backend from "../utils/Backend";
import Notify from "../utils/Notify";

export default class Party extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      party: props.party || {indirect_representative: true}
    }
  }

  componentDidMount() {
    let partyID =  this.props.match.params.partyId
    Backend.getParty(partyID).then(party => {
      this.setState({party})
    }).catch(e => Notify.error(e.message()))
  }

  _handleSubmission(){
    let {
      party
    } = this.state

    if(party.id){
      this._updateParty(party)
    }else{
      this._addParty(party)
    }
  }

  _addParty(party){
    this.setState({loading: true})
    Backend.addParty(party).then(party => {
      this.setState({loading: false})
      Notify.success("Party Added")
      window.location = '/parties'
    }).catch(e => {
      this.setState({loading: false})
      Notify.error(e.message)
    })
  }

  _updateParty(party){
    this.setState({loading: true})
    Backend.updateParty(party).then(party => {
      this.setState({loading: false})
      Notify.success("Party Updated")
      window.location = '/parties'
    }).catch(e => {
      this.setState({loading: false})
      Notify.error(e.message)
    })
  }

  render() {
    let {
      party
    } = this.state

    let title = party.id ? "Update Party" : "Add Party"

    return (
      <>
        <div className="row">
          <div className="col-sm-12 col-xl-12">
            <Card
              title={title}
            >
              <div className="flex-column" data-kt-stepper-element="content">
                <div className="row">
                  <div className="col-md-6">
                    <div className="fv-row mb-3">
                      <div className="form-floating mb-2">
                        <select
                          className="form-select"
                          id="partyType"
                          aria-label="Party Type"
                          onChange={e => {
                            party.type = e.target.value
                            this.setState({party})
                          }}
                          disabled={party.id}
                        >
                          <option>Party Type</option>
                          <option value="representative" selected={party.type === "representative"}>Representative</option>
                          <option value="declarant" selected={party.type === "declarant"}>Declarant</option>
                          <option value="exporter" selected={party.type === "exporter"}>Exporter</option>
                          <option value="importer" selected={party.type === "importer"}>Importer</option>
                          <option value="buyer" selected={party.type === "buyer"}>Buyer</option>
                          <option value="seller" selected={party.type === "seller"}>Seller</option>
                          <option value="owner" selected={party.type === "owner"}>Owner</option>
                        </select>
                        <div className="position-absolute translate-middle-y top-50 end-0 me-10">
                          <i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title=""
                             data-bs-original-title="Description of Field" aria-label="Description of Field"></i>
                        </div>
                        <label htmlFor="partyType">Party Type</label>
                      </div>
                    </div>
                  </div>
                  {
                    party.type === "representative" &&
                    <div className="col-md-6">
                      <div className="fv-row mb-3">
                        <div className="form-floating mb-2">
                          <select
                            className="form-select"
                            id="identificationNumberRepresentative"
                            aria-label="Representative Status Code"
                            onChange={e => {
                              party.indirect_representative = e.target.value === "indirect"
                              this.setState({party})
                            }}
                          >
                            <option>Select</option>
                            <option value="direct" selected={!party.indirect_representative}>Direct representative</option>
                            <option value="indirect" selected={party.indirect_representative}>Indirect representative</option>
                          </select>
                          <div className="position-absolute translate-middle-y top-50 end-0 me-10">
                            <i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title=""
                               data-bs-original-title="Description of Field" aria-label="Description of Field"></i>
                          </div>
                          <label htmlFor="identificationNumberRepresentative">Representative Status Code</label>
                        </div>
                      </div>
                    </div>
                  }
                  <div className="col-md-6">
                    <div className="fv-row mb-3">
                      <div className="form-floating mb-2">
                        <input
                          type="text"
                          className="form-control"
                          id="identificationNumberRepresentative"
                          placeholder="Add Identification number"
                          value={party.identification_number}
                          onChange={e => {
                            party.identification_number = e.target.value
                            this.setState({party})
                          }}
                        />
                        <div className="position-absolute translate-middle-y top-50 end-0 me-3">
                          <i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title=""
                             data-bs-original-title="Description of Field" aria-label="Description of Field"></i>
                        </div>
                        <label htmlFor="identificationNumberRepresentative">Identification number</label>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="fv-row mb-3">
                      <div className="form-floating mb-2">
                        <input
                          type="text"
                          className="form-control"
                          id="declarantName"
                          placeholder="Add Identification Name"
                          value={party.name}
                          onChange={e => {
                            party.name = e.target.value
                            this.setState({party})
                          }}
                        />
                        <div className="position-absolute translate-middle-y top-50 end-0 me-3">
                          <i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title=""
                             data-bs-original-title="Description of Field" aria-label="Description of Field"></i>
                        </div>
                        <label htmlFor="declarantName">Name</label>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="fv-row mb-3">
                      <div className="form-floating mb-2">
                        <LocationInput
                          className="form-control"
                          placeholder={"Address"}
                          allowManualInput={true}
                          location={party.address}
                          onUpdated={address => {
                            address = {
                              line_1: address.address_line_1,
                              line_2: address.address_line_2,
                              city: address.city,
                              state: address.state,
                              country: address.country,
                              country_code: address.country_short,
                              postal_code: address.postal_code,
                              raw: address.raw
                            }
                            party.address = address
                            this.setState({party})
                          }}
                        />
                        <div className="position-absolute translate-middle-y top-50 end-0 me-3">
                          <i
                            className="fas fa-exclamation-circle ms-2 fs-7"
                            data-bs-toggle="tooltip"
                            title=""
                            data-bs-original-title="Description of Field"
                            aria-label="Description of Field"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" d-flex justify-content-end py-6">
                <button
                  className="btn btn-primary px-6"
                  id="addProductCode"
                  onClick={() => this._handleSubmission()}
                >
                  {title}
                </button>
              </div>
            </Card>
          </div>

        </div>
      </>
    )
  }

}
