import React from "react"

export default class Div extends React.Component {
    constructor(props){
        super(props)

        this.state = {
            canRenderChildren: props.canRenderChildren
        }
    }

    componentWillReceiveProps(nextProps){
        this.setState(nextProps)
    }

    _renderDisableView(){
        if(!this.props.disabled){
            return null
        }

        return (
            <div className="disable-view">
                <p>Loading...</p>
            </div>
        )
    }

    _renderLoading(){
        if(this.props.renderLoading){
            return this.props.renderLoading()
        }

        return null
    }

    render() {
        let {
            canRenderChildren
        } = this.state

        if(!canRenderChildren){
            return null
        }

        return (
            <div className={"relative "+this.props.className} style={this.props.style}>
                { this.props.children }
                { this._renderDisableView() }
            </div>
        )
    }
}

Div.defaultProps = {
    canRenderChildren: true
}
