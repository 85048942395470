import React from 'react'
import Modal from 'react-bootstrap/Modal'

export default class Confirmation extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      show: props.show
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  render() {
    let {
      show,
    } = this.state

    return (
      <Modal
        show={show}
        onHide={() => this.props.onHide()}
        centered
        className={'sub-modal'}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {this.props.title}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {this.props.message &&
            <span>{this.props.message}</span>
          }
        </Modal.Body>

        <Modal.Footer>

          <div className='buttons-grid gg-7'>
            <button
                type="button"
                className="btn btn-light me-3"
                onClick={() => this.props.onHide()}
            >
              Dismiss
            </button>

            <button
                type="button"
                className={`btn btn-${this.props.theme}`}
                onClick={() => this.props.onConfirm()}
                disabled={this.props.disabled}
            >
              { this.props.confirmTitleText }
            </button>
          </div>

        </Modal.Footer>

      </Modal>
    )
  }
}

Confirmation.defaultProps = {
  theme: "primary",
  title: "Are you sure?",
  message: "This action cannot be undone",
  confirmTitleText: "Confirm"
}
