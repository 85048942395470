import React from 'react'
import Departure from "../../modal/Departure";


export default class DepartureNotification extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      submission: props.submission,
    }
  }

  render() {
    let {
      submission,
      showDeparture
    } = this.state

    return (
      <>
        <button
          className="btn btn-sm btn-outline-primary btn-submission-action"
          onClick={() => this.setState({showDeparture: true})}
        >
          Depart
        </button>
        {
          showDeparture &&
          <Departure
            show={showDeparture}
            submission={submission}
            onDeparted={() => {
              this.props.onDeparted()
              this.setState({ showDeparture: false })
            }}
            onHide={() => this.setState({showDeparture: false})}
          />
        }
      </>
    )
  }
}
