import React from "react";
import Modal from 'react-bootstrap/Modal'

import Div from "../common/Div"

import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";
import AuthManager from "../../../utils/AuthManager";
import Declarant from "../../../utils/Declarant";

export default class Forms extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      currentUser: AuthManager.currentUser,
      show: props.show,
      type: props.type,
      template: props.template,
      authorisedForms: [],
      standardForms: [],
      templates: []
    }
  }

  componentDidMount() {
    let { type } = this.state
    Backend.getForms(true, type).then(authorisedForms => {
      this.setState({authorisedForms: authorisedForms})
    }).catch(e => Notify.error(e.message))
    Backend.getForms(false, type).then(standardForms => {
      this.setState({standardForms: standardForms})
    }).catch(e => Notify.error(e.message))
    Backend.getTemplates(type).then(templates => {
      this.setState({templates: templates.results})
    }).catch(e => Notify.error(e.message))
  }

  _createSubmission(form){

    let declarant = Declarant.getDeclarantId(form)

    let data = {
      declarant,
      answers: []
    }

    if(form.template){
      data.template_id = form.id
      data.form = form.form.id
    }else{
      data.form = form.id
    }

    // if(this.state.template){
    //   data.template = true
    //   data.name = form.name
    // }

    this.setState({ loading: true })
    Backend.submitDeclaration(data).then(submission => {
      window.location = `/submission/${submission.id}`
    }).catch(e => Notify.error(e.message))
  }

  _renderForms(forms){
    return (
      forms.map(form => {
        return (
          <>
            <div className="col-md-12 mb-1">
              <a onClick={e => this._createSubmission(form)} className="card bg-light-primary hoverable card-xl-stretch mb-xl-8">
                <div className="card-body">
                  <div className="text-primary fw-bolder fs-2 mb-2 mt-5">{form.name}</div>
                  <div className="fw-bold text-primary">{form.description}</div>
                </div>
              </a>
            </div>
          </>
        )
      })
    )
  }

  render() {
    let {
      show,
      type,
      loading,
      template,
      templates,
      standardForms,
      authorisedForms
    } = this.state

    let title = template ? "Create Template" : `Which Declaration would you like to add?`

    return (
      <>
        <Modal
          show={show}
          onHide={() => this.props.onHide()}
          className={'sub-modal'}
          dialogClassName={"modal-70"}
        >
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>

          <Modal.Body>

            <Div disabled={loading}>
              <ul className="nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6">
                <li className="nav-item">
                  <a className="nav-link active" data-bs-toggle="tab" href="#standardDeclarationss">Standard Declarations</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" data-bs-toggle="tab" href="#authorisedDeclarationss">Authorised Declarations</a>
                </li>
                {
                  !template &&
                  <li className="nav-item">
                    <a className="nav-link" data-bs-toggle="tab" href="#templates">Your Templates</a>
                  </li>
                }
              </ul>

              <div className="tab-content">
                <div className="tab-pane fade active show" id="standardDeclarationss">
                  <div className="row">
                    {this._renderForms(standardForms)}
                  </div>
                </div>
                <div className="tab-pane fade " id="authorisedDeclarationss">
                  <div className="row">
                    {this._renderForms(authorisedForms)}
                  </div>
                </div>
                {
                  !template &&
                  <div className="tab-pane fade " id="templates">
                    <div className="row">
                      {this._renderForms(templates)}
                    </div>
                  </div>
                }
              </div>
            </Div>

          </Modal.Body>

          <Modal.Footer>
            <button
              type="button"
              className="btn btn-light"
              data-bs-dismiss="modal"
              onClick={() => this.props.onHide()}
            >
              Cancel
            </button>
          </Modal.Footer>

        </Modal>
      </>
    )
  }

}
