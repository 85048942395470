import React from "react";

import CodeInput from "./CodeInput"

import Codes from "../common/Codes"
import Input from "./Input";
import SmartList from "../common/SmartList";

export default class AdditionalProcedures extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            editable: props.editable,
            additionalProcedures: props.additionalProcedures
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState(nextProps)
    }

    _renderAdditionalProcedure(additionalProcedure, index){
        let {
            editable,
            additionalProcedures
        } = this.state
        return (
            <div className="form-group row mb-5">
                <CodeInput
                    placeholder={"Additional Procedure"}
                    listKey={"AdditionalProcedure"}
                    value={additionalProcedure.code}
                    editable={editable}
                    onSelected={option => {
                        additionalProcedures[index].code = option
                        this.props.onUpdated(additionalProcedures)
                    }}
                />
            </div>
        )
    }


    render() {
        let {
            editable,
            additionalProcedures,
        } = this.state

        let itemName = "Additional Procedure"
        return (
            <SmartList
                minimal
                items={additionalProcedures}
                itemName={itemName}
                showItemName={true}
                canDelete={editable}
                canAdd={editable}
                renderItem={(item, index) => this._renderAdditionalProcedure(item, index)}
                renderDelete={(item, index) => (
                    <a
                        className="btn btn-sm btn-light-danger"
                        onClick={() => {
                            additionalProcedures.splice(index, 1)
                            this.props.onUpdated(additionalProcedures)
                        }}
                    >
                        <i className="la la-trash-o fs-3"></i>
                        {itemName}
                    </a>
                )}
                onUpdated={items => {
                    additionalProcedures = items
                    this.props.onUpdated(additionalProcedures)
                }}
                onAddItemPressed={() => {
                    additionalProcedures.push({})
                    this.props.onUpdated(additionalProcedures)
                }}
            />
        )
    }


}

AdditionalProcedures.defaultProps = {

}
