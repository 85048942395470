import React from "react"


export default class Guarantees extends React.Component {

  constructor(props) {
    super(props)

    this.state = {

    }
  }

  render() {
    let {

    } = this.state

    return (
      <>
        <div className="card-header border-0">
          <div className="card-title m-0" bis_skin_checked="1">
            <h3 className="fw-bold m-0">Guarantees</h3>
          </div>
        </div>
        <form
          id="kt_guarantees_form"
          className="form fv-plugins-bootstrap5 fv-plugins-framework"
          noValidate="novalidate"
        >
          <div className="card-body border-top p-9" bis_skin_checked="1">
            <div id="guaranteeRepeater">
              <div className="form-group">
                <div data-repeater-list="guaranteeRepeater_outer">
                  <div data-repeater-item="" className="mb-5 product-item">
                    <div className="form-group row mb-5">
                      <div className="col-md-6">
                        <h4 className="fw-bold mb-5">Guarantee [1]</h4>
                      </div>
                      <div className="col-md-6 text-end">
                        <a href="javascript:;" data-repeater-delete="" className="btn btn-sm btn-light-danger">
                          <i className="la la-trash-o fs-3"></i>Delete Guarantee
                        </a>
                      </div>
                    </div>
                    <div className="form-group row mb-5">
                      <div className="col-md-12">
                        <div className="form-floating mb-4">
                          <select className="form-select" id="guaranteeType" aria-label="Guarantee Type">
                            <option value="Guarantee waiver" selected="">Guarantee Waiver</option>
                          </select>
                          <label htmlFor="guaranteeType">Guarantee Type</label>
                        </div>
                        <div className="separator separator-dashed my-6" bis_skin_checked="1"></div>
                      </div>
                      <div className="col-md-12">
                        <div className="inner-repeater">
                          <div data-repeater-list="guaranteeRepeater_inner" className="mb-5">
                            <div data-repeater-item="">
                              <div className="fv-row row mb-4">
                                <div className="col-md-6">
                                  <div className="form-floating mb-4">
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="grn"
                                      placeholder="GRN"
                                    />
                                    <label htmlFor="grn">GRN</label>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-floating mb-4">
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="otherGuaranteeReference"
                                      placeholder="Other Guarantee Reference"
                                    />
                                    <label htmlFor="otherGuaranteeReference">Other Guarantee Reference</label>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-floating mb-4">
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="accessCode"
                                      placeholder="Access Code"
                                    />
                                    <label htmlFor="accessCode">Access Code</label>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-floating mb-4">
                                    <select className="form-select" id="currencyCodeCondition"
                                            aria-label="Currency Code of Condition">
                                      <option value="AED" selected="">AED - UAE Dirham</option>
                                    </select>
                                    <label htmlFor="currencyCodeCondition">Currency Code of Condition</label>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-floating mb-4">
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="guaranteeAmount"
                                      placeholder="Amount"
                                    />
                                    <label htmlFor="guaranteeAmount">Amount</label>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-floating mb-4">
                                    <select className="form-select" id="officeOfGuarantee"
                                            aria-label="Customs Office of Guarantee">
                                      <option value="AD000003" selected="">AD000003 - Central Customs Office</option>
                                    </select>
                                    <label htmlFor="officeOfGuarantee">Customs Office of Guarantee</label>
                                  </div>
                                </div>
                              </div>
                              <button className="btn btn-sm btn-danger" data-repeater-delete="" type="button">
                                Delete Guarantee Reference
                              </button>
                              <div className="separator separator-dashed my-6" bis_skin_checked="1"></div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <button className="btn btn-sm btn-primary" data-repeater-create="" type="button">
                                <i className="la la-plus"></i>
                                Add Another Reference
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <a href="javascript:;" data-repeater-create="" className="btn btn-light-primary">
                  <i className="la la-plus"></i>
                  Add Another Guarantee
                </a>
              </div>
            </div>
          </div>
          <div className="card-footer d-flex justify-content-end py-6 px-9" bis_skin_checked="1">
            <button type="reset" className="btn btn-light btn-active-light-primary me-2">Discard</button>
            <button type="submit" className="btn btn-primary" id="kt_guarantees_submit">Save Changes</button>
          </div>
          <input type="hidden"/>
          <div bis_skin_checked="1"></div>
        </form>
      </>
    )
  }

}