import React from "react";

export default class Separator extends React.Component{
  render() {
    return (

      <div className="c-separator form-group row mt-4 mb-4 align-items-center text-center">
		<div className="col-3">
			<hr/>
		</div>
		<div className="col-6">
			<span><strong>{this.props.text}</strong></span>
		</div>
		<div className="col-3">
			<hr/>
		</div>
	  </div>

    )
  }
}
