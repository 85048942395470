import React from "react"

import { Link, withRouter } from "react-router-dom";

import DateRangePicker from '@wojtekmaj/react-daterange-picker';

import BaseTable from "./BaseTable"

import Edit from "./cells/Edit";
import Delete from "./cells/Delete";

import Party from "../modal/Party";
import Confirmation from "../modal/Confirmation";

import Declarant from "../../../utils/Declarant";
import Notify from "../../../utils/Notify";
import General from "../../../utils/General";
import Backend from "../../../utils/Backend";

const TYPES_FILTERS = {
  name: {
    api: 'type',
    display: 'Type',
  },
  values: [
    {
      label: 'All',
      value: null,
    },
    {
      label: 'Buyer',
      value: 'buyer',
    },
    {
      label: 'Owner',
      value: 'owner',
    },
    {
      label: 'Seller',
      value: 'seller',
    },
    {
      label: 'Client',
      value: 'client',
    },
    {
      label: 'Contact',
      value: 'contact',
    },
    {
      label: 'Importer',
      value: 'importer',
    },
    {
      label: 'Exporter',
      value: 'exporter',
    },
    {
      label: 'Declarant',
      value: 'declarant',
    },
    {
      label: 'Representative',
      value: 'representative',
    },
  ],
}

const FILTERS = [TYPES_FILTERS]

  class PartiesTable extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      live: props.live
    }

    this.table = React.createRef()
  }

  componentDidMount(){
    // check for HMRC response
    let code = Declarant.getHMRCAuthCode()
    if(!code){
      return null
    }

    this.setState({
      selectedParty: {
        type: "declarant",
        certificate: {
          code,
          type: "hmrc",
          return_url: window.location.href
        }
      },
      showPartyModal: true,
    })
  }

  _handleDelete(party){
    Backend.deleteParty(party).then(() => {
      Notify.success("Party Deleted.")
      this.setState({showConfirmationModal: false, selectedParty: null})
      this.table.current.refresh()
    }).catch(e => Notify.error(e.message))
  }

  _getColumns() {

    let columns = [
      {
        Header: 'Party Type',
        id: 'type',
        accessor: party => General.snakeCaseToTitleCase(party.type),
      },
      {
        Header: 'Identification Number',
        id: 'identification_number',
        accessor: 'identification_number',
      },
      {
        Header: 'Name',
        id: 'name',
        accessor: 'name',
      },
      {
        Header: 'Address',
        id: 'address',
        accessor: 'address.raw',
      },
      {
        Header: '',
        id: '',
        sortable: false,
        exportable: false,
        Cell: rowInfo => {
          let party = rowInfo.original
          return (
            <div className={"text-end my-auto"}>
              <Edit
                onEditClicked={() => {
                  this.setState({showPartyModal: true, selectedParty: party})
                }}
              />
              <Delete
                onDeleteClicked={() => {
                  this.setState({showConfirmationModal: true, selectedParty: party})
                }}
              />
            </div>
          )
        }
      }
    ]

    return columns

  }

  render() {
    let {
      selectedParty,
      showPartyModal,
      showConfirmationModal
    } = this.state

    const columns = this._getColumns()

    return (
      <>
        <BaseTable
          ref={this.table}
          endpoint={window.Api.Parties}
          noDataMessage={"No parties found"}
          title={this.props.title}
          columns={columns}
          filters={FILTERS}
          defaultSorted={[
            {
              id: "created_at",
              desc: true
            }
          ]}
          renderToolbar={() => (
            <>
              <div className="card-toolbar" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover"
                   data-kt-initialized="1" data-bs-original-title="" title="">
                <a
                  href="#"
                  className="btn btn-primary"
                  onClick={() => {
                    this.setState({showPartyModal: true})
                  }}
                >
                  + Add Party
                </a>
              </div>
            </>
          )}
        />
        {
          showPartyModal &&
          <Party
            name={"Party"}
            show={showPartyModal}
            party={selectedParty}
            onHide={() => this.setState({showPartyModal: false, selectedParty: null})}
            onSubmit={() => {
              this.table.current.refresh()
              this.setState({showPartyModal: false, selectedParty: null})
            }}
          />
        }
        {
            showConfirmationModal &&
            <Confirmation
                show={showConfirmationModal}
                onConfirm={() => this._handleDelete(selectedParty)}
                onHide={() => this.setState({showConfirmationModal: false, selectedParty: null})}
            />
        }
      </>
    )

  }

}

export default withRouter(PartiesTable);
