import React from "react";
import Modal from 'react-bootstrap/Modal'

import AsyncSelect from "../common/AsyncSelect";
import SmartList from "../common/SmartList";
import Codes from "../common/Codes"
import FileUploader from "../common/FileUploader";
import CustomDropzone from "../common/CustomDropzone"

import AuthManager from "../../../utils/AuthManager";
import General from "../../../utils/General";
import MockData from "../../../utils/MockData";
import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";

const ALLOWED_FILE_FORMATS = [
  '.pdf',
  '.doc',
  '.docx',
  '.png',
  '.jpg',
  '.jpeg',
  '.csv',
  '.xls',
  '.xlsx',
  '.txt'
]

export default class Attachments extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      show: props.show,
      submission: props.submission,

    }
  }

  _isFormValid(){
    let {
      type,
      file,
    } = this.state

    let error = null
    if(!type){
      error = "Please select a type"
    }
    else if(!file){
      error = "Please select a file"
    }

    if(error){
      Notify.error(error)
      return false
    }

    return true
  }

  _addAttachment(){
    let {
      type,
      file,
      submission
    } = this.state

    if(!this._isFormValid()){
      return
    }

    let data = {
      type: type.id,
      file: file.id,
      submission: submission.id
    }

    this.setState({loading: true})
    Backend.addAttachment(data)
    .then(e => {
      this.setState({loading: false, file: null, type: null})
      Notify.success("Attachment Added")
      this.props.onAdded()
    }).catch(e => {
      this.setState({loading: false})
      Notify.error(e.message)
    })
  }


  render() {
    let {
      show,
      file,
      type,
      loading,
      submission
    } = this.state

    return (
      <>
        <Modal
          show={show}
          onHide={() => this.props.onHide()}
          className={'sub-modal'}
          dialogClassName={"modal-30"}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Attachment</Modal.Title>
          </Modal.Header>

          <Modal.Body>

            {AuthManager.isInvoiceVerificationEnabled() &&
              <p>Select "Invoice - Proof of Purchase/Sale" to enable AI Invoice Verification</p>
            }

            <div className="form-group row mt-5 mb-5">

              <div className="col-md-12">
                <div className="form-floating mb-4">
                  <Codes
                    placeholder={"Type"}
                    orderBy={"name"}
                    filter={`list_key=AttachmentType`}
                    value={type}
                    onSelected={option => {
                      this.setState({type: option})
                    }}
                  />
                </div>
              </div>
              <div className="col-md-12 text-center">

                <div className="fv-row mt-10 mb-10 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                  <div className="dropzone dropzone-default dropzone-no-border">
                    <CustomDropzone
                      deletable={true}
                      fileTypes={ALLOWED_FILE_FORMATS}
                      onUploaded={file => {
                        this.setState({file})
                      }}
                      onDelete={() => {
                        this.setState({file: null})
                      }}
                    />
                  </div>
                </div>

              </div>
            </div>

            <div className="form-group text-end mt-10">
              <button
                type="button"
                className="btn btn-light-primary"
                disabled={loading}
                onClick={() => this._addAttachment()}
              >
                Save
              </button>
            </div>
          </Modal.Body>

        </Modal>
      </>
    )
  }


}
