import React from "react"

import General from "../../../utils/General";
import Field from "./Field";

export default class Row extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      row: props.row,
      editable: props.editable,
      index: props.index,
      group: props.group,
      submission: props.submission
    }

    this.fieldRefs = {}
  }

  componentWillReceiveProps(props) {
    this.setState(props)
  }

  getErrors(){
    let errors = []
    Object.keys(this.fieldRefs).map((key, index) => {
        let fieldRef = this.fieldRefs[index]
        let error = fieldRef.getError()
        if(error){
          errors.push(error)
        }
    })
    return errors
  }

  getUnansweredRecommendedFields(){
    let unansweredRecommendedFields = []
    Object.keys(this.fieldRefs).map((key, index) => {
        let fieldRef = this.fieldRefs[index]
        let unansweredRecommendedField = fieldRef.getUnansweredRecommendedField()
        if(unansweredRecommendedField){
          unansweredRecommendedFields.push(unansweredRecommendedField)
        }
    })
    return unansweredRecommendedFields
  }

  render() {
    let {
      row,
      index,
      group,
      editable,
      submission
    } = this.state

    if(row.fields.length === 0){
      return null
    }

    return (
      <div className={"row mb-2"}>
        { row.fields.map((field, fieldIndex) => {
          return (
            <Field
              ref={ref => this.fieldRefs[fieldIndex] = ref}
              field={field}
              editable={editable}
              index={index}
              group={group}
              submission={submission}
              onUpdated={fieldData => {
                let rowData = General.clone(row)
                rowData.fields[fieldIndex] = fieldData
                this.props.onUpdated(rowData)
              }}
              onBlur={this.props.onBlur}
              onDuplicate={fieldData => {
                let rowData = General.clone(row)
                rowData.fields[fieldIndex] = fieldData
                this.props.onDuplicate(rowData)
              }}
            />
          )
          })
        }
      </div>
    )
  }
}
