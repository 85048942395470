import React from "react"

import ImageEdit from "../common/ImageEdit";
import AsyncSelect from "../common/AsyncSelect";
import Select from "../common/Select"

import Notify from "../../../utils/Notify";
import Backend from "../../../utils/Backend";
import General from "../../../utils/General";
import AuthManager from "../../../utils/AuthManager";

export const ROUTE_FILTERS = [
    {
      label: 'Red',
      value: 'red',
    },
    {
      label: 'Orange',
      value: 'orange',
    },
    {
      label: 'Yellow',
      value: 'yellow',
    }
]

export default class Company extends React.Component {

  constructor(props) {
    super(props)

    let company = AuthManager.currentUser.company
    this.state = {
      data: {
        logo: company.logo,
        backup_tan: company.backup_tan,
        auto_rejection_routes: company.auto_rejection_routes,
        template_ais_submission: company.template_ais_submission,
        notifiable_contacts_for_route_cleared: company.notifiable_contacts_for_route_cleared
      }
    }
  }

  _save(){
    let {
      data
    } = this.state
    this.setState({ loading: true })

    Backend.updateCompany(data)
    .then(company => {
      AuthManager.currentUser.company.template_ais_submission = company.template_ais_submission
      Notify.success('Your details have been changed successfully')
      this.setState({ loading: false })
    })
    .catch(error => {
      this.setState({ loading: false })
      Notify.error(error.message)
    })
  }

  _getOptions(contacts){
    return contacts.map(contact => ({
        value: contact.id,
        label: contact.name,
        data: contact
    }))
  }

  render() {
    let {
      loading,
      data,
    } = this.state

    return (
      <>
        <div className="card-header border-0">
          <div className="card-title m-0" bis_skin_checked="1">
            <h3 className="fw-bold m-0">Company Settings</h3>
          </div>
        </div>
        <div
          id="kt_account_profile_details_form"
          className="form fv-plugins-bootstrap5 fv-plugins-framework"
          noValidate="novalidate"
        >
          <div className="card-body border-top p-9" bis_skin_checked="1">

            <div className="form-group row mb-10">
              <div className="col-4">
                <label className="required fs-6 fw-semibold form-label mb-2">Logo</label>
                <div className="position-relative">
                  <ImageEdit
                    aspectRatio={1}
                    cropImage={true}
                    image={data.logo?.original}
                    onUpdated={image => {
                      data.logo = image
                      this.setState({data})
                    }}
                    onDeleteClicked={() => {
                      data.logo = null
                      this.setState({data})
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="form-group row mb-10">
              <label className="form-label ">Auto Rejection Routes</label>
              <Select
                isClearable={true}
                isMulti={true}
                value={data.auto_rejection_routes.map(route => {
                  return {
                    label: General.toTitleCase(route),
                    value: route
                  }
                })}
                options={ROUTE_FILTERS}
                placeholder="Routes"
                getOptionLabel={route => route.label}
                getOptionValue={route => route.value}
                className={'custom-async-select__container'}
                classNamePrefix={'custom-async-select'}
                onSelected={result => {
                  data.auto_rejection_routes = result?.map(option => option.value) || []
                  this.setState({ data })
                }}
              />
              <span className="text-muted">Automatically rejected declarations where one or more TARIC codes has been previously routed to one or more of the above statuses</span>
            </div>

            <div className="form-group row mb-10">
              <label className="form-label ">Template Linked AIS Declaration</label>
              <AsyncSelect
                placeholder={"Please select a template"}
                endpoint={window.Api.Submissions}
                orderBy={"name"}
                filter={`template=true&form_type=ais`}
                isClearable={false}
                className={'custom-async-select__container'}
                classNamePrefix={'custom-async-select'}
                value={General.getSingleAsyncOption(data.template_ais_submission)}
                getOptions={options => {
                    return options.map(option => ({
                        value: option.id,
                        label: option.name,
                        data: option
                    }))
                }}
                onSelected={submission => {
                  data.template_ais_submission = submission
                  this.setState({ data })
                }}
              />
              <span className="text-muted">Template used to automatically create an AIS Declaration when a CDS Export has Ireland as its "Destination Country Code [5/8]".</span>
            </div>

            <div className="form-group row mb-10">
              <label className="form-label ">Backup TAN</label>
              <input
                type="text"
                className="form-control form-control-lg mb-3"
                id="test"
                placeholder="Backup TAN"
                name="backup_tan"
                value={data.backup_tan}
                onChange={e => {
                  data.backup_tan = e.target.value || null
                  this.setState({ data })
                }}
              />
              <span className="text-muted">TAN used to automatically amend a declaration's "Customs Duty Payer Identification Number [3/46]" when an 'Insufficient Funds' message is received.</span>
            </div>

            <div className="form-group row mb-10">
              <label className="form-label ">Control Notice Clearance Contacts</label>
              <AsyncSelect
                  endpoint={window.Api.Parties}
                  orderBy={"name"}
                  filter={`type=contact`}
                  isMulti={true}
                  className={'custom-async-select__container'}
                  classNamePrefix={'custom-async-select'}
                  value={this._getOptions(data.notifiable_contacts_for_route_cleared)}
                  getOptions={options => this._getOptions(options)}
                  onSelected={options => {
                    let contacts = []
                    if(options){
                      contacts = options.map(option => option.data)
                    }
                    data.notifiable_contacts_for_route_cleared = contacts
                    this.setState({ data })
                  }}
              />
              <span className="text-muted">Contacts to be notified when a orange/yellow/red routing becomes green.</span>
            </div>

          </div>
        </div>

        <div className="card-footer d-flex justify-content-end py-6 px-9" bis_skin_checked="1">
          <button
            type="submit"
            className="btn btn-primary"
            id="kt_account_profile_details_submit"
            onClick={() => this._save()}
            disabled={loading}
          >
            Save
          </button>
        </div>
        <input type="hidden"/>
        <div bis_skin_checked="1"></div>
      </>
    )
  }

}
