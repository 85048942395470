import React from "react";
import Modal from 'react-bootstrap/Modal'
import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";
import AuthManager from "../../../utils/AuthManager";

export default class FieldHelpText extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      show: props.show,
      field: props.field
    }
  }

  _handleChange(e) {
    let { field } = this.state
    field[e.target.name] = e.target.value || null
    this.setState({ field, error: null })
  }

  _save(){
    let { field } = this.state

    let data = {
      id: field.id,
      help_text: field.help_text
    }

    this.setState({loading: true})
    Backend.updateField(data)
    .then(field => {
      this.setState({loading: false})
      Notify.success("Field Saved!")
      this.props.onUpdated()
    }).catch(e => {
      this.setState({loading: false})
      Notify.error(e.message)
    })
  }

  render() {
    let {
      show,
      field,
      loading,
    } = this.state


    return (
      <>
        <Modal
          show={show}
          onHide={() => this.props.onHide()}
          className={'sub-modal'}
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit {field.title} Help Text</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div
              className="d-flex flex-column scroll-y me-n7 pe-7" id="kt_modal_add_user_scroll"
              data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}"
              data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_user_header"
              data-kt-scroll-wrappers="#kt_modal_add_user_scroll" data-kt-scroll-offset="300px"
              style={{maxHeight: 458}} bis_skin_checked="1"
            >
              <div className="fv-row mb-7 fv-plugins-icon-container" bis_skin_checked="1">
                <label className="required fw-semibold fs-6 mb-2">Help Text</label>
                <textarea
                  type="text"
                  name="help_text"
                  className="form-control form-control-solid mb-3 mb-lg-0"
                  placeholder={field.placeholder}
                  minRows="1"
                  value={field.help_text}
                  onChange={e => this._handleChange(e)}
                />
                <span class="text-muted">Help Text Displayed To User</span>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="text-right">
              <button
                className="btn btn-light ml-auto"
                onClick={() => this.props.onHide()}
              >
                Close
              </button>
              <button
                className="btn btn-primary ms-3"
                disabled={loading}
                onClick={e => {
                  this._save()
                }}
              >
                Save
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </>
    )
  }

}
