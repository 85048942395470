import React from "react";
import General from "../../../utils/General";
import Form from "../../../utils/Form";

export default class Collapsible extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            id: props.id || General.uuid(),
            title: props.title,
            hidden: props.hidden,
            defaultExpanded: props.defaultExpanded
        }
    }

    render() {
        let {
            id,
            title,
            hidden,
            defaultExpanded
        } = this.state

        let headerId = `${id}_header`
        let bodyId = `${id}_body`

        if(hidden){
          return null
        }

        let accordionClassName = defaultExpanded ? "show" : ""

        return (
            <div className="accordion-item">
                <h2 className="accordion-header" id={headerId}>
                    <button
                        className={`accordion-button fs-4 fw-semibold collapsed`}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#${bodyId}`}
                        aria-expanded="false"
                        aria-controls={bodyId}
                        onClick={() => this.setState({ defaultExpanded: false })}
                    >
                        <span>{title}</span>
                        {this.props.renderToolbar()}
                    </button>
                </h2>
                <div
                    id={bodyId}
                    className={`accordion-collapse collapse  ${accordionClassName}`}
                    aria-labelledby={headerId}
                >
                    <div className="accordion-body">
                        {this.props.children}
                    </div>
                </div>
            </div>

        )
    }


}

Collapsible.defaultProps = {
    declaration: true,
    hidden: false,
    renderToolbar: () => {}
}
