import React from "react";
import StatCard from "./components/common/StatCard";
import DeclarationsTable from "./components/tables/SubmissionsTable";
import AuthManager from "../utils/AuthManager";
import Backend from "../utils/Backend";
import Notify from "../utils/Notify";
import Event from "../utils/Event";
import { Link } from "react-router-dom";

export default class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: AuthManager.currentUser,
    };
  }

  componentDidMount() {
    Backend.getOverallStats()
      .then((stats) => this.setState({ stats }))
      .catch((e) => Notify.error(e.message));
  }

  _renderDeclarationStat(cardClassName) {
    let { stats } = this.state;

    if (!stats) return null;

    return (
      <>
        <div className="row">
          <StatCard
            cardClassName={cardClassName}
            title={"Declarations Last 7 Days"}
            variation={"detail"}
            count={stats.overall.periods.seven_days.current.count}
            percentage={
              stats.overall.periods.seven_days.current.percentage_delta
            }
            className={
              stats.overall.periods.seven_days.current.count >
              stats.overall.periods.seven_days.previous.count
                ? "success"
                : "danger"
            }
          />
          <StatCard
            cardClassName={cardClassName}
            title={"Declarations Last 30 Days"}
            variation={"detail"}
            count={stats.overall.periods.thirty_days.current.count}
            percentage={
              stats.overall.periods.thirty_days.current.percentage_delta
            }
            className={
              stats.overall.periods.thirty_days.current.count >
              stats.overall.periods.thirty_days.previous.count
                ? "success"
                : "danger"
            }
          />
          <StatCard
            cardClassName={cardClassName}
            title={"Declarations Last 3 Months"}
            variation={"detail"}
            count={stats.overall.periods.ninety_days.current.count}
            percentage={
              stats.overall.periods.ninety_days.current.percentage_delta
            }
            className={
              stats.overall.periods.ninety_days.current.count >
              stats.overall.periods.ninety_days.previous.count
                ? "success"
                : "danger"
            }
          />
          <StatCard
            cardClassName={cardClassName}
            title={"Declarations Last Year"}
            variation={"detail"}
            count={stats.overall.periods.year.current.count}
            percentage={stats.overall.periods.year.current.percentage_delta}
            className={
              stats.overall.periods.year.current.count >
              stats.overall.periods.year.previous.count
                ? "success"
                : "danger"
            }
          />
        </div>
      </>
    );
  }

  _renderStatSection() {
    let { currentUser } = this.state;

    if (currentUser.company.onboarding_checklist.complete) {
      return this._renderDeclarationStat("col-md-3");
    }

    return (
      <>
        <div className="col-md-6">
          <div className="card card-xl-stretch mb-5 mb-xl-8">
            <div className="card-header border-0">
              <h3 className="card-title fw-bolder dark">Finish Your Account</h3>
            </div>

            <div className="card-body pt-2">
              <div className="d-flex align-items-center mb-8">
                <span className="bullet bullet-vertical h-40px bg-primary" />
                <div className="form-check form-check-custom form-check-solid mx-5">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={currentUser.company.onboarding_checklist.guarantee}
                  />
                </div>
                <div className="flex-grow-1">
                  <Link
                    to="/guarantees"
                    className="gray-800 hover-primary fw-bolder fs-6"
                  >
                    Add A Guarantee
                  </Link>
                  <span className="muted fw-bold d-block">
                    This can apply to all of your declarations
                  </span>
                </div>
              </div>

              <div className="d-flex align-items-center mb-8">
                <span className="bullet bullet-vertical h-40px bg-primary" />
                <div className="form-check form-check-custom form-check-solid mx-5">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={currentUser.company.onboarding_checklist.client}
                  />
                </div>
                <div className="flex-grow-1">
                  <Link
                    to="/clients"
                    className="gray-800 hover-primary fw-bolder fs-6"
                  >
                    Add Your First Client
                  </Link>
                  <span className="muted fw-bold d-block">
                    Set Up Your First Client
                  </span>
                </div>
              </div>

              <div className="d-flex align-items-center mb-8">
                <span className="bullet bullet-vertical h-40px bg-primary" />
                <div className="form-check form-check-custom form-check-solid mx-5">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={currentUser.company.onboarding_checklist.item}
                  />
                </div>
                <div className="flex-grow-1">
                  <Link
                    to="/products"
                    className="gray-800 hover-primary fw-bolder fs-6"
                  >
                    Add Your First Product
                  </Link>
                  <span className="muted fw-bold d-block">
                    Set Up Your First Product
                  </span>
                </div>
              </div>

              <div className="d-flex align-items-center mb-8">
                <span className="bullet bullet-vertical h-40px bg-primary" />
                <div className="form-check form-check-custom form-check-solid mx-5">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={
                      currentUser.company.onboarding_checklist.submission
                    }
                  />
                </div>
                <div className="flex-grow-1">
                  <a
                    href="#"
                    className="gray-800 hover-primary fw-bolder fs-6"
                    onClick={() => {
                      Event.emit("ADD_DECLARATION");
                    }}
                  >
                    Submit A Declaration
                  </a>
                  <span className="muted fw-bold d-block">
                    Submit your first declaration
                  </span>
                </div>
              </div>

              <div className="d-flex align-items-center ">
                <span className="bullet bullet-vertical h-40px bg-primary" />
                <div className="form-check form-check-custom form-check-solid mx-5">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={currentUser.company.onboarding_checklist.template}
                  />
                </div>
                <div className="flex-grow-1">
                  <Link
                    to="/import-submissions"
                    className="gray-800 hover-primary fw-bolder fs-6"
                  >
                    Set Up Template
                  </Link>
                  <span className="muted fw-bold d-block">
                    This will speed up declarations going forward
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          {this._renderDeclarationStat("col-md-6")}
        </div>
      </>
    );
  }

  _renderStatBadgeSection(stats) {
    const statData = [
      {
        label: "Processed",
        value: stats.overall.verification.total_no,
        labelClass: "text-primary",
      },
      {
        label: "Verified",
        value: stats.overall.verification.passed_no,
        labelClass: "text-success",
      },
      {
        label: "Failed",
        value: stats.overall.verification.failed_no,
        labelClass: "text-danger",
      },
    ];

    return (
      <StatCard
        cardClassName={this.state.cardClassName}
        title={"AI Verified"}
        variation={"badge"}
        stats={statData}
      />
    );
  }

  render() {
    let { stats } = this.state;

    return (
      <>
        {this._renderStatSection()}
        {stats && (
          <div className="row gb-5">
            <StatCard className={"success"} count={stats.overall.green} />

            <StatCard className={"warning"} count={stats.overall.orange} />

            <StatCard className={"danger"} count={stats.overall.red} />

            {AuthManager.isInvoiceVerificationEnabled() &&
              this._renderStatBadgeSection(stats)}
          </div>
        )}

        <div className="row">
          <div className="col-sm-12 col-xl-12">
            <DeclarationsTable
              title={"Recent Declarations"}
              subtitle={"Last 20 Declarations"}
              mode={"latest"}
            />
          </div>
        </div>
      </>
    );
  }
}
