import React from "react"

import RevealKeyModal from "../modal/RevealKey"
import ConfirmationModal from "../modal/Confirmation"

import Div from "../common/Div";

import AuthManager from "../../../utils/AuthManager";
import Backend from "../../../utils/Backend";
import Email from "../../../utils/Email";
import Notify from "../../../utils/Notify";

export default class Api extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      secretKey: null,
    }
  }

  componentDidMount() {
    this._setup()
  }

  _setup(){
    Backend.getSecretKey()
    .then(secretKey => {
      this.setState({
        secretKey,
        loading: false
      })
    })
    .catch(error => {
      this.setState({
        loading: false
      })
    })
  }

  render() {
    let {
      loading,
      secretKey,
      rotateSecretKey,
      showRevealKeyModal,
      showRotateKeyModal,
      showRevealConfirmationModal,
      showRotateConfirmationModal,
    } = this.state

    return (
      <>
        <div className="card-header border-0">
          <div className="card-title m-0" bis_skin_checked="1">
            <h3 className="fw-bold m-0">API Settings</h3>
          </div>
        </div>
        <div
          className="form fv-plugins-bootstrap5 fv-plugins-framework"
          noValidate="novalidate"
        >
          <div className="card-body border-top p-9" bis_skin_checked="1">

            <Div
              className="kt-wizard-v2__content"
              data-ktwizard-type="step-content"
              data-ktwizard-state="current"
              disabled={loading}
              spinner={true}
            >
              <div className="kt-form__section kt-form__section--first">
                <div className="kt-wizard-v2__form">

                  <div className="form-group row">
                    <label className="col-3 col-form-label">Secret Key</label>
                    <div className="col-6">
                      <input
                        className="form-control form-control-solid"
                        type="text"
                        value={secretKey?.truncated || "*****************"}
                        disabled
                      />
                    </div>
                    <div className="col-3">
                      { !secretKey &&
                        <button
                          className="btn btn-sm btn-outline-danger mt-1"
                          onClick={() => this.setState({ showRevealConfirmationModal: true })}
                        >
                          Reveal
                        </button>
                      }
                      { secretKey &&
                        <button
                          className="btn btn-sm btn-outline-danger mt-1"
                          onClick={() => this.setState({ showRotateConfirmationModal: true })}
                        >
                          Rotate
                        </button>
                      }
                    </div>
                  </div>

                </div>
              </div>
            </Div>
          </div>
        </div>

        <ConfirmationModal
          show={showRevealConfirmationModal}
          title={"Reveal Secret Key"}
          body={"This key can only be revealed once to keep your account secure. If you lose it, you must rotate the key."}
          onConfirm={() => this.setState({ showRevealConfirmationModal: false, showRevealKeyModal: true, rotateSecretKey: false })}
          confirmTitleText={"Reveal Key"}
          theme={"danger"}
          dismissTitleText={"Close"}
          onHide={() => {
            this.setState({
              showRevealConfirmationModal: false,
              rotateSecretKey: false
            })
          }}
        />

        <ConfirmationModal
          show={showRotateConfirmationModal}
          title={"Rotate Secret Key"}
          body={"Any applications/integrations depending on the current key will no longer work"}
          onConfirm={() => this.setState({ showRotateConfirmationModal: false, showRevealKeyModal: true, rotateSecretKey: true })}
          confirmTitleText={"Rotate Key"}
          theme={"danger"}
          dismissTitleText={"Close"}
          onHide={() => {
            this.setState({
              showRotateConfirmationModal: false,
              rotateSecretKey: false
            })
          }}
        />

        <RevealKeyModal
          show={showRevealKeyModal}
          rotate={rotateSecretKey}
          onSecretKeyUpdated={secretKey => this.setState({ secretKey}) }
          onHide={() => {
            this.setState({
              showRevealKeyModal: false,
              rotateSecretKey: false
            })
          }}
        />
      </>
    )
  }

}
