import React from "react"
import MockData from "../utils/MockData";
import LocationInput from "./components/common/LocationInput";
import AccountDetails from "./components/register/AccountDetails";
import DeclarantInfo from "./components/register/DeclarantInfo";
import RepresentativeInfo from "./components/register/RepresentativeInfo";
import AddOns from "./components/register/AddOns";
import Payment from "./components/register/Payment";
import {Link} from "react-router-dom";
import AuthManager from "../utils/AuthManager";

export default class Register extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      activeTabIndex: 0,
      data: {
        name: "",
        members: [
          {
            user: {
              first_name: "",
              last_name: "",
              email: "",
              password: "",
            }
          }
        ]
      }
    }
  }

  componentDidMount() {
    document.getElementById("root").className = "bg-body"
    AuthManager.silentLogin()
      .then(companyMember => {
        if(!companyMember.company.onboarding_checklist.declarant){
          this.setState({activeTabIndex: 1})
        }else if(!companyMember.company.onboarding_checklist.subscription_activate) {
          this.setState({activeTabIndex: 2})
        }else{
          window.location = "/"
        }
      })
  }

  _renderContent(){
    let { data, activeTabIndex } = this.state
    if(activeTabIndex == null){
      return null
    }

    switch(activeTabIndex){
      case 0: return <AccountDetails data={data} onRegistered={() => this.setState({activeTabIndex: 1})} />
      case 1: return <DeclarantInfo onDeclarantAdded={() => this.setState({activeTabIndex: 2})} />
      case 2: return <AddOns onAddOnsSelected={(data, total) => this.setState({data, total, activeTabIndex: 3})} />
      case 3: return <Payment data={this.state.data} total={this.state.total} onPaymentSuccessful={() => this.setState({activeTabIndex: 4})} />
      case 4: return (
        <>
          <div className="current" data-kt-stepper-element="content">
            <div className="w-100">
              <div className="pb-8 pb-lg-10">
                <h2 className="fw-bolder text-dark">You Are Done!</h2>
              </div>
              <div className="mb-0">
                <div className="fs-6 text-gray-600 mb-5">Your account is set up. You can continue to build
                  your account by following the to-do list on the next screen.
                </div>
                <Link to="/" className="btn btn-light-primary">Go to Dashboard</Link>
              </div>
            </div>
          </div>
        </>
      )
      default: return null
    }
  }

  render() {
    let {
      data,
      activeTabIndex
    } = this.state

    return (
      <>
        <div className="d-flex flex-column flex-root">

          <div
            className="d-flex flex-column flex-lg-row flex-column-fluid stepper stepper-pills stepper-column first"
            id="kt_create_account_stepper" data-kt-stepper="true"
          >

            <div className="d-flex flex-column flex-lg-row-auto w-xl-500px bg-lighten shadow-sm">

              <div className="d-flex flex-column position-xl-fixed top-0 bottom-0 w-xl-500px scroll-y">

                <div className="d-flex flex-row-fluid flex-column flex-center p-10 pt-lg-20">

                  <Link to={"/register"} className="mb-10 mb-lg-20">
                    <img alt="Logo" src={require("../assets/media/logos/dark-logo.svg").default} className="h-60px"/>
                  </Link>

                  <div className="stepper-nav">

                    <div className={`stepper-item ${activeTabIndex === 0 ? "current" : activeTabIndex > 0 ? "completed" : "pending"}`} data-kt-stepper-element="nav">
                      <div className="stepper-line w-40px"></div>
                      <div className="stepper-icon w-40px h-40px">
                        <i className="stepper-check fas fa-check"></i>
                        <span className="stepper-number">1</span>
                      </div>
                      <div className="stepper-label">
                        <h3 className="stepper-title">Account Creation</h3>
                        <div className="stepper-desc fw-bold">Setup Your Account Details</div>
                      </div>
                    </div>

                    <div className={`stepper-item ${activeTabIndex === 1 ? "current" : activeTabIndex > 1 ? "completed" : "pending"}`} data-kt-stepper-element="nav">
                      <div className="stepper-line w-40px"></div>
                      <div className="stepper-icon w-40px h-40px">
                        <i className="stepper-check fas fa-check"></i>
                        <span className="stepper-number">2</span>
                      </div>
                      <div className="stepper-label">
                        <h3 className="stepper-title">Declarant Info</h3>
                        <div className="stepper-desc fw-bold">Your Declarant Related Info</div>
                      </div>
                    </div>


                    <div className={`stepper-item ${activeTabIndex === 2 ? "current" : activeTabIndex > 2 ? "completed" : "pending"}`} data-kt-stepper-element="nav">
                      <div className="stepper-line w-40px"></div>
                      <div className="stepper-icon w-40px h-40px">
                        <i className="stepper-check fas fa-check"></i>
                        <span className="stepper-number">4</span>
                      </div>
                      <div className="stepper-label">
                        <h3 className="stepper-title">Account Features</h3>
                        <div className="stepper-desc fw-bold">Setup Your Account Features</div>
                      </div>
                    </div>

                    <div className={`stepper-item ${activeTabIndex === 3 ? "current" : activeTabIndex > 3 ? "completed" : "pending"}`} data-kt-stepper-element="nav">
                      <div className="stepper-line w-40px"></div>
                      <div className="stepper-icon w-40px h-40px">
                        <i className="stepper-check fas fa-check"></i>
                        <span className="stepper-number">5</span>
                      </div>
                      <div className="stepper-label">
                        <h3 className="stepper-title">Finish Your Profile</h3>
                        <div className="stepper-desc fw-bold">Add your declaration guarantees &amp; authorisation</div>
                      </div>
                    </div>

                  </div>

                </div>

              </div>

            </div>


            <div className="d-flex flex-column flex-lg-row-fluid py-10">

              <div className="d-flex flex-center flex-column flex-column-fluid" data-select2-id="select2-data-15-7d58">

                <div className="w-lg-700px p-10 p-lg-15 mx-auto" data-select2-id="select2-data-14-gz4q">

                  <form
                    className="my-auto pb-5 fv-plugins-bootstrap5 fv-plugins-framework"
                    noValidate="novalidate"
                    id="kt_create_account_form"
                    data-select2-id="select2-data-kt_create_account_form"
                  >

                    {this._renderContent()}

                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </form>

                </div>

              </div>


              <div className="d-flex flex-center flex-wrap fs-6 p-5 pb-0">
                <div className="d-flex flex-center fw-bold fs-6">
                  <a href="#" className="text-muted text-hover-primary px-2"
                     target="_blank">About</a>
                  <a href="https://www.customswindow.com/#Message" className="text-muted text-hover-primary px-2"
                     target="_blank">Support</a>
                </div>
              </div>

            </div>

          </div>

        </div>
      </>
    )
  }
}
