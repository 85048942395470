import React from "react";
import Modal from 'react-bootstrap/Modal'
import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";
import LocationInput from "../common/LocationInput";
import General from "../../../utils/General";
import AsyncSelect from "../common/AsyncSelect";
import SmartList from "../common/SmartList";
import AuthManager from "../../../utils/AuthManager";

export default class Guarantee extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      show: props.show,
      guarantee: props.guarantee || {references: [{}]}
    }
  }

  componentDidMount() {

  }

  _handleSubmission(){
    let {
      guarantee
    } = this.state

    if(guarantee.id){
      this._updateGuarantee(guarantee)
    }else{
      this._addGuarantee(guarantee)
    }
  }

  _addGuarantee(party){
    this.setState({loading: true})
    Backend.addGuarantee(party).then(party => {
      if(!AuthManager.currentUser.company.onboarding_checklist.guarantee){
        AuthManager.currentUser.company.onboarding_checklist.guarantee = true
      }
      this.setState({loading: false})
      Notify.success("Guarantee Added")
      this.props.onSubmit(party)
    }).catch(e => {
      this.setState({loading: false})
      Notify.error(e.message)
    })
  }

  _updateGuarantee(party){
    this.setState({loading: true})
    Backend.updateGuarantee(party).then(party => {
      this.setState({loading: false})
      Notify.success("Guarantee Updated")
      this.props.onSubmit(party)
    }).catch(e => {
      this.setState({loading: false})
      Notify.error(e.message)
    })
  }

  _renderReference(reference, index){
    let {
      guarantee
    } = this.state

    return (
      <>
        <div data-repeater-list="guaranteeRepeater_inner" className="mt-3 mb-5">
          <div className="fv-row row mb-4">
            <div className="col-md-6">
              <div className="form-floating mb-4">
                <input
                  type="text"
                  className="form-control"
                  id="grn"
                  placeholder="GRN"
                  value={reference.grn}
                  maxLength={24}
                  onChange={e => {
                    guarantee.references[index].grn = e.target.value
                    this.setState({guarantee})
                  }}
                />
                <label htmlFor="grn">GRN</label>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-floating mb-4">
                <input
                  type="text"
                  className="form-control"
                  id="otherGuaranteeReference"
                  placeholder="Other Guarantee Reference"
                  maxLength={35}
                  value={reference.other_guarantee_reference}
                  onChange={e => {
                    guarantee.references[index].other_guarantee_reference = e.target.value
                    this.setState({guarantee})
                  }}
                />
                <label htmlFor="otherGuaranteeReference">Other Guarantee Reference</label>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-floating mb-4">
                <input
                  type="text"
                  className="form-control"
                  id="accessCode"
                  placeholder="Access Code"
                  maxLength={4}
                  value={reference.access_code}
                  onChange={e => {
                    guarantee.references[index].access_code = e.target.value
                    this.setState({guarantee})
                  }}
                />
                <label htmlFor="accessCode">Access Code</label>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-floating mb-4">
                <AsyncSelect
                  placeholder="Currencies"
                  endpoint={window.Api.Codes}
                  orderBy={"name"}
                  filter={"list_key=Currencies"}
                  className={'custom-async-select__container'}
                  classNamePrefix={'custom-async-select'}
                  value={General.getSingleAsyncOption(reference.currency, "value")}
                  getOptions={options => General.getAsyncOptions(options)}
                  onSelected={option => {
                    guarantee.references[index].currency = option
                    this.setState({guarantee})
                  }}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-floating mb-4">
                <input
                  type="number"
                  className="form-control"
                  id="guaranteeAmount"
                  placeholder="Amount"
                  value={reference.amount/100 || ''}
                  onChange={e => {
                    guarantee.references[index].amount = Math.round(e.target.value * 100)
                    this.setState({guarantee})
                  }}
                />
                <label htmlFor="guaranteeAmount">Amount</label>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-floating mb-4">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Customs Office of Guarantee"
                  maxLength={3}
                  value={reference.guarantee_customs_office}
                  onChange={e => {
                    guarantee.references[index].guarantee_customs_office = e.target.value
                    this.setState({guarantee})
                  }}
                />
                <label htmlFor="officeOfGuarantee">Customs Office of Guarantee</label>
              </div>
            </div>
          </div>

          <div className="separator separator-dashed my-3" bis_skin_checked="1"></div>
        </div>
      </>
    )
  }

  render() {
    let {
      show,
      loading,
      guarantee,
    } = this.state

    let title = guarantee.id ? "Update Guarantee" : "Add Guarantee"

    return (
      <>
        <Modal
          show={show}
          onHide={() => this.props.onHide()}
          className={'sub-modal'}
          dialogClassName={"modal-40"}
        >
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="form-group row mb-5">
              <div className="col">
                <div className="fv-rom mb-3">
                  <div className="form-floating mb-2">
                    <AsyncSelect
                      placeholder="Codes"
                      endpoint={window.Api.Codes}
                      orderBy={"name"}
                      filter={"list_key=GuaranteeType"}
                      className={'custom-async-select__container'}
                      classNamePrefix={'custom-async-select'}
                      value={General.getSingleAsyncOption(guarantee.type, "value")}
                      getOptions={options => General.getAsyncOptions(options)}
                      onSelected={option => {
                        guarantee.type = option
                        this.setState({guarantee})
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-12 mt-7">
                <SmartList
                  items={guarantee.references}
                  itemName={"Reference"}
                  showItemName={true}
                  renderItem={(reference, index) => this._renderReference(reference, index)}
                  renderDelete={(item, index) => (
                    <a
                        className="btn btn-sm btn-light-danger"
                        onClick={() => {
                          guarantee.references.splice(index, 1)
                          this.setState({guarantee})
                        }}
                    >
                      Delete Reference
                    </a>
                  )}
                  onUpdated={items => {
                    guarantee.references = items
                    this.setState({guarantee})
                  }}
                  onAddItemPressed={() => {
                    guarantee.references.push({})
                    this.setState({guarantee})
                  }}
                />
              </div>
            </div>
            <div className=" d-flex justify-content-end py-6">
              <button
                className="btn btn-primary px-6"
                onClick={() => this._handleSubmission()}
                disabled={loading}
              >
                {title}
              </button>
            </div>
          </Modal.Body>

        </Modal>
      </>
    )
  }

}