import React from 'react'
import Modal from 'react-bootstrap/Modal'

import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";

export default class GoodsPresentation extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      show: props.show,
      submission: props.submission
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _presentGoods(){
    let {
      submission
    } = this.state

    this.setState({loading: true})

    Backend.presentGoods(submission)
    .then(e => {
      this.setState({loading: false})
      Notify.success("Goods Presented")
      this.props.onHide()
    }).catch(e => {
      this.setState({loading: false})
      Notify.error(e.message)
    })
  }

  render() {
    let {
      show,
      loading,
    } = this.state

    return (
      <Modal
        show={show}
        onHide={() => this.props.onHide()}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Send Presentation Notification to Revenue
          </Modal.Title>
        </Modal.Header>

        <Modal.Body
          className='scroll-y mx-5 mx-xl-15 '
        >

          <div className='text-center pt-5'>
            <div className='buttons-grid gg-7'>

              <button
                type="button"
                className="btn btn-primary ml-auto"
                onClick={() => this._presentGoods()}
                disabled={loading}
              >
                Confirm
              </button>
            </div>
          </div>


        </Modal.Body>

      </Modal>
    )
  }
}
