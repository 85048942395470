import React from 'react'
import Modal from 'react-bootstrap/Modal'

import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import SplitButton from 'react-bootstrap/SplitButton';

import AuthManager from "../../../utils/AuthManager";

export default class SubmissionConfirmation extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      show: props.show,
      company: AuthManager.currentUser.company,
      unansweredRecommendedFields: props.unansweredRecommendedFields
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _renderUnansweredRecommendedFields(){
    let { unansweredRecommendedFields } = this.state

    if(!unansweredRecommendedFields || unansweredRecommendedFields.length == 0){
      return null
    }
    return (
      <div className="bg bg-light-danger p-8 mb-4">
        <p>The following fields have not been answered. We highly recommend they are answered so as to minimise the chance of any rejections.</p>
        <ul>
        { unansweredRecommendedFields.map(unansweredRecommendedField => {
            let { path, field } = unansweredRecommendedField
            return (
              <li>{path}</li>
            )
          })
        }
        </ul>
      </div>
    )
  }

  render() {
    let {
      show,
      company,
      showDropdown,
      unansweredRecommendedFields
    } = this.state

    let autoRejectionsEnabled = company.auto_rejection_routes.length > 0
    return (
      <Modal
        show={show}
        onHide={() => this.props.onHide()}
        centered
        className={'sub-modal'}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {this.props.title}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {this.props.message &&
            <span>{this.props.message}</span>
          }
          {this._renderUnansweredRecommendedFields()}
        </Modal.Body>

        <Modal.Footer>

          <div className='buttons-grid gg-7 me-3'>
            <button
                type="button"
                className="btn btn-light me-3"
                onClick={() => this.props.onHide()}
            >
              Cancel
            </button>
            { !autoRejectionsEnabled &&
              <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => this.props.onSend()}
                  disabled={this.props.disabled}
              >
                Send
              </button>
            }
            { autoRejectionsEnabled &&
              <SplitButton
                className="d-inline mx-2"
                autoClose="outside"
                title={"Send"}
                onClick={() => this.props.onSend()}
                disabled={this.props.disabled}
              >
                <Dropdown.Item>
                  <a
                    className="dropdown-item"
                    onClick={() => this.props.onForceSend()}
                    disabled={this.props.disabled}
                  >
                    Send & Bypass Restriced Codes
                  </a>
                </Dropdown.Item>
              </SplitButton>
            }
          </div>

        </Modal.Footer>

      </Modal>
    )
  }
}

SubmissionConfirmation.defaultProps = {
  title: "Are you sure?",
  message: "This action cannot be undone"
}
