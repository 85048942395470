import React from "react";
import Modal from 'react-bootstrap/Modal'
import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";
import LocationInput from "../common/LocationInput";
import General from "../../../utils/General";
import AsyncSelect from "../common/AsyncSelect";
import PhoneInput from "react-phone-input-2";

export default class Template extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      show: props.show,
      template: {}
    }
  }

  render() {
    let {
      show,
      template
    } = this.state

    return (
      <>
        <Modal
          show={show}
          onHide={() => this.props.onHide()}
          className={'sub-modal'}
          dialogClassName={"modal-50"}
        >
          <Modal.Header closeButton>
            <Modal.Title>Create Template</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="flex-column" data-kt-stepper-element="content">
              <div className="row">

                <div className="col-md-12">
                  <div className="fv-row mb-3">
                    <div className="form-floating mb-2">
                      <input
                        type="text"
                        className="form-control"
                        id="declarantName"
                        placeholder="Template Name"
                        value={template.name}
                        onChange={e => {
                          template.name = e.target.value
                          this.setState({template})
                        }}
                      />
                      <div className="position-absolute translate-middle-y top-50 end-0 me-3">
                        <i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title=""
                           data-bs-original-title="Description of Field" aria-label="Description of Field"></i>
                      </div>
                      <label htmlFor="declarantName">Template Name</label>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div className=" d-flex justify-content-end py-6">
              <button
                className="btn btn-primary px-6"
                id="addProductCode"
                onClick={() => {
                  if(!template.name){
                    Notify.error("Please provide a name for the template")
                  }else{
                    this.props.onCreateTemplate(template)
                  }
                }}
              >
                Create Template
              </button>
            </div>
          </Modal.Body>

        </Modal>
      </>
    )
  }

}